import classNames from 'classnames';

import { getAssetInfo } from '@sturdyfi/sturdy-ui-kit';

import Value from '../../basic/Value';
import NoData from '../../basic/NoData';
import ValuePercent from '../../basic/ValuePercent';
import SpinLoader from '../../basic/SpinLoader';
import InfoPanelWrapper from '../../Wrappers/InfoPanelWrapper';
import TextWithInfoWrapper from '../../Wrappers/TextWithInfoWrapper';

import { OverviewDataType } from '../types';

import staticStyles from './style';
import rightArrow from 'src/images/right_arrow.svg';
import PriceSlippageModal from 'src/components/PriceSlippageModal';
import { useState } from 'react';
import { Network, valueToBigNumber } from '@sturdyfi/sturdy-js';
import { useStaticPoolDataContext } from 'src/libs/pool-data-provider';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';

interface DeleverageOverviewPanelProps
  extends Pick<
    OverviewDataType,
    | 'assetAmount'
    | 'assetSymbol'
    | 'healthFactor'
    | 'healthFactorAfter'
    | 'APY'
    | 'APYAfter'
    | 'avgAPY'
    | 'price'
    | 'liquidationPrice'
    | 'liquidationPriceAfter'
    | 'gasEstimation'
    | 'loadingGasEstimation'
    | 'slippage'
    | 'slippageExpected'
    | 'isSlippageLow'
    | 'maxSlippage'
    | 'onSlippageChanged'
  > {}

export default function DeleverageOverviewPanel({
  assetAmount,
  assetSymbol,
  healthFactor,
  healthFactorAfter,
  APY,
  APYAfter,
  price,
  liquidationPrice,
  liquidationPriceAfter,
  gasEstimation,
  loadingGasEstimation,
  slippage,
  slippageExpected,
  isSlippageLow,
  maxSlippage,
  onSlippageChanged,
}: DeleverageOverviewPanelProps) {
  const asset = getAssetInfo(assetSymbol);
  const tokenName = asset.shortSymbol ? asset.shortSymbol : asset.symbol;
  const [visibleSlippageModal, setVisibleSlippageModal] = useState(false);

  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { network } = useProtocolDataContext();

  const priceUnit = network === Network.eth ? 'ETH' : 'USD';

  const formattedPrice = price
    ? network === Network.eth
      ? valueToBigNumber(price).multipliedBy(marketRefPriceInUsd).toString()
      : price
    : undefined;

  const formattedLiquidationPrice = liquidationPrice
    ? network === Network.eth
      ? valueToBigNumber(liquidationPrice).multipliedBy(marketRefPriceInUsd).toString()
      : liquidationPrice
    : undefined;

  const formattedLiquidationPriceAfter = liquidationPriceAfter
    ? network === Network.eth
      ? valueToBigNumber(liquidationPriceAfter).multipliedBy(marketRefPriceInUsd).toString()
      : liquidationPriceAfter
    : undefined;

  return (
    <InfoPanelWrapper
      title={<span>Transaction overview</span>}
      className={'DeleverageOverviewPanel'}
    >
      <div className={'DeleverageOverviewPanel__item'}>
        <div className={'DeleverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Remaining Balance'} tooltipName="remainingBalance" />
          <div className={'DeleverageOverviewPanel__item__content__value'}>
            {assetAmount ? <Value value={assetAmount} maximumValueDecimals={2} /> : <NoData />}
            <span>&nbsp;{tokenName.toUpperCase()}</span>
          </div>
        </div>
      </div>

      <div className={'DeleverageOverviewPanel__item'}>
        <div className={'DeleverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Health Factor'} tooltipName="healthFactor" />
          {healthFactorAfter ? (
            <div className={'DeleverageOverviewPanel__item__content__value__after'}>
              <div
                className={classNames(
                  {
                    DeleverageOverviewPanel__item__content__value__healthFactor:
                      Number(healthFactor) < 2,
                  },
                  {
                    DeleverageOverviewPanel__item__content__value__healthFactorSafe:
                      Number(healthFactor) >= 2,
                  }
                )}
              >
                {healthFactor ? (
                  <Value value={healthFactor} maximumValueDecimals={2} />
                ) : (
                  <NoData />
                )}
              </div>
              <img src={rightArrow} alt="" />
              <div
                className={classNames(
                  {
                    DeleverageOverviewPanel__item__content__value__healthFactor:
                      Number(healthFactorAfter) < 2,
                  },
                  {
                    DeleverageOverviewPanel__item__content__value__healthFactorSafe:
                      Number(healthFactorAfter) >= 2,
                  }
                )}
              >
                {healthFactorAfter ? (
                  <Value value={healthFactorAfter} maximumValueDecimals={2} />
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          ) : (
            <div
              className={classNames(
                {
                  DeleverageOverviewPanel__item__content__value__healthFactor:
                    Number(healthFactor) < 2,
                },
                {
                  DeleverageOverviewPanel__item__content__value__healthFactorSafe:
                    Number(healthFactor) >= 2,
                }
              )}
            >
              {healthFactor ? <Value value={healthFactor} maximumValueDecimals={2} /> : <NoData />}
            </div>
          )}
        </div>
        <div className={'DeleverageOverviewPanel__item__sub'}>{'Liquidation at < 1.0'}</div>
      </div>

      <div className={'DeleverageOverviewPanel__item'}>
        <div className={'DeleverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'APY'} tooltipName="depositAPYForCollateral" />
          {APYAfter ? (
            <div className={'DeleverageOverviewPanel__item__content__value__after'}>
              {APY ? <ValuePercent value={APY} maximumDecimals={2} /> : <NoData />}
              <img src={rightArrow} alt="" />
              <ValuePercent value={APYAfter} maximumDecimals={2} />
            </div>
          ) : (
            <div className={'DeleverageOverviewPanel__item__content__value'}>
              {APY ? <ValuePercent value={APY} maximumDecimals={2} /> : <NoData />}
            </div>
          )}
        </div>
      </div>

      <div className={'DeleverageOverviewPanel__item'}>
        <div className={'DeleverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Current Price'} tooltipName="currentPrice" />
          <div className={'DeleverageOverviewPanel__item__content__value'}>
            {formattedPrice ? (
              <Value
                value={formattedPrice}
                symbol={priceUnit}
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      <div className={'DeleverageOverviewPanel__item'}>
        <div className={'DeleverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Liquidation Price'} tooltipName="liquidationPrice" />
          {liquidationPriceAfter ? (
            <div className={'DeleverageOverviewPanel__item__content__value__after'}>
              <div className={'DeleverageOverviewPanel__item__content__value'}>
                {formattedLiquidationPrice ? (
                  <Value
                    value={formattedLiquidationPrice}
                    symbol={priceUnit}
                    withoutSymbol={true}
                    tokenIcon={true}
                    minimumValueDecimals={2}
                    maximumValueDecimals={2}
                  />
                ) : (
                  <NoData />
                )}
              </div>
              <img src={rightArrow} alt="" />
              <div className={'DeleverageOverviewPanel__item__content__value'}>
                {formattedLiquidationPriceAfter ? (
                  <Value
                    value={formattedLiquidationPriceAfter}
                    symbol={priceUnit}
                    withoutSymbol={true}
                    tokenIcon={true}
                    minimumValueDecimals={2}
                    maximumValueDecimals={2}
                  />
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          ) : (
            <div className={'DeleverageOverviewPanel__item__content__value'}>
              {liquidationPrice ? (
                <Value
                  value={liquidationPrice}
                  symbol="USD"
                  withoutSymbol={true}
                  tokenIcon={true}
                  minimumValueDecimals={2}
                  maximumValueDecimals={2}
                />
              ) : (
                <NoData />
              )}
            </div>
          )}
        </div>
      </div>

      <div className={'DeleverageOverviewPanel__split'} />

      <div className={'DeleverageOverviewPanel__item'}>
        <div className={'DeleverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Gas Fee'} tooltipName="gasFee" />
          <div className={'DeleverageOverviewPanel__item__content__value'}>
            {loadingGasEstimation ? (
              <SpinLoader color="black" />
            ) : gasEstimation ? (
              <Value
                value={gasEstimation}
                symbol="USD"
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      {typeof slippageExpected !== 'undefined' ? (
        <div className={'DeleverageOverviewPanel__item'}>
          <div className={'DeleverageOverviewPanel__item__content'}>
            <TextWithInfoWrapper text={'Slippage Bonus/Loss'} tooltipName="slippageBonusLoss" />
            <div className={'DeleverageOverviewPanel__item__content__value'}>
              {slippageExpected != 0 ? (
                <ValuePercent
                  className={
                    slippageExpected > 0
                      ? 'DeleverageOverviewPanel__item__content__value__slippageBonus'
                      : 'DeleverageOverviewPanel__item__content__value__slippageLoss'
                  }
                  value={slippageExpected}
                  maximumDecimals={2}
                />
              ) : (
                <NoData />
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className={'DeleverageOverviewPanel__item'}>
        <div className={'DeleverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Additional Slippage Tolerance'} tooltipName="priceSlippage" />
          <div className={'DeleverageOverviewPanel__item__content__value'}>
            {slippage ? (
              <u onClick={() => setVisibleSlippageModal(true)}>
                <ValuePercent value={slippage} maximumDecimals={2} />
              </u>
            ) : (
              <NoData />
            )}
          </div>
        </div>
        {isSlippageLow && (
          <div className={'LeverageOverviewPanel__item__sub'}>
            {<span className="LowSlippage">Slippage too low</span>}
          </div>
        )}
      </div>

      <PriceSlippageModal
        isVisible={visibleSlippageModal}
        onBackdropPress={() => setVisibleSlippageModal(false)}
        slippage={Number(slippage || 0.005)}
        maxSlippageValue={Number(maxSlippage || 0.05)}
        onSubmitSlippageValue={onSlippageChanged}
      />
      <style jsx={true}>{staticStyles}</style>
    </InfoPanelWrapper>
  );
}
