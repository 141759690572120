import {
  ChainId,
  IncentivesControllerInterface,
  normalize,
  REWARD_TYPE,
  valueToBigNumber,
} from '@sturdyfi/sturdy-js';
import { textCenterEllipsis, TokenIcon } from '@sturdyfi/sturdy-ui-kit';
import { useState, useEffect, useRef } from 'react';
import { toast, Id } from 'react-toastify';
import { providers } from 'ethers';
import UAuth from '@uauth/js';
import { useUserWalletDataContext } from 'src/libs/web3-data-provider';
import BasicModal from '../basic/BasicModal';
import staticStyles from './style';
import { useWeb3React } from '@web3-react/core';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';
import { useIncentivesDataContext } from 'src/libs/pool-data-provider/hooks/use-incentives-data-context';
import { useDynamicPoolDataContext, useStaticPoolDataContext } from 'src/libs/pool-data-provider';
import { addERC20Token } from 'src/helpers/add-erc20';
import {
  EthTransactionData,
  sendEthTransactionWithoutStateNotify,
} from 'src/helpers/send-ethereum-tx';
import Value from '../basic/Value';
import TxToastView from '../TxConfirmationView/TxToastView';

interface ClaimModalProps {
  className?: string;
  isVisible: boolean;
  onBackdropPress: () => void;
}

interface RewardTokenInfo {
  rewardTokenSymbol: string;
  rewardTokenAddress: string;
  rewardTokenDecimals: number;
  rewardTokenPrice: string;
  rewardType: REWARD_TYPE;
  aTokens: string[];
  claimableAmount: string;
  totalCostInUsd: string;
  distributorAddress: string;
}

export default function ClaimModal({ isVisible, onBackdropPress }: ClaimModalProps) {
  const { currentAccount, currentProviderName } = useUserWalletDataContext();
  const { library: provider, chainId } = useWeb3React<providers.Web3Provider>();
  const { marketRefPriceInUsd, extraAssetPrices } = useStaticPoolDataContext();
  const { user } = useDynamicPoolDataContext();
  const { networkConfig } = useProtocolDataContext();
  const {
    userReward,
    incentiveRewardsTxBuilder,
    variableRewardsTxBuilder,
    stableRewardsTxBuilder,
  } = useIncentivesDataContext();
  const [userEns, setUserEns] = useState('');
  const toastId = useRef(0 as Id);

  useEffect(() => {
    get_ens();
  }, [currentAccount, chainId]);

  const get_ens = async () => {
    if (!currentAccount || !provider) {
      return setUserEns('');
    }

    if (currentProviderName === 'uath-connect') {
      const clientID =
        process.env.REACT_APP_UNSTOPPABLE_DOMAIN_CLIENTID || 'e240de52-3903-4bb9-a839-3dcd6a3c0ff3';
      const redirectUri =
        process.env.REACT_APP_UNSTOPPABLE_DOMAIN_REDIRECT_URI || 'https://app.sturdy.finance/';
      const uauth = new UAuth({
        clientID,
        redirectUri,
      });
      const user = await uauth.user();
      setUserEns(user.sub);
    } else if (chainId === ChainId.mainnet) {
      var ens = (await provider.lookupAddress(currentAccount)) || '';
      setUserEns(ens);
    }
  };

  const handleAddAsset = async (address: string, symbol: string, decimals: number) => {
    try {
      await addERC20Token(address, symbol, decimals);
    } catch (e) {
      console.log(e);
    }
  };

  const handleTxExecuted = (txHash: string) => {
    toastId.current = toast(<TxToastView type={'pending'} txHash={txHash} />, { autoClose: false });
  };

  const handleTxConfirmed = (txHash: string) => {
    if (toastId) {
      toast.update(toastId.current, {
        render() {
          return <TxToastView type={'success'} txHash={txHash} />;
        },
        autoClose: 5000,
      });
    }
  };

  const handleTxFailed = (txHash: string | undefined, error: string) => {
    if (toastId) {
      toast.update(toastId.current, {
        render() {
          return <TxToastView type={'error'} txHash={txHash} />;
        },
        autoClose: 5000,
      });
    }
  };

  const handleClaim = async (type: REWARD_TYPE, assets: string[], distributorAddress: string) => {
    const txBuilder =
      type === REWARD_TYPE.MAIN
        ? incentiveRewardsTxBuilder
        : type === REWARD_TYPE.STABLE
        ? stableRewardsTxBuilder
        : variableRewardsTxBuilder;

    if (user) {
      const txs =
        type === REWARD_TYPE.MAIN
          ? await (txBuilder as IncentivesControllerInterface).claimRewards({
              user: user.id,
              assets,
              to: user.id,
            })
          : await txBuilder.claimRewards({
              user: user.id,
              assets,
              to: user.id,
              distributorAddress: distributorAddress,
            });
      const formattedTxs: EthTransactionData[] = txs.map((tx, index) => {
        return {
          txId: index,
          txType: tx.txType,
          unsignedData: tx.tx,
          gas: tx.gas,
          name: tx.txType,
        };
      });

      const tx = formattedTxs.find((item) => item.txType === 'REWARD_ACTION');

      if (tx && tx.unsignedData) {
        await sendEthTransactionWithoutStateNotify(tx.unsignedData, provider, {
          onExecution: handleTxExecuted,
          onConfirmation: handleTxConfirmed,
          onFail: handleTxFailed,
        });
      }
    }
  };

  const accountUrl =
    currentAccount && networkConfig.explorerLinkBuilder({ address: currentAccount });

  const title = 'Your Rewards';

  const rewardTokens = networkConfig.collateralRewardAddresses
    ?.map((rewardAddress) => {
      const {
        symbol: rewardTokenSymbol,
        token: rewardTokenAddress,
        decimals: rewardTokenDecimals,
        type: rewardType,
      } = rewardAddress;

      const rewardTokenPrice =
        extraAssetPrices.find(
          (asset) => asset.address.toLowerCase() === rewardTokenAddress.toLowerCase()
        )?.price || '0';

      let claimableAmount = valueToBigNumber(0);
      let distributorAddress = '';
      let shouldSubtract = false;
      const aTokens: string[] = [];

      userReward?.forEach((userReward) => {
        userReward.rewardUserDatas.forEach((item) => {
          if (item.rewardTokenAddress.toLowerCase() === rewardTokenAddress.toLowerCase()) {
            distributorAddress = item.distributorAddress;
            let realRewardsBalance = valueToBigNumber(item.rewardsBalance);
            if (rewardType !== REWARD_TYPE.VARIABLE) {
              if (shouldSubtract) {
                realRewardsBalance = realRewardsBalance.minus(
                  valueToBigNumber(item.userUnclaimedRewards)
                );
              } else {
                shouldSubtract = true;
              }
            }
            claimableAmount = claimableAmount.plus(realRewardsBalance);
            if (realRewardsBalance.gt(0)) {
              if (!aTokens.includes(item.tokenAddress)) aTokens.push(item.tokenAddress);
            }
          }
        });
      });

      const totalCostInEth = normalize(
        claimableAmount.multipliedBy(rewardTokenPrice),
        rewardTokenDecimals
      );
      const totalCostInUsd = normalize(
        valueToBigNumber(totalCostInEth).div(marketRefPriceInUsd),
        18
      );

      return {
        rewardTokenSymbol,
        rewardTokenAddress,
        rewardTokenDecimals,
        rewardTokenPrice,
        rewardType,
        aTokens,
        claimableAmount: normalize(claimableAmount, rewardTokenDecimals),
        totalCostInUsd,
        distributorAddress,
      };
    })
    .filter(
      (reward) =>
        !(
          reward.rewardType === REWARD_TYPE.STABLE && valueToBigNumber(reward.claimableAmount).eq(0)
        )
    );

  return (
    <BasicModal
      className="ClaimModal"
      isVisible={isVisible}
      onBackdropPress={onBackdropPress}
      title={title}
      withCloseButton={true}
    >
      <p></p>
      <div className="Wallet">
        <h3>your wallet</h3>
        <div>
          <span>Address:</span>
          <a href={accountUrl} target="_blank" rel="noreferrer">
            <span>{userEns || textCenterEllipsis(currentAccount, 4, 4)}</span>
          </a>
        </div>
      </div>
      <div className="IncentiveTokens">
        <h3>Tokens</h3>
        <>
          {(rewardTokens || []).map((token) => (
            <div className="IncentiveToken" key={token.rewardTokenSymbol}>
              <div>
                <div className="IncentiveToken__icon">
                  <TokenIcon tokenSymbol={token.rewardTokenSymbol} height={30} width={30} />
                  <div className="IncentiveToken__icon-info">
                    {/* <Value
                      symbol={token.rewardTokenSymbol}
                      value={token.claimableAmount}
                      maximumValueDecimals={5}
                      minimumValueDecimals={0}
                      withoutSymbol={false}
                    /> */}
                    <div>
                      <span>{token.claimableAmount}</span>
                      <span>{token.rewardTokenSymbol.toUpperCase()}</span>
                    </div>
                    {token.rewardTokenPrice !== '0' && (
                      <Value
                        value={token.totalCostInUsd}
                        symbol="USD"
                        withoutSymbol={true}
                        tokenIcon={true}
                        minimumValueDecimals={2}
                        maximumValueDecimals={2}
                      />
                    )}
                  </div>
                </div>
                <button
                  className="ClaimButton"
                  disabled={valueToBigNumber(token.claimableAmount).eq(0)}
                  onClick={async () => {
                    await handleClaim(token.rewardType, token.aTokens, token.distributorAddress);
                  }}
                >
                  Claim
                </button>
              </div>
              <p>
                <button
                  onClick={() =>
                    handleAddAsset(
                      token.rewardTokenAddress,
                      token.rewardTokenSymbol,
                      token.rewardTokenDecimals
                    )
                  }
                >
                  Add to Metamask.
                </button>
              </p>
            </div>
          ))}
        </>
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </BasicModal>
  );
}
