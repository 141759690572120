import { TokenIcon } from '@sturdyfi/sturdy-ui-kit';
import classNames from 'classnames';
import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import TextWithInfoWrapper from '../TextWithInfoWrapper';

import staticStyles from './style';
import incIcon from 'src/images/inc_arrow.svg';
import ValuePercent from 'src/components/basic/ValuePercent';
import { ReserveRatesData } from '@sturdyfi/sturdy-js';

interface ChartWrapperProps {
  title: string;
  width: number;
  height: number;
  symbol: string;
  marketData?: ReserveRatesData;
  isExpandView?: boolean;
  onHandleExpand?: () => void;
  className?: string;
}

export default function ChartWrapper({
  title,
  width,
  height,
  symbol,
  marketData,
  isExpandView,
  onHandleExpand,
  className,
}: ChartWrapperProps) {
  const PERIOD = ['1W', '1M', '3M', 'ALL'];
  const PERIOD_LENGTH = [7, 30, 90, 90];
  const [period, setPeriod] = useState('1W');
  const [dataLength, setDataLength] = useState(7);

  const onPeriod = (_period: string, _length: number) => {
    setPeriod(_period);
    setDataLength(_length);
  };

  // if (symbol === 'WETH') return <></>;

  if (!marketData) return <></>;

  const chartData =
    period === 'ALL' ? marketData.all.slice(-dataLength) : marketData.daily.slice(-dataLength);
  const timestamp = Date.now();
  const DAYms = 24 * 60 * 60 * 1000;
  const categories = chartData.map((_, id) => {
    const date: Date = new Date(timestamp - (chartData.length - id) * DAYms);
    return `${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(
      2,
      '0'
    )}`;
  });

  return (
    <div className={classNames('ChartWrapper', className)}>
      <div className={'ChartWrapper__header'}>
        <div className={'ChartWrapper__header__title'}>
          <div className={'ChartWrapper__header__title__content'}>
            <TokenIcon
              tokenSymbol={symbol}
              height={16}
              width={16}
              tokenFullName={''}
              className=""
            />
            <TextWithInfoWrapper text={title} infoPosition="right" tooltipName="depositAPY" />
          </div>
          {isExpandView && (
            <span className={'ChartWrapper__header__title__apy'}>
              <ValuePercent value={marketData.currentWeekAvgAPY / 100} maximumDecimals={2} />
            </span>
          )}
        </div>
        {!isExpandView && (
          <div className={'ChartWrapper__header__period'}>
            <span className={'ChartWrapper__header__period__apy'}>
              <ValuePercent value={marketData.currentWeekAvgAPY / 100} maximumDecimals={2} />
            </span>
            <div className={'ChartWrapper__header__period__select'}>
              {PERIOD.map((item, id) => (
                <span
                  key={id}
                  data-key={id}
                  className={classNames({
                    ChartWrapper__header__period__select__active: item === period,
                  })}
                  onClick={() => onPeriod(item, PERIOD_LENGTH[id])}
                >
                  {item}
                </span>
              ))}
            </div>
          </div>
        )}
        <div className={'ChartWrapper__header__diff'}>
          <div className={'ChartWrapper__header__diff__content'}>
            <img src={incIcon} alt="" />
            {Number(marketData.diffAPYFromLastWeek) > 0 ? (
              <span className={'ChartWrapper__header__diff__content__plusvalue'}>
                +{marketData.diffAPYFromLastWeek.toFixed(2)}%
              </span>
            ) : (
              <span className={'ChartWrapper__header__diff__content__minusvalue'}>
                {marketData.diffAPYFromLastWeek.toFixed(2)}%
              </span>
            )}
            <span className={'ChartWrapper__header__diff__content__lastweek'}>vs last week</span>
          </div>
          {isExpandView ? (
            <div className={'ChartWrapper__header__diff__select'}>
              {PERIOD.map((item, id) => (
                <span
                  key={id}
                  data-key={id}
                  className={classNames({
                    ChartWrapper__header__diff__select__active: item === period,
                  })}
                  onClick={() => onPeriod(item, PERIOD_LENGTH[id])}
                >
                  {item}
                </span>
              ))}
            </div>
          ) : (
            <span className={'ChartWrapper__header__diff__expand'} onClick={onHandleExpand}>
              Expand
            </span>
          )}
        </div>
      </div>
      <Chart
        width={width}
        height={height}
        type="area"
        options={{
          xaxis: {
            tickAmount: 7,
            labels: {
              show: true,
              rotate: 0,
              rotateAlways: false,
              hideOverlappingLabels: true,
              style: {
                colors: Array(categories.length).fill('#7D8495'),
                fontSize: '12px',
                fontFamily: 'Poppins',
                fontWeight: 500,
              },
              offsetY: 5,
              formatter(value, timestamp, opts) {
                return categories[Number(value) - 1];
              },
            },
            axisBorder: {
              show: false,
            },
            tooltip: {
              enabled: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              show: true,
              formatter: (val) => val.toFixed(1),
              offsetX: -10,
              style: {
                colors: ['#7D8495'],
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          grid: {
            show: false,
            padding: {
              bottom: 5,
              left: 0,
              right: 0,
              top: 0,
            },
          },
          fill: {
            type: 'gradient',
            colors: ['rgba(57, 96, 236, 0.7052)'],
            gradient: {
              opacityFrom: 1,
              opacityTo: 0,
            },
          },
          dataLabels: {
            enabled: false,
          },
          chart: {
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
            parentHeightOffset: 0,
            offsetY: 0,
            height: 'auto',
          },
          tooltip: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: 2,
            colors: ['#3960EC'],
          },
        }}
        series={[
          {
            name: 'DATA',
            data: chartData,
          },
        ]}
      />
      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
