import Chart from 'react-apexcharts';
import { TokenIcon, useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import PanelWrapper from 'src/components/Wrappers/PanelWrapper';
import staticStyles from './style';
import { BigNumber } from '@sturdyfi/sturdy-js';
import Value from 'src/components/basic/Value';
import ValuePercent from 'src/components/basic/ValuePercent';
import SkeletonWrapper from 'src/components/Wrappers/SkeletonWrapper';

const FillOption = {
  low: {
    type: 'solid',
  },
  medium: {
    type: 'gradient',
    gradient: {
      type: 'horizontal',
      colorStops: [
        {
          offset: 40,
          color: '#9AFE36',
        },
        {
          offset: 80,
          color: '#FFA800',
        },
      ],
    },
  },
  high: {
    type: 'gradient',
    gradient: {
      type: 'horizontal',
      colorStops: [
        {
          offset: 40,
          color: '#9AFE36',
        },
        {
          offset: 80,
          color: '#FFA800',
        },
        {
          offset: 100,
          color: '#FE3653',
        },
      ],
    },
  },
};

type SummaryCardDataType = {
  totalDepositedInETH: BigNumber;
  totalDepositedInUSD: BigNumber;
  totalBorrowedInETH: BigNumber;
  totalBorrowedInUSD: BigNumber;
  borrowPowerUsed: string;
  currentLTV: number | string;
};

interface SummaryCardProps {
  data: SummaryCardDataType;
  isLoading: boolean;
}

export default function SummaryCard({ data, isLoading }: SummaryCardProps) {
  const {
    totalDepositedInETH,
    totalDepositedInUSD,
    totalBorrowedInETH,
    totalBorrowedInUSD,
    borrowPowerUsed,
    currentLTV,
  } = data;
  const { xs, isCurrentThemeDark } = useThemeContext();

  const borrowPowerUsedInPercentage = Math.ceil(Number(borrowPowerUsed) * 100);
  // const radialBarSize = xs ? 114 : 165;
  const radialBarBgColor = isCurrentThemeDark ? '#323754' : '#EDF0F2';
  const radialBarValueColor = isCurrentThemeDark ? '#F7F9FB' : '#1D233B';
  const radialBarNameColor = isCurrentThemeDark ? '#BCBEBF' : '#9193A1';

  const fillOption =
    borrowPowerUsedInPercentage > 80
      ? FillOption['high']
      : borrowPowerUsedInPercentage > 40
      ? FillOption['medium']
      : FillOption['low'];

  return (
    <PanelWrapper className="SummaryCard">
      <div className="SummaryCard__info">
        <article>
          <h4>Total Deposited</h4>
          {isLoading ? (
            <SkeletonWrapper width={100} height={30} />
          ) : (
            <div className="SummaryCard__amount-wrapper">
              <Value
                value={totalDepositedInUSD.toString()}
                symbol="USD"
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
                className="usdValue"
              />
              <div className="ethValue">
                <Value
                  value={totalDepositedInETH.toString()}
                  minimumValueDecimals={5}
                  maximumValueDecimals={5}
                />
                <span className="ethValue">ETH</span>
              </div>
            </div>
          )}
        </article>
        <article>
          <h4>Total borrowed</h4>
          {isLoading ? (
            <SkeletonWrapper width={100} height={30} />
          ) : (
            <div className="SummaryCard__amount-wrapper">
              <Value
                value={totalBorrowedInUSD.toString()}
                symbol="USD"
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
                className="usdValue"
              />
              <div className="ethValue">
                <Value
                  value={totalBorrowedInETH.toString()}
                  minimumValueDecimals={5}
                  maximumValueDecimals={5}
                />
                <span className="ethValue">ETH</span>
              </div>
            </div>
          )}
        </article>
        <article>
          <h4>Current LTV</h4>
          {isLoading ? (
            <SkeletonWrapper width={100} height={30} />
          ) : (
            <div className="SummaryCard__amount-wrapper">
              <ValuePercent value={currentLTV} className="usdValue" />
            </div>
          )}
        </article>
      </div>
      <div className="SummaryCard__chart">
        <Chart
          options={{
            chart: {
              type: 'radialBar',
              offsetY: 0,
              sparkline: {
                enabled: true,
              },
              width: '165px',
              height: '165px',
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
              parentHeightOffset: 0,
            },
            states: {
              hover: {
                filter: {
                  type: 'none',
                },
              },
              active: {
                filter: {
                  type: 'none',
                },
              },
            },
            plotOptions: {
              radialBar: {
                startAngle: -135,
                endAngle: 135,
                track: {
                  background: radialBarBgColor,
                },
                dataLabels: {
                  name: {
                    fontFamily: 'Poppins',
                    fontWeight: 400,
                    fontSize: '10px',
                    color: radialBarNameColor,
                    offsetY: 20,
                  },
                  value: {
                    offsetY: -20,
                    fontFamily: 'Poppins',
                    fontWeight: 700,
                    fontSize: '14px',
                    color: radialBarValueColor,
                    formatter: function (val) {
                      return val + '%';
                    },
                  },
                },
              },
            },
            fill: {
              ...fillOption,
            },
            stroke: {
              lineCap: borrowPowerUsedInPercentage > 0 ? 'round' : 'butt',
            },
            labels: ['Borrow Power Used'],
            colors: ['#9AFE36'],
          }}
          series={[borrowPowerUsedInPercentage]}
          type="radialBar"
          // width={radialBarSize}
          // height={radialBarSize}
        />
      </div>

      <style jsx={true}>{staticStyles}</style>
    </PanelWrapper>
  );
}
