import { defineMessages } from 'react-intl';

export default defineMessages({
  asset: 'Assets',
  walletBalance: 'Wallet balance',
  capacity: 'Capacity',
  depositAPY: 'Deposit APY',
  maxLeverage: 'Max. Leverage',
  maxAPY: 'Max. APY',
});
