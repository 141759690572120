import { TokenIcon, useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import NoData from 'src/components/basic/NoData';
import Value from 'src/components/basic/Value';
import ValuePercent from 'src/components/basic/ValuePercent';
import PanelWrapper from 'src/components/Wrappers/PanelWrapper';
import SkeletonWrapper from 'src/components/Wrappers/SkeletonWrapper';
import staticStyles from './style';

type OverViewCardDataType = {
  avgAPY: number | string;
  borrowAPY: number | string;
  healthFactor: number | string;
};

interface OverViewCardProps {
  data: OverViewCardDataType;
  isLoading: boolean;
}

export default function OverViewCard({ data, isLoading }: OverViewCardProps) {
  const { avgAPY, borrowAPY, healthFactor } = data;
  const { xl } = useThemeContext();
  return (
    <PanelWrapper className="OverviewCard">
      <div className="OverviewCard__title">
        <h3>Overview</h3>
      </div>
      <div className="OverviewCard__info">
        <article>
          <h4>Avg. APY</h4>
          {isLoading ? (
            <SkeletonWrapper width={100} height={30} />
          ) : (
            <div>
              {avgAPY ? (
                <ValuePercent value={avgAPY} className="OverviewCard__value" />
              ) : (
                <NoData />
              )}
            </div>
          )}
        </article>
        <article>
          <h4>Borrow APY</h4>
          {isLoading ? (
            <SkeletonWrapper width={100} height={30} />
          ) : (
            <div>
              {borrowAPY ? (
                <ValuePercent value={borrowAPY} className="OverviewCard__value" />
              ) : (
                <NoData />
              )}
            </div>
          )}{' '}
        </article>
        <article>
          <h4>Health Factor</h4>
          {isLoading ? (
            <SkeletonWrapper width={100} height={30} />
          ) : healthFactor ? (
            <Value value={healthFactor} maximumValueDecimals={2} className="OverviewCard__value" />
          ) : (
            <NoData />
          )}
        </article>
      </div>
      <style jsx={true}>{staticStyles}</style>
    </PanelWrapper>
  );
}
