import classNames from 'classnames';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import Audit from './Audit';
import Depositing from './Depositing';
import HealthFactor from './HealthFactor';
import HowDeleverageWorking from './HowDeleverageWorking';
import HowLeverageWorking from './HowLeverageWorking';
import LendAndBorrowing from './LendAndBorrowing';
import StrdyToken from './StrdyToken';
import WhereIsYieldFrom from './WhereIsYieldFrom';

import messages from './messages';
import { TooltipName, TooltipHorizontalPosition, TooltipVerticalPosition } from './types';

import infoIcon from 'src/images/info.svg';
import staticStyles from './style';

type TooltipInfo = {
  title?: string;
  description?: string;
  longDescription?: boolean;
  component?: ReactNode;
};
interface TooltipProps {
  link?: ReactNode;
  tooltipName?: TooltipName;
  verticalPosition?: TooltipVerticalPosition;
  horizontalPosition?: TooltipHorizontalPosition;
  tooltipData?: string;
  hoverable?: boolean;
}

export default function Tooltip({
  link,
  tooltipName,
  verticalPosition = 'bottom',
  horizontalPosition = 'center',
  tooltipData,
  hoverable = true,
}: TooltipProps) {
  const intl = useIntl();
  const [visible, setVisible] = useState(false);
  const { isCurrentThemeDark } = useThemeContext();
  const tooltipInnerRef = useRef<HTMLDivElement>(null);

  const Tooltips: { [key in TooltipName]: TooltipInfo } = useMemo(() => {
    return {
      aboveUtilizationRate: {
        title: intl.formatMessage(messages.aboveUtilizationRate),
        description: intl.formatMessage(messages.aboveUtilizationRateDesc),
      },
      aboveZeroBorrowAPY: {
        title: intl.formatMessage(messages.aboveZeroBorrowAPY),
        description: intl.formatMessage(messages.aboveZeroBorrowAPYDesc),
      },
      apyOverTime: {
        title: intl.formatMessage(messages.apyOverTime),
        description: intl.formatMessage(messages.apyOverTimeDesc),
      },
      audit: { component: <Audit /> },
      availableLiquidity: {
        title: intl.formatMessage(messages.availableLiquidity),
        description: intl.formatMessage(messages.availableLiquidityDesc),
      },
      averageAPY: {
        title: intl.formatMessage(messages.averageAPY),
        description: intl.formatMessage(messages.averageAPYDesc),
      },
      averageAPYOver90Days: {
        title: intl.formatMessage(messages.averageAPY),
        description: intl.formatMessage(messages.averageAPYDesc90),
      },
      balance: {
        title: intl.formatMessage(messages.balance),
        description: intl.formatMessage(messages.balanceDesc),
      },
      borrowAPY: {
        title: intl.formatMessage(messages.borrowAPY),
        description: intl.formatMessage(messages.borrowAPYDesc),
      },
      borrowAPYForAsset: {
        title: intl.formatMessage(messages.borrowAPYForAsset),
        description: intl.formatMessage(messages.borrowAPYForAssetDesc),
      },
      borrowPosition: {
        title: intl.formatMessage(messages.borrowPosition),
        description: intl.formatMessage(messages.borrowPositionDesc),
      },
      borrowPowerUsed: {
        title: intl.formatMessage(messages.borrowPowerUsed),
        description: intl.formatMessage(messages.borrowPowerUsedDesc),
      },
      crvRewards: {
        title: intl.formatMessage(messages.crvRewards),
        description: intl.formatMessage(messages.crvRewardsDesc),
      },
      currentLTV: {
        title: intl.formatMessage(messages.currentLTV),
        description: intl.formatMessage(messages.currentLTVDesc),
      },
      currentPrice: {
        title: intl.formatMessage(messages.currentPrice),
        description: intl.formatMessage(messages.currentPriceDesc),
      },
      depositAPY: {
        title: intl.formatMessage(messages.depositAPY),
        description: intl.formatMessage(messages.depositAPYDesc),
      },
      depositAPYForCollateral: {
        title: intl.formatMessage(messages.depositAPYForCollateral),
        description: intl.formatMessage(messages.depositAPYDescForCollateral),
      },
      depositing: { component: <Depositing /> },
      gasFee: {
        title: intl.formatMessage(messages.gasFee),
        description: intl.formatMessage(messages.gasFeeDesc),
      },
      healthFactor: { component: <HealthFactor /> },
      highBorrowAPY: {
        title: intl.formatMessage(messages.highBorrowAPY),
        description: intl.formatMessage(messages.highBorrowAPYDesc),
      },
      highDepositRate: {
        title: intl.formatMessage(messages.highDepositRate),
        description: intl.formatMessage(messages.highDepositRateDesc),
      },
      howAuraDeleverageWorking: { component: <HowDeleverageWorking collateralType="aura" /> },
      howAuraLeverageWorking: { component: <HowLeverageWorking collateralType="aura" /> },
      howCurveDeleverageWorking: { component: <HowDeleverageWorking /> },
      howCurveLeverageWorking: { component: <HowLeverageWorking /> },
      lendAndBorrowing: { component: <LendAndBorrowing /> },
      liquidationPenalty: {
        title: intl.formatMessage(messages.liquidationPenalty),
        description: intl.formatMessage(messages.liquidationPenaltyDesc),
      },
      liquidationPrice: {
        title: intl.formatMessage(messages.liquidationPrice),
        description: intl.formatMessage(messages.liquidationPriceDesc),
      },
      liquidationThreshold: {
        title: intl.formatMessage(messages.liquidationThreshold),
        description: intl.formatMessage(messages.liquidationThresholdDesc),
      },
      maxAmount: {
        title: intl.formatMessage(messages.maxAmount),
        description: intl.formatMessage(messages.maxAmountDesc),
      },
      maxAPY: {
        title: intl.formatMessage(messages.maxAPY),
        description: intl.formatMessage(messages.maxAPYDesc),
      },
      maxLeverage: {
        title: intl.formatMessage(messages.maxLeverage),
        description: intl.formatMessage(messages.maxLeverageDesc),
      },
      maxLTV: {
        title: intl.formatMessage(messages.maxLTV),
        description: intl.formatMessage(messages.maxLTVDesc),
      },
      overallLeverage: {
        title: intl.formatMessage(messages.overallLeverage),
        description: intl.formatMessage(messages.overallLeverageDesc),
      },
      payingWith: {
        title: intl.formatMessage(messages.payingWith),
        description: intl.formatMessage(messages.payingWithDesc),
      },
      priceSlippage: {
        title: intl.formatMessage(messages.priceSlippage),
        description: intl.formatMessage(messages.priceSlippageDesc),
      },
      slippageBonusLoss: {
        title: intl.formatMessage(messages.slippageBonusLoss),
        description: intl.formatMessage(messages.slippageBonusLossDesc),
      },
      remainingBalance: {
        title: intl.formatMessage(messages.remainingBalance),
        description: intl.formatMessage(messages.remainingBalanceDesc),
      },
      reserveSize: {
        title: intl.formatMessage(messages.reserveSize),
        description: intl.formatMessage(messages.reserveSizeDesc),
      },
      strdyIncentive: {
        component: <StrdyToken />,
      },
      strdyIncentiveOnDeposit: {
        description: intl.formatMessage(messages.strdyIncentiveDepositDesc),
      },
      totalAvailable: {
        title: intl.formatMessage(messages.totalAvailable),
        description: intl.formatMessage(messages.totalAvailableDesc),
      },
      totalBorrowed: {
        title: intl.formatMessage(messages.totalBorrowed),
        description: intl.formatMessage(messages.totalBorrowedDesc),
      },
      totalBorrowedForAsset: {
        title: intl.formatMessage(messages.totalBorrowedForAsset),
        description: intl.formatMessage(messages.totalBorrowedForAssetDesc),
      },
      totalCollateral: {
        title: intl.formatMessage(messages.totalCollateral),
        description: intl.formatMessage(messages.totalCollateralDesc),
      },
      totalDepositAndBorrowed: {
        title: intl.formatMessage(messages.totalDepositAndBorrowed),
        description: intl.formatMessage(messages.totalDepositAndBorrowedDesc),
      },
      totalLent: {
        title: intl.formatMessage(messages.totalLent),
        description: intl.formatMessage(messages.totalLentDesc),
      },
      totalMarketSize: {
        title: intl.formatMessage(messages.totalMarketSize),
        description: intl.formatMessage(messages.totalMarketSizeDesc),
      },
      useFlashloan: {
        title: intl.formatMessage(messages.useFlashloan),
        description: intl.formatMessage(messages.useFlashloanDesc),
      },
      utilizationRate: {
        title: intl.formatMessage(messages.utilizationRate),
        description: intl.formatMessage(messages.utilizationRateDesc),
      },
      walletBalance: {
        title: intl.formatMessage(messages.walletBalance),
        description: intl.formatMessage(messages.walletBalanceDesc),
      },
      whereIsYieldFrom: { component: <WhereIsYieldFrom /> },
      yourPosition: {
        title: intl.formatMessage(messages.yourPosition),
        description: intl.formatMessage(messages.yourPositionDesc),
      },
      zeroBorrowAPY: {
        title: intl.formatMessage(messages.zeroBorrowAPY),
        description: intl.formatMessage(messages.zeroBorrowAPYDesc),
      },
      data: {
        description: tooltipData,
      },
    };
  }, []);

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        !visible ||
        (tooltipInnerRef.current && tooltipInnerRef.current.contains(e.target as HTMLElement))
      ) {
        return;
      }
      console.log(tooltipInnerRef.current);
      setVisible(!visible);
    },
    [visible]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const tooltipInfo = tooltipName ? Tooltips[tooltipName] : undefined;
  const title = tooltipInfo?.title;
  const description = tooltipInfo?.description;
  const component = tooltipInfo?.component;

  const getClsName = (str: string) => {
    if (str === '\\B') {
      return 'Tooltip__Bold';
    }
    if (str === '\\Y') {
      return 'Tooltip__Yellow';
    }
    if (str === '\\G') {
      return 'Tooltip__Green';
    }
    return '';
  };

  const splitStr = (sentence: string) => {
    const words = sentence.split(/<>/);

    return (
      <>
        {words.map((word, id) => {
          const chars = word.slice(0, 2);
          const clsName = getClsName(chars);
          return (
            <span className={clsName} key={id}>
              {clsName ? word.slice(2) : word}
            </span>
          );
        })}
      </>
    );
  };

  const renderTitle = (value: string) => {
    return <div className="Tooltip__title">{splitStr(value)}</div>;
  };

  const renderDescription = (value: string) => {
    const smallParts = value.split('\n');

    return (
      <div className="Tooltip__content">
        {smallParts.map((str, id) => (
          <p key={id}>{splitStr(str)}</p>
        ))}
      </div>
    );
  };

  return (
    <div
      className={classNames(
        'Tooltip',
        `Tooltip__${verticalPosition}`,
        `Tooltip__${horizontalPosition}`,
        { Tooltip__hoverable: hoverable },
        { Tooltip__data: tooltipData }
      )}
    >
      <div className="Tooltip__outer" onClick={() => setVisible(!visible)}>
        {link && link}
        {!link && <img className="Tooltip__infoIcon" src={infoIcon} alt="Sturdy" />}
      </div>

      <div
        className={classNames('Tooltip__inner', {
          Tooltip__innerLight: !isCurrentThemeDark,
          Tooltip__innerVisible: visible,
        })}
        ref={tooltipInnerRef}
      >
        {title && renderTitle(title)}
        {description && renderDescription(description)}
        {component}
      </div>

      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}

export type { TooltipName, TooltipHorizontalPosition, TooltipVerticalPosition };
