import css from 'styled-jsx/css';
import 'typeface-poppins';
import 'typeface-inter';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/screen-size';
  @import 'src/_mixins/vars';
  @import 'src/_mixins/font-face';

  // light theme
  :root {
    --background-color: #{$background_color};
    --box-shadow: #{$box_shadow};
    --table-box-shadow: initial;
    --panel-box-shadow: #{$panel_box};
    --action-button-shadow: 0px 4px 16px rgba(213, 213, 213, 0.54);
    --selected-color: #{$selected_color};
    --not-selected-color: #{$not_selected_color};
    --text-color: #{$text_color};
    --text2-color: #{$text2_color};
    --text3-color: #{$text3_color};
    --button1-color: #{$button1_color};
    --button1-hover-color: #{$button1_hover_color};
    --button2-color: #{$button2_color};
    --button2-hover-color: #{$button2_hover_color};
    --linear-apy-color: #{$linear_apy_color};
    --slider-color: #{$slider_color};
    --leverage-color: #{$leverage_color};
    --tabs-color: #{$tabs_color};
    --border-color: #{$tabs_color};
    --question-link-color: #4066ed;
    --button-background-color: #{$button_background_color};
    --action-button-background: white;
    --dropdown-background-color: white;
    --dropdown-item-active-background-color: gray;
    --audit-background-color: rgba(255, 255, 255, 0.6);
    --marketcad-background-color: white;
    --table-background-color: rgba(254, 254, 255, 0.7);
    --tab-background-color: #{$button_background_color};
    --panel-background-color: rgba(255, 255, 255, 0.6);
    --leverage-value-background-color: #fafbfd;
    --health-factor-color: #{$health_factor_color};
    --error-color: #{$error_color};
    --error-background-color: #{$error_background_color};
    --error2-color: #{$error2_color};
    --error2-background-color: #{$error2_background_color};
    --info-button-color: #{$info_button_color};
    --info-button2-color: #{$info_button2_color};
    --tooltip-text-color: #{$text2_color};
    --tooltip-bold-color: #{$tooltip_bold_color};
    --tooltip-highlight-color: #{$background_color};
    --toast-background-color: white;
    --skeleton-base-color: #{$skeleton_base_color};
    --skeleton-highlight-color: #{$skeleton_highlight_color};
    --mobile-menu-color: black;
  }

  // dark theme
  [data-theme='dark'] {
    --background-color: #{$background_color_dark};
    --box-shadow: #{$box_shadow_dark};
    --table-box-shadow: #{$panel_box_dark};
    --panel-box-shadow: #{$panel_box_dark};
    --action-button-shadow: #{$panel_box_dark};
    --selected-color: white;
    --not-selected-color: #{$not_selected_color};
    --text-color: #{$text_color};
    --text2-color: #{$text2_color};
    --text3-color: #{$text3_color};
    --button1-color: #{$button1_color};
    --button1-hover-color: #{$button1_hover_color};
    --button2-color: #{$button2_color_dark};
    --button2-hover-color: #{$button2_hover_color};
    --linear-apy-color: #{$linear_apy_color};
    --slider-color: #{$slider_color};
    --leverage-color: white;
    --tabs-color: initial;
    --border-color: #32384f;
    --question-link-color: white;
    --button-background-color: #{$button_background_color};
    --action-button-background: #{$action_tab_background_color};
    --dropdown-background-color: #{$dropdown_background_color};
    --dropdown-item-active-background-color: #{$selected_color};
    --audit-background-color: #{$card_background_color};
    --marketcad-background-color: #{$card_background_color};
    --table-background-color: rgba(39, 44, 70, 0.7);
    --tab-background-color: #{$dropdown_background_color};
    --panel-background-color: #272c46;
    --leverage-value-background-color: #{$action_tab_background_color};
    --health-factor-color: #{$health_factor_color};
    --error-color: #{$error_color};
    --error-background-color: #{$error_background_color};
    --error2-color: #{$error2_color};
    --error2-background-color: #{$error2_background_color};
    --info-button-color: #{$info_button_color};
    --info-button2-color: #{$info_button2_color};
    --tooltip-text-color: white;
    --tooltip-bold-color: #{$tooltip_bold_color};
    --tooltip-highlight-color: #{$action_tab_background_color};
    --toast-background-color: #{$dropdown_background_color};
    --skeleton-base-color: rgba(39, 44, 70, 0.7);
    --skeleton-highlight-color: #{$dropdown_background_color};
    --mobile-menu-color: white;
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    &:after,
    &:before {
      box-sizing: border-box;
    }
  }

  html {
    -webkit-text-size-adjust: none;
    position: relative;
    height: 100%;
    overflow: hidden !important;
  }

  body {
    @include body_16_regular;
    background-color: var(--background-color);
  }

  img {
    box-sizing: content-box;
  }

  button {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    &:disabled {
      cursor: default;
    }
  }

  ul {
    margin: 0;
  }

  ul li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  strong {
    font-weight: 600;
  }

  html,
  body,
  #root,
  .Main {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  .drift-frame-controller-align-right {
    right: auto !important;
    left: 0 !important;
    bottom: 0 !important;
    height: 60px !important;
    width: 55px !important;
    @include respond-to(sm) {
      left: auto !important;
      right: 5px !important;
      display: none !important;
    }
  }

  .Body__mobileMenu-open {
    .drift-frame-controller-align-right {
      right: auto !important;
      left: -10px !important;
    }
  }
  .Body__filters-open,
  .Body__mobileMenu-open {
    #PrivateWrapper__content-wrapper {
      overflow: hidden;
    }
  }
`;

export default staticStyles;
