import { API_ETH_MOCK_ADDRESS, Network } from '@sturdyfi/sturdy-js';
import { FORK_RPC_URL, FORK_WS_RPC_URL } from '../config';
import { BaseNetworkConfig, REWARD_TYPE } from '../helpers/markets/types';

const mainnet_config: BaseNetworkConfig = {
  publicJsonRPCUrl: `https://eth-mainnet.alchemyapi.io/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}`,
  addresses: {
    walletBalanceProvider: '0x9787bDC2Ff7F39Ff981ecc347DfAcF6D57b8783E',
    uiPoolDataProvider: '0x9ff79b260334Fa61F6b24190e8FaEc46Bc97b9d3',
    sturdyOracle: '0xe5d78eB340627B8D5bcFf63590Ebec1EF9118C89',
    extraAssetsRequiredPrice: [
      '0xD533a949740bb3306d119CC777fa900bA034cd52', //CRV
      '0xba100000625a3754423978a60c9317c58a424e3D', //BAL
      '0xC0c293ce456fF0ED870ADd98a0828Dd4d2903DBF', //AURA
    ],
    uiIncentiveDataProvider: '0xf62B771940105dfB095d0BC5F12a6Dc47Aaf9142',
  },
  protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/sturdyfi/sturdy-ethereum',
  rewardTokenSymbol: 'BRICK',
  rewardTokenAddress: '0x150A51977Bc273dfe84B5530D45ED44D175E88f1',
  rewardTokenDecimals: 18,
  incentivePrecision: 18,
  baseAsset: 'ETH',
  baseAssetGas: '0.1',
  baseAssetWrappedAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  collateralAssets: {
    stETH: ['ETH', 'stETH'],
    FRAX_3CRV_LP: ['FRAX_3CRV_LP'],
    DAI_USDC_USDT_SUSD_LP: ['DAI_USDC_USDT_SUSD_LP'],
    IRON_BANK_LP: ['IRON_BANK_LP'],
    FRAX_USDC_LP: ['FRAX_USDC_LP'],
    MIM_3CRV_LP: ['MIM_3CRV_LP'],
    TUSD_FRAXBP_LP: ['TUSD_FRAXBP_LP'],
    BAL_BB_A_USD_LP: ['BAL_BB_A_USD_LP'],
    BAL_BB_A3_USD_LP: ['BAL_BB_A3_USD_LP'],
  },
  collateralAddresses: {
    stETH: '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
    FRAX_3CRV_LP: '0xd632f22692FaC7611d2AA1C0D552930D43CAEd3B',
    DAI_USDC_USDT_SUSD_LP: '0xC25a3A3b969415c80451098fa907EC722572917F',
    IRON_BANK_LP: '0x5282a4eF67D9C33135340fB3289cc1711c13638C',
    FRAX_USDC_LP: '0x3175Df0976dFA876431C2E9eE6Bc45b65d3473CC',
    MIM_3CRV_LP: '0x5a6A4D54456819380173272A5E8E9B9904BdF41B',
    TUSD_FRAXBP_LP: '0x33baeDa08b8afACc4d3d07cf31d49FC1F1f3E893',
    BAL_BB_A_USD_LP: '0xA13a9247ea42D743238089903570127DdA72fE44',
    BAL_BB_A3_USD_LP: '0xfeBb0bbf162E64fb9D0dfe186E517d84C395f016',
  },
  collateralAssetFromSymbol: {
    stETH: 'stETH',
    'cFRAX3CRV-f': 'FRAX_3CRV_LP',
    ccrvPlain3andSUSD: 'DAI_USDC_USDT_SUSD_LP',
    cib3CRV: 'IRON_BANK_LP',
    ccrvFRAX: 'FRAX_USDC_LP',
    'cMIM-3LP3CRV-f': 'MIM_3CRV_LP',
    'cTUSDFRAXBP3CRV-f': 'TUSD_FRAXBP_LP',
    'cbb-a-USD': 'BAL_BB_A3_USD_LP',
  },
  collateralRewardAddresses: [
    {
      token: '0x59276455177429ae2af1cc62B77AE31B34EC3890',
      symbol: 'STRDY',
      decimals: 18,
      type: REWARD_TYPE.MAIN,
    },
    {
      token: '0xD533a949740bb3306d119CC777fa900bA034cd52',
      symbol: 'CRV',
      decimals: 18,
      type: REWARD_TYPE.VARIABLE,
    },
    {
      token: '0xba100000625a3754423978a60c9317c58a424e3D',
      symbol: 'BAL',
      decimals: 18,
      type: REWARD_TYPE.VARIABLE,
    },    
  ],
  explorerLink: 'https://etherscan.io',
  rpcOnly: true,
  usdMarket: false,
  isTestnet: false,
  chainName: 'Ethereum',
  currency: {
    name: 'Ethereum Coin',
    symbol: 'ETH',
    decimals: 18,
  },
} as const;

export const networkConfigs: { [key: string]: BaseNetworkConfig } = {
  [Network.mainnet]: {
    ...mainnet_config,
  },
  [Network.eth]: {
    ...mainnet_config,
    addresses: {
      walletBalanceProvider: '0x2ce36430E9893934B14b5B54eF6466E480F2da59',
      uiPoolDataProvider: '0x88435B0324239D79D1b92D4f49a3201f74B3721e',
      sturdyOracle: '0xe5d78eB340627B8D5bcFf63590Ebec1EF9118C89',
      extraAssetsRequiredPrice: [
        '0xba100000625a3754423978a60c9317c58a424e3D', //BAL
        '0xC0c293ce456fF0ED870ADd98a0828Dd4d2903DBF', //AURA
        '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84', //stETH
        '0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32', //LDO
        '0xD533a949740bb3306d119CC777fa900bA034cd52', //CRV
      ],
      uiIncentiveDataProvider: '0xf62B771940105dfB095d0BC5F12a6Dc47Aaf9142',
      curvePoolInfo: {
        provider: '0xe64608E223433E8a03a1DaaeFD8Cb638C14B552C',
        pools: [
          {
            symbol: 'ETH_STETH_LP',
            address: '0xdc24316b9ae028f1497c275eb9192a3ea0f67022',
            tokens: [
              API_ETH_MOCK_ADDRESS,
              '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84', //stETH
            ],
          },
        ],
      },
    },
    collateralAssets: {
      ETH_STETH_LP: ['ETH_STETH_LP'],
      BAL_WSTETH_WETH_LP: ['BAL_WSTETH_WETH_LP'],
      BAL_RETH_WETH_LP: ['BAL_RETH_WETH_LP'],
    },
    collateralAddresses: {
      ETH_STETH_LP: '0x06325440D014e39736583c165C2963BA99fAf14E',
      BAL_WSTETH_WETH_LP: '0x32296969Ef14EB0c6d29669C550D4a0449130230',
      BAL_RETH_WETH_LP: '0x1E19CF2D73a72Ef1332C882F20534B6519Be0276',
    },
    collateralAssetFromSymbol: {
      csteCRV: 'ETH_STETH_LP',
      'cB-stETH-STABLE': 'BAL_WSTETH_WETH_LP',
      'cB-rETH-STABLE': 'BAL_RETH_WETH_LP',
    },
    collateralRewardAddresses: [
      {
        token: '0x59276455177429ae2af1cc62B77AE31B34EC3890',
        symbol: 'STRDY',
        decimals: 18,
        type: REWARD_TYPE.MAIN,
      },
      {
        token: '0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32',
        symbol: 'LDO',
        decimals: 18,
        type: REWARD_TYPE.STABLE,
      },
      {
        token: '0xD533a949740bb3306d119CC777fa900bA034cd52',
        symbol: 'CRV',
        decimals: 18,
        type: REWARD_TYPE.VARIABLE,
      },
      {
        token: '0xba100000625a3754423978a60c9317c58a424e3D',
        symbol: 'BAL',
        decimals: 18,
        type: REWARD_TYPE.VARIABLE,
      },
    ],
  },
  [Network.fork]: {
    ...mainnet_config,
    // addresses: {
    //   walletBalanceProvider: '0x42e0fAB4aa7580D1A1492be16E2fc4fa50eb5a45',
    //   uiPoolDataProvider: '0x1BDc1144C7b3B17F7Be67b5Fe687Bcb7DA1cB550',
    //   uiIncentiveDataProvider: '0x939FC67C23f3d2b165BD7C5cb15Ce8EEe3Fa4429',
    // },
    privateJsonRPCUrl: FORK_RPC_URL,
    privateJsonRPCWSUrl: FORK_WS_RPC_URL,
    rpcOnly: true,
  },
  [Network.ftm]: {
    publicJsonRPCUrl: 'https://rpcapi.fantom.network/', //'https://rpc.ftm.tools/',
    addresses: {
      walletBalanceProvider: '0x736c58F1C8B8BEC3Ef73b9308d3137c7b9D41F44',
      uiPoolDataProvider: '0xF672c8422aEcF74df126dFBEf22EA83023bF63C3',
      sturdyOracle: '0xE84fD77E8B7bB52a71087653a26d6CC6448fb77D',
      extraAssetsRequiredPrice: [],
      uiIncentiveDataProvider: '0x9AB2Bdf9ABfCCff59b3005F34b2BC3D4c5E47213',
    },
    protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/sturdyfi/sturdy-fantom',
    rewardTokenSymbol: 'BRICK',
    rewardTokenAddress: '0x6BA22b71D83e44D611bB9a0771A5144d869baf57',
    rewardTokenDecimals: 18,
    incentivePrecision: 18,
    baseAsset: 'FTM',
    baseAssetGas: '0.1',
    baseAssetWrappedAddress: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    collateralAssets: {
      WFTM: ['FTM', 'WFTM'],
      WETH: ['WETH'],
      WBTC: ['WBTC'],
      BOO: ['BOO'],
      fBEETS: ['fBEETS'],
      LINK: ['LINK'],
      SPELL: ['SPELL'],
      CRV: ['CRV'],
      TOMB_MIMATIC_LP: ['TOMB_MIMATIC_LP'],
      BASED_MIMATIC_LP: ['BASED_MIMATIC_LP'],
    },
    collateralAssetFromSymbol: {
      yvWFTM: 'WFTM',
      yvWETH: 'WETH',
      yvWBTC: 'WBTC',
      yvBOO: 'BOO',
      yvfBEETS: 'fBEETS',
      yvLINK: 'LINK',
      yvSPELL: 'SPELL',
      yvCRV: 'CRV',
      'mooTombTOMB-MAI': 'TOMB_MIMATIC_LP',
      'mooTombBASED-MAI': 'BASED_MIMATIC_LP',
    },
    collateralAddresses: {
      WFTM: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
      WETH: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
      WBTC: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
      BOO: '0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE',
      TOMB_MIMATIC_LP: '0x45f4682B560d4e3B8FF1F1b3A38FDBe775C7177b',
      BASED_MIMATIC_LP: '0x7B5B3751550be4FF87aC6bda89533F7A0c9825B3',
      LINK: '0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8',
      SPELL: '0x468003B688943977e6130F4F68F23aad939a1040',
      CRV: '0x1E4F97b9f9F913c46F1632781732927B9019C68b',
      fBEETS: '0xfcef8a994209d6916EB2C86cDD2AFD60Aa6F54b1',
    },
    explorerLink: 'https://ftmscan.com',
    rpcOnly: true,
    usdMarket: true,
    isTestnet: false,
    chainName: 'Fantom',
    currency: {
      name: 'Fantom Coin',
      symbol: 'FTM',
      decimals: 18,
    },
  },
  [Network.ftm_test]: {
    publicJsonRPCUrl: 'https://rpc.testnet.fantom.network/',
    addresses: {
      walletBalanceProvider: '0xA5E91FA7F5A9813923c1a79d50547D78509A7532',
      uiPoolDataProvider: '0x98AEc7583913350B07570231211e3C1a80B08406',
      sturdyOracle: '0x53F155B80C4b4807E9A44df81018bAbe4b978B45',
      extraAssetsRequiredPrice: [],
      uiIncentiveDataProvider: '0x1F164A927B5558F46f1471aB43B673b6d776CC13',
    },
    protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/sturdyfi/sturdy-goerli', //ToDo: subgraph dont support fantom testnet
    rewardTokenSymbol: 'BRICK',
    rewardTokenAddress: '0xEfaA310fe2410B0f6B84d0077417A85fba853Aa2',
    rewardTokenDecimals: 18,
    incentivePrecision: 18,
    baseAsset: 'FTM',
    baseAssetGas: '0.1',
    baseAssetWrappedAddress: '0xf1277d1ed8ad466beddf92ef448a132661956621',
    collateralAssets: {
      WFTM: ['FTM', 'WFTM'],
      WETH: ['WETH'],
      WBTC: ['WBTC'],
      BOO: ['BOO'],
      fBEETS: ['fBEETS'],
      LINK: ['LINK'],
      SPELL: ['SPELL'],
      CRV: ['CRV'],
      TOMB_FTM_LP: ['TOMB_FTM_LP'],
      TOMB_MIMATIC_LP: ['TOMB_MIMATIC_LP'],
      BASED_MIMATIC_LP: ['BASED_MIMATIC_LP'],
    },
    collateralAssetFromSymbol: {
      yvWFTM: 'WFTM',
      yvWETH: 'WETH',
      yvWBTC: 'WBTC',
      yvBOO: 'BOO',
      yvfBEETS: 'fBEETS',
      yvLINK: 'LINK',
      yvSPELL: 'SPELL',
      yvCRV: 'CRV',
      'mooTombTOMB-FTM': 'TOMB_FTM_LP',
      'mooTombTOMB-MIMATIC': 'TOMB_MIMATIC_LP',
      'mooTombBASED-MIMATIC': 'BASED_MIMATIC_LP',
    },
    collateralAddresses: {
      WFTM: '0xf1277d1ed8ad466beddf92ef448a132661956621',
      WETH: '0x4135c251eE7804A73dB09D36C306AE0214deA28B',
      WBTC: '0x0e9Cbd91546F290b0F99cF62DAC637B33D22D9B6',
      BOO: '0x9dAFB108f0fFd18C1f844C4782F8c7F934f8566E',
      fBEETS: '0x9dAFB108f0fFd18C1f844C4782F8c7F934f8566E',
      LINK: '0x9dAFB108f0fFd18C1f844C4782F8c7F934f8566E',
      SPELL: '0x180D7c72DAb76D11Dd8E0ffD4892ABc34D4d1f48',
      CRV: '0x9e5C734c28B8Df6F45d4b757F3dDFbc241042259',
      TOMB_FTM_LP: '0x0906E97beB6f422C239627FeFB9198144904327d',
      TOMB_MIMATIC_LP: '0x16c8deB0B2a1dfC8Fc44b4b2694ccAfa76dfE6B6',
      BASED_MIMATIC_LP: '0x323b65bC4F76b36AB57EAF4cFBD9561cfaAe5d29',
    },
    explorerLink: 'https://testnet.ftmscan.com',
    rpcOnly: true,
    usdMarket: false,
    isTestnet: true,
    chainName: 'Fantom Testnet',
    currency: {
      name: 'Fantom Coin',
      symbol: 'FTM',
      decimals: 18,
    },
  },
} as const;
