import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Feedback {
    width: 100%;
    border-radius: 8px;

    &__success {
      background: #e5f4ee;
      border: 1px solid #8fd9bc;
      box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
    }

    &__warning {
      background: #f8efe4;
      border: 1px solid #f8ddbc;
      box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
    }

    &__error {
      background: #feeff1;
      border: 1px solid #feced5;
      box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
    }

    &__info {
      background: #f7f9fb;
      border: 1px solid #d5d8d9;
      box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
    }

    // &__darkTheme {
    //   background: #323754 !important;
    //   box-shadow: none !important;
    // }

    &__inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 8px 24px;
      gap: 4px;
      isolation: isolate;
    }

    &__info &__message {
      color: #4d526e;
    }

    &__success &__message {
      color: #008840;
    }

    &__warning &__message {
      color: #af5c00;
    }

    &__error &__message {
      color: #e30840;
    }

    &__message {
      @incldue body_12;
    }
  }
`;

export default staticStyles;
