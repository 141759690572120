import { useIntl } from 'react-intl';

import { TooltipName } from 'src/components/Tooltip';
import BasicTableWrapper from 'src/components/Wrappers/Table/BasicTableWrapper';
import TableColumnWrapper from 'src/components/Wrappers/Table/TableColumnWrapper';
import TableHeaderWrapper from 'src/components/Wrappers/Table/TableHeaderWrapper';
import TextWithInfoWrapper from 'src/components/Wrappers/TextWithInfoWrapper';

import { DepositAssetTableItemProps } from './types';
import TableItemEth from './tableItemEth';
import messages from './messages';
import staticStyles from './style';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';
import { Network } from '@sturdyfi/sturdy-js';
import TableItem from './tableItem';
import { useCallback } from 'react';

interface DepositAssetTableProps {
  listData: DepositAssetTableItemProps[];
  isLoading: boolean;
}

export default function DepositAssetTable({ listData, isLoading }: DepositAssetTableProps) {
  const intl = useIntl();
  const { currentMarketData } = useProtocolDataContext();

  const columns: {
    title: string;
    minWidth?: number;
    tooltipName?: TooltipName;
  }[] =
    currentMarketData.network === Network.eth
      ? [
          {
            title: intl.formatMessage(messages.asset),
            minWidth: 315,
          },
          {
            title: intl.formatMessage(messages.walletBalance),
            minWidth: 180,
            tooltipName: 'walletBalance',
          },
          {
            title: intl.formatMessage(messages.depositAPY),
            tooltipName: 'depositAPYForCollateral',
          },
          {
            title: intl.formatMessage(messages.maxLeverage),
            tooltipName: 'maxLeverage',
          },
          {
            title: intl.formatMessage(messages.maxAPY),
            tooltipName: 'maxAPY',
            minWidth: 130,
          },
        ]
      : [
          {
            title: intl.formatMessage(messages.asset),
            minWidth: 315,
          },
          {
            title: intl.formatMessage(messages.walletBalance),
            minWidth: 180,
            tooltipName: 'walletBalance',
          },
          {
            title: intl.formatMessage(messages.depositAPY),
            tooltipName: 'depositAPYForCollateral',
          },
          {
            title: intl.formatMessage(messages.maxLeverage),
            tooltipName: 'maxLeverage',
          },
          {
            title: intl.formatMessage(messages.maxAPY),
            tooltipName: 'maxAPY',
            minWidth: 130,
          },
        ];

  const Item = useCallback(
    (props: DepositAssetTableItemProps) =>
      currentMarketData.network === Network.eth ? (
        <TableItemEth {...props} />
      ) : (
        <TableItem {...props} />
      ),
    []
  );

  return (
    <BasicTableWrapper
      className={'DepositAssetTable'}
      headerColumns={
        <TableHeaderWrapper>
          {columns.map((column, index) => (
            <TableColumnWrapper key={index} minWidth={column.minWidth}>
              {column.tooltipName ? (
                <TextWithInfoWrapper
                  className="TableHeaderWrapper__title"
                  text={column.title}
                  tooltipName={column.tooltipName}
                />
              ) : (
                <p className="TableHeaderWrapper__title">{column.title}</p>
              )}
            </TableColumnWrapper>
          ))}
        </TableHeaderWrapper>
      }
    >
      {isLoading
        ? Array(3)
            .fill({})
            .map((item, index) => <Item {...item} key={index} isLoading={isLoading} />)
        : listData.map((item, index) => <Item {...item} key={index} />)}

      <style jsx={true}>{staticStyles}</style>
    </BasicTableWrapper>
  );
}
