import { useIntl } from 'react-intl';
import messages from '../messages';

interface HowLeverageWorkingProps {
  collateralType?: 'curve' | 'aura';
}

export default function HowLeverageWorking({ collateralType = 'curve' }: HowLeverageWorkingProps) {
  const intl = useIntl();

  const renderAdditionalDescription = () => {
    return (
      <div className="Tooltip__additional-description">
        <span>&#9432;</span>
        <span>{intl.formatMessage(messages.howLeverageWorkingDesc3)}</span>
      </div>
    );
  };

  const renderContent = () => {
    const description1 = intl.formatMessage(messages.howLeverageWorkingDesc1);
    const description2 =
      collateralType === 'curve'
        ? intl.formatMessage(messages.howLeverageWorkingDesc2_Curve)
        : intl.formatMessage(messages.howLeverageWorkingDesc2_Aura);
    const smallParts = description2.split('\n');

    return (
      <div className="Tooltip__content">
        <p>{description1}</p>
        <div>
          {smallParts.map((str, id) => (
            <p key={id}>
              <span>{str}</span>
              <br />
            </p>
          ))}
        </div>
        {renderAdditionalDescription()}
      </div>
    );
  };

  return (
    <>
      <div className="Tooltip__title">{intl.formatMessage(messages.howLeverageWorking)}</div>
      {renderContent()}
    </>
  );
}
