import Value from '../../basic/Value';
import NoData from '../../basic/NoData';
import ValuePercent from '../../basic/ValuePercent';
import SpinLoader from '../../basic/SpinLoader';
import InfoPanelWrapper from '../../Wrappers/InfoPanelWrapper';
import TextWithInfoWrapper from '../../Wrappers/TextWithInfoWrapper';

import { OverviewDataType } from '../types';

import staticStyles from './style';
import { useState } from 'react';
import PriceSlippageModal from 'src/components/PriceSlippageModal';
import { useStaticPoolDataContext } from 'src/libs/pool-data-provider';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';
import { Network, valueToBigNumber } from '@sturdyfi/sturdy-js';

interface LeverageOverviewPanelProps
  extends Pick<
    OverviewDataType,
    | 'borrowPositionComponent'
    | 'healthFactor'
    | 'APY'
    | 'price'
    | 'liquidationPrice'
    | 'gasEstimation'
    | 'loadingGasEstimation'
    | 'slippage'
    | 'slippageExpected'
    | 'leverageSlippage'
    | 'isSlippageLow'
    | 'maxSlippage'
    | 'onSlippageChanged'
  > {}

export default function LeverageOverviewPanel({
  healthFactor,
  APY,
  price,
  liquidationPrice,
  gasEstimation,
  loadingGasEstimation,
  slippage,
  slippageExpected,
  leverageSlippage,
  isSlippageLow,
  maxSlippage,
  onSlippageChanged,
  borrowPositionComponent,
}: LeverageOverviewPanelProps) {
  const [visibleSlippageModal, setVisibleSlippageModal] = useState(false);
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { network } = useProtocolDataContext();

  const formattedPrice = price
    ? network === Network.eth
      ? valueToBigNumber(price).multipliedBy(marketRefPriceInUsd).toString()
      : price
    : undefined;

  const formattedLiquidationPrice = liquidationPrice
    ? network === Network.eth
      ? valueToBigNumber(liquidationPrice).multipliedBy(marketRefPriceInUsd).toString()
      : liquidationPrice
    : undefined;

  const priceUnit = network === Network.eth ? 'ETH' : 'USD';

  return (
    <InfoPanelWrapper title={<span>Transaction overview</span>} className={'LeverageOverviewPanel'}>
      <div className={'LeverageOverviewPanel__item'}>
        <div className={'LeverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Borrow Position'} tooltipName="borrowPosition" />
          <div className={'LeverageOverviewPanel__item__content__value'}>
            {borrowPositionComponent ? borrowPositionComponent : <NoData />}
          </div>
        </div>
      </div>

      <div className={'LeverageOverviewPanel__item'}>
        <div className={'LeverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Health Factor'} tooltipName="healthFactor" />
          <div className={'LeverageOverviewPanel__item__content__value__healthFactor'}>
            {healthFactor ? <Value value={healthFactor} maximumValueDecimals={2} /> : <NoData />}
          </div>
        </div>
        <div className={'LeverageOverviewPanel__item__sub'}>{'Liquidation at < 1.0'}</div>
      </div>

      <div className={'LeverageOverviewPanel__item'}>
        <div className={'LeverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'APY'} tooltipName="depositAPYForCollateral" />
          <div className={'LeverageOverviewPanel__item__content__value'}>
            {APY ? <ValuePercent value={APY} maximumDecimals={2} /> : <NoData />}
          </div>
        </div>
      </div>

      <div className={'LeverageOverviewPanel__item'}>
        <div className={'LeverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Current Price'} tooltipName="currentPrice" />
          <div className={'LeverageOverviewPanel__item__content__value'}>
            {formattedPrice ? (
              <Value
                value={formattedPrice}
                symbol={priceUnit}
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      <div className={'LeverageOverviewPanel__item'}>
        <div className={'LeverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Liquidation Price'} tooltipName="liquidationPrice" />
          <div className={'LeverageOverviewPanel__item__content__value'}>
            {formattedLiquidationPrice ? (
              <Value
                value={formattedLiquidationPrice}
                symbol={priceUnit}
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      <div className={'LeverageOverviewPanel__split'} />

      <div className={'LeverageOverviewPanel__item'}>
        <div className={'LeverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Gas Fee'} tooltipName="gasFee" />
          <div className={'LeverageOverviewPanel__item__content__value'}>
            {loadingGasEstimation ? (
              <SpinLoader color="black" />
            ) : gasEstimation ? (
              <Value
                value={gasEstimation}
                symbol="USD"
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      {typeof slippageExpected !== 'undefined' ? (
        <div className={'LeverageOverviewPanel__item'}>
          <div className={'LeverageOverviewPanel__item__content'}>
            <TextWithInfoWrapper text={'Slippage Bonus/Loss'} tooltipName="slippageBonusLoss" />
            <div className={'LeverageOverviewPanel__item__content__value'}>
              {slippageExpected != 0 ? (
                <ValuePercent
                  className={
                    slippageExpected > 0
                      ? 'LeverageOverviewPanel__item__content__value__slippageBonus'
                      : 'LeverageOverviewPanel__item__content__value__slippageLoss'
                  }
                  value={slippageExpected}
                  maximumDecimals={2}
                />
              ) : (
                <NoData />
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className={'LeverageOverviewPanel__item'}>
        <div className={'LeverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Additional Slippage Tolerance'} tooltipName="priceSlippage" />
          <div className={'LeverageOverviewPanel__item__content__value'}>
            {slippage ? (
              <u onClick={() => setVisibleSlippageModal(true)}>
                <ValuePercent value={slippage} maximumDecimals={2} />
              </u>
            ) : (
              <NoData />
            )}
          </div>
        </div>
        {isSlippageLow && (
          <div className={'LeverageOverviewPanel__item__sub'}>
            {<span className="LowSlippage">Slippage too low</span>}
          </div>
        )}
      </div>

      <div className={'LeverageOverviewPanel__item'}>
        <div className={'LeverageOverviewPanel__item__content'}>
          <p>Leveraged Slippage</p>
          <div className={'LeverageOverviewPanel__item__content__value'}>
            {leverageSlippage ? (
              <ValuePercent value={leverageSlippage} maximumDecimals={2} />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      <PriceSlippageModal
        isVisible={visibleSlippageModal}
        onBackdropPress={() => setVisibleSlippageModal(false)}
        slippage={Number(slippage || 0.005)}
        maxSlippageValue={Number(maxSlippage || 0.05)}
        onSubmitSlippageValue={onSlippageChanged}
      />
      <style jsx={true}>{staticStyles}</style>
    </InfoPanelWrapper>
  );
}
