import {
  CollateralReserveMarketData,
  Network,
  SLIPPAGE_TYPE,
  SWAP_POOL_TYPE,
  UserSummaryData,
  normalize,
  parseNumber,
  tEthereumAddress,
  valueToBigNumber,
} from '@sturdyfi/sturdy-js';
import { MarketDataType } from '../../helpers/markets/types';

import * as logos from './images';
import {
  GLSwapInfoPathType,
  GLSwapInfoType,
} from '@sturdyfi/sturdy-js/dist/tx-builder/types/GeneralLevSwap2MethodTypes';
import ICurvePoolServiceInterface from '@sturdyfi/sturdy-js/dist/tx-builder/interfaces/CurvePoolService';
import IBalancerPoolServiceInterface from '@sturdyfi/sturdy-js/dist/tx-builder/interfaces/BalancerPoolService';
import GeneralLevSwap2Interface from '@sturdyfi/sturdy-js/dist/tx-builder/interfaces/v1/GeneralLevSwap2';
import { BigNumber } from 'ethers';

export enum CustomMarket {
  proto_mainnet = 'proto_mainnet',
  proto_eth = 'proto_eth',
  proto_fork = 'proto_fork',
  proto_ftm = 'proto_ftm',
  proto_ftm_test = 'proto_ftm_test',
}

export const marketsData: { [key in keyof typeof CustomMarket]: MarketDataType } = {
  [CustomMarket.proto_mainnet]: {
    network: Network.mainnet,
    logo: logos.eth_stable,
    activeLogo: logos.eth_stable,
    aTokenPrefix: 's',
    enabledFeatures: {
      governance: false,
      staking: false,
      liquiditySwap: false,
      collateralRepay: false,
      incentives: false,
    },
    flashloanFee: 0,
    maxFlashloanSlippage: 0.05,
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0xb7499a92fc36e9053a4324aFfae59d333635D9c3',
      LENDING_POOL: '0xA422CA380bd70EeF876292839222159E41AAEe17',
      LIDO_VAULT: '0x01c05337354aae5345d27d2A4A70B56a17aF2b4a',
      CONVEX_FRAX_3CRV_VAULT: '0xA77D72A6C3fA97F091E9aa9F0C87e9efCB552628',
      CONVEX_DAI_USDC_USDT_SUSD_VAULT: '0x9700E0B9d22fE10fF00170462c98aBfFa2505DE2',
      CONVEX_IRON_BANK_VAULT: '0xCD1B342cd04A29D7ae86aE54FfCCB8b4580a7192',
      CONVEX_FRAX_USDC_VAULT: '0xf8C91E5BfFDD79Ef7D1d6F9ae3391E021fFCBD73',
      CONVEX_MIM_3CRV_VAULT: '0xe2Cac13bbff65d30BA31C1709B73c60573cFbDA0',
      CONVEX_TUSD_FRAXBP_VAULT: '0x994A8C1AD6C6A65E8D8c6b37A9c8F964A6C985A8',
      AURA_BB_A3_USD_VAULT: '0xcD23DB9B3FF90Cf200C60Efaa9b9F6Fbd139635F',
      CONVEX_FRAX_3CRV_LEVSWAPPER: '0x640f7B78830db57BAfDF6F462d2B458dA80825D3',
      CONVEX_DAI_USDC_USDT_SUSD_LEVSWAPPER: '0x85Ad7A2085d391aF549A4413B84Bd9Fa38eD201A',
      CONVEX_FRAX_USDC_LEVSWAPPER: '0xE60bFF4746C1acCBcCFc8dC46fbc0dCAA9552E61',
      CONVEX_IRON_BANK_LEVSWAPPER: '0x01Eac83b91158e09EaE57a86eE4538134ff47e26',
      CONVEX_MIM_3CRV_LEVSWAPPER: '0x51f2591c31332eF79dc2ad37dE876D2A48F1013f',
      CONVEX_TUSD_FRAXBP_LEVSWAPPER: '0xC972d37d48038D289251347A7E52AeDe64B47a40',
      AURA_BB_A3_USD_LEVSWAPPER: '0xB508171E31273887CB530B5268834C218B3A1303',
      INCENTIVES_CONTROLLER: '0xA3e9B5e1dc6B24F296FfCF9c085E2546A466b883',
      INCENTIVES_CONTROLLER_REWARD_TOKEN: '0x59276455177429ae2af1cc62B77AE31B34EC3890',
    },
  },
  [CustomMarket.proto_eth]: {
    network: Network.eth,
    logo: logos.eth,
    activeLogo: logos.eth,
    aTokenPrefix: 's',
    enabledFeatures: {
      governance: false,
      staking: false,
      liquiditySwap: false,
      collateralRepay: false,
      incentives: false,
    },
    flashloanFee: 0,
    maxFlashloanSlippage: 0.05,
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x40ea6e14800F6040D25A9a5a1E9cC0F5Cb6D1066',
      LENDING_POOL: '0x9f72DC67ceC672bB99e3d02CbEA0a21536a2b657',
      WETH_GATEWAY: '0xE27F1a136C0114ADed15b24b576aD7F71483dCCa',
      CONVEX_ETH_STETH_VAULT: '0xa36BE47700C079BD94adC09f35B0FA93A55297bc',
      AURA_WSTETH_WETH_VAULT: '0x6AE5Fd07c0Bb2264B1F60b33F65920A2b912151C',
      AURA_RETH_WETH_VAULT: '0x1C7E40d95eBb322a285638d88276BfE913E2D62d',
      CONVEX_ETH_STETH_LEVSWAPPER: '0x638B129Ab6d241Ec2F890Aa3af197bF84b5FF1bB',
      AURA_WSTETH_WETH_LEVSWAPPER: '0xf1859145906b08C66fB99E167a1406aC00a2079E',
      AURA_RETH_WETH_LEVSWAPPER: '0x8793DDd75bC1807333A6d03FC266B0cA738283e1',
      INCENTIVES_CONTROLLER: '0x96F8165CEA3B6cDF17D2D15143dDf741B7f7FAE1',
      INCENTIVES_CONTROLLER_REWARD_TOKEN: '0x59276455177429ae2af1cc62B77AE31B34EC3890',
    },
  },
  [CustomMarket.proto_fork]: {
    network: Network.fork,
    logo: logos.aavev2Logo,
    activeLogo: logos.aavev2ActiveLogo,
    aTokenPrefix: 'a',
    enabledFeatures: {
      governance: false,
      staking: false,
      liquiditySwap: false,
      collateralRepay: false,
      incentives: false,
    },
    flashloanFee: 0,
    maxFlashloanSlippage: 0.05,
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x3a5e7db2E0EA9e69fB53Cd8582e64D4001746E8c'.toLowerCase(),
      LENDING_POOL: '0x2A4d822BFB34d377c978F28a6C332Caa2fF87530',
      LIDO_VAULT: '0x01c05337354aae5345d27d2A4A70B56a17aF2b4a',
      CONVEX_FRAX_3CRV_VAULT: '0xb2E308Ef09697ed2b2d740c769CD3b0246DD1e6c',
      CONVEX_DAI_USDC_USDT_SUSD_VAULT: '0xF44275a19A24D016364ddD9541FC9B17735De2f2',
      CONVEX_IRON_BANK_VAULT: '0x8c30CE2bCE4523681DA9644cA63e1E3f5f41c3d6',
      CONVEX_FRAX_USDC_VAULT: '0xAd7D921B94aDA77A38AbE4c70049574b639BBEBA',
      CONVEX_MIM_3CRV_VAULT: '0xfDEba3d5Fd0819864FD652f5d6b0beC7a3DE5BF8',
      CONVEX_TUSD_FRAXBP_VAULT: '0x994A8C1AD6C6A65E8D8c6b37A9c8F964A6C985A8',
      CONVEX_FRAX_3CRV_LEVSWAPPER: '0x7bCeDa3f6554c5fC7AD043840a8546DbE8b90402',
      CONVEX_DAI_USDC_USDT_SUSD_LEVSWAPPER: '0xFa73E18353C9a44b677090708E46e7d1bCed2515',
      CONVEX_FRAX_USDC_LEVSWAPPER: '0x882A796B6f1f1679BBa1dd127EcE41Fd4f7aEacc',
      CONVEX_IRON_BANK_LEVSWAPPER: '0xD436e756Cf41318ADeC62E8dCbEF2608753Ae068',
      CONVEX_MIM_3CRV_LEVSWAPPER: '0xed60B470AD09D99aeF8363F52d75910Bd0079bBa',
      CONVEX_TUSD_FRAXBP_LEVSWAPPER: '0x72352d5Db60027a902a0AE6CF6815A11eB639Edb',
    },
  },
  [CustomMarket.proto_ftm]: {
    network: Network.ftm,
    logo: logos.ftm,
    activeLogo: logos.ftm,
    aTokenPrefix: 's',
    enabledFeatures: {
      governance: false,
      staking: false,
      liquiditySwap: false,
      collateralRepay: false,
      incentives: false,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x3B8569df88A70ECAE31a6bCA1fc3d51BD426189d'.toLowerCase(),
      LENDING_POOL: '0x7FF2520Cd7b76e8C49B5DB51505b842d665f3e9A',
      YEARN_VAULT: '0xc7506f6e48Aa399e571BDc0e15FC608F05375127',
      YEARN_WETH_VAULT: '0x51Dd37AD6BC71629b348F77ab5C978b7CeD931f3',
      YEARN_WBTC_VAULT: '0x94c5Cf77E26615bae9978a06dD8DE0e0a8F7A4EC',
      YEARN_BOO_VAULT: '0xc54cB7d967D139e4FcD414b4Eed2b0Fc19a1461E',
      TOMB_MIMATIC_BEEFY_VAULT: '0x3C0238B16dBA2D11Af549954abA9bfd75A074236',
      BASED_MIMATIC_BEEFY_VAULT: '0xbF9Cc228AEC3D5A80cA44E295E8f029E2D84d0AE',
      YEARN_FBEETS_VAULT: '0xEDb658f0Cf6FF9e25922500B9EC0c97a681B2559',
      YEARN_LINK_VAULT: '0x860544A91A432819C3DCF4B6BB7B6eFF8D2701c2',
      YEARN_SPELL_VAULT: '0x015DE603a68F2EC3A783bc609E1d20906D8Ec675',
      YEARN_CRV_VAULT: '0x5A427C7dE1E27133D292D23a00EBF365c672c87E',
    },
  },
  [CustomMarket.proto_ftm_test]: {
    network: Network.ftm_test,
    logo: logos.aavev2Logo,
    activeLogo: logos.aavev2ActiveLogo,
    aTokenPrefix: 's',
    enabledFeatures: {
      governance: false,
      staking: false,
      liquiditySwap: false,
      collateralRepay: false,
      incentives: false,
      faucet: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x808Ab7D913c2C6db826C7C83B9D2C1316E43E1cA'.toLowerCase(),
      LENDING_POOL: '0x7b07169cCB3CcEe717498e1Cefe6049De02f588D',
      YEARN_VAULT: '0xaAb1BCFF466ABa124327C3135D1F96C0fFefaE93',
      YEARN_WETH_VAULT: '0x6b9ba59c56f096d16D755DF3395b6b66d35d13e1',
      YEARN_WBTC_VAULT: '0x2dd4be604EC805E045bf7d014eBdAdD558cd248e',
      YEARN_BOO_VAULT: '0xDc202B24147910484e2D1b6f385FEf886cA87B6F',
      YEARN_FBEETS_VAULT: '',
      YEARN_LINK_VAULT: '',
      YEARN_SPELL_VAULT: '',
      YEARN_CRV_VAULT: '',
      TOMB_FTM_BEEFY_VAULT: '0x0196D782c84bB31B50C5349e9498D7975D127e4c',
      TOMB_MIMATIC_BEEFY_VAULT: '0x28Ceee5212aA73C4Ac4d635542fE97B4128A5dEf',
      BASED_MIMATIC_BEEFY_VAULT: '0x8706C35bDD49c6029b8113cf02C5544AA5E2375d',
      FAUCET: '0xaDeF6a09F84037ab69Ea88A9b5aa5CA689E58B82',
    },
  },
} as const;

const BORROW_SLIPPAGE = 0.0065; //flashloan fee + extra(swap loss) = 0.65%
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

// borrowable assets
const USDC = '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48';
const USDT = '0xdAC17F958D2ee523a2206206994597C13D831ec7';
const DAI = '0x6b175474e89094c44da98b954eedeac495271d0f';

// curve pools
export const DAI_USDC_USDT_SUSD_POOL = '0xA5407eAE9Ba41422680e2e00537571bcC53efBfD';
export const THREE_CRV_POOL = '0xbEbc44782C7dB0a1A60Cb6fe97d0b483032FF1C7';
export const FRAX_3CRV_POOL = '0xd632f22692FaC7611d2AA1C0D552930D43CAEd3B';
export const MIM_3CRV_POOL = '0x5a6A4D54456819380173272A5E8E9B9904BdF41B';
export const FRAX_USDC_POOL = '0xDcEF968d416a41Cdac0ED8702fAC8128A64241A2';
export const TUSD_FRAXBP_POOL = '0x33baeDa08b8afACc4d3d07cf31d49FC1F1f3E893';
export const TUSD_3CRV_POOL = '0xecd5e75afb02efa118af914515d6521aabd189f1';

// curve pool lp tokens
export const DAI_USDC_USDT_SUSD_LP = '0xC25a3A3b969415c80451098fa907EC722572917F';
export const THREE_CRV_LP = '0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490';
export const FRAX_USDC_LP = '0x3175Df0976dFA876431C2E9eE6Bc45b65d3473CC';
export const FRAX_3CRV_LP = '0xd632f22692FaC7611d2AA1C0D552930D43CAEd3B';
export const MIM_3CRV_LP = '0x5a6A4D54456819380173272A5E8E9B9904BdF41B';
export const TUSD_FRAXBP_LP = '0x33baeDa08b8afACc4d3d07cf31d49FC1F1f3E893';
export const TUSD_3CRV_LP = '0xecd5e75afb02efa118af914515d6521aabd189f1';

// balancer pools
export const BB_A_USDT_POOLID =
  '0x2f4eb100552ef93840d5adc30560e5513dfffacb000000000000000000000334';
export const BB_A_USDC_POOLID =
  '0x82698aecc9e28e9bb27608bd52cf57f704bd1b83000000000000000000000336';
export const BB_A_DAI_POOLID = '0xae37d54ae477268b9997d4161b96b8200755935c000000000000000000000337';
export const BB_A_USD_POOLID = '0xa13a9247ea42d743238089903570127dda72fe4400000000000000000000035d';
export const BB_A3_USDT_POOLID =
  '0xa1697f9af0875b63ddc472d6eebada8c1fab85680000000000000000000004f9';
export const BB_A3_USDC_POOLID =
  '0xcbfa4532d8b2ade2c261d3dd5ef2a2284f7926920000000000000000000004fa';
export const BB_A3_DAI_POOLID =
  '0x6667c6fa9f2b3fc1cc8d85320b62703d938e43850000000000000000000004fb';
export const BB_A3_USD_POOLID =
  '0xfebb0bbf162e64fb9d0dfe186e517d84c395f016000000000000000000000502';

// balancer pool lp tokens
export const BB_A_USDT = '0x2F4eb100552ef93840d5aDC30560E5513DFfFACb';
export const BB_A_USDC = '0x82698aeCc9E28e9Bb27608Bd52cF57f704BD1B83';
export const BB_A_DAI = '0xae37D54Ae477268B9997d4161B96b8200755935c';
export const BB_A_USD = '0xA13a9247ea42D743238089903570127DdA72fE44';
export const BB_A3_USDT = '0xA1697F9Af0875B63DdC472d6EeBADa8C1fAB8568';
export const BB_A3_USDC = '0xcbFA4532D8B2ade2C261D3DD5ef2A2284f792692';
export const BB_A3_DAI = '0x6667c6fa9f2b3Fc1Cc8D85320b62703d938E4385';
export const BB_A3_USD = '0xfeBb0bbf162E64fb9D0dfe186E517d84C395f016';

// other tokens
const TUSD = '0x0000000000085d4780B73119b644AE5ecd22b376';
const SUSD = '0x57Ab1ec28D129707052df4dF418D58a2D46d5f51';
const FRAX = '0x853d955aCEf822Db058eb8505911ED77F175b99e';
const MIM = '0x99D8a9C45b2ecA8864373A26D1459e3Dff1e17F3';

const DAI_USDC_USDT_SUSD_POOL_INFO = {
  pool: DAI_USDC_USDT_SUSD_POOL,
  poolType: SWAP_POOL_TYPE.CURVE,
  coins: [
    { asset: DAI, decimals: 18 },
    { asset: USDC, decimals: 6 },
    { asset: USDT, decimals: 6 },
    { asset: SUSD, decimals: 18 },
  ],
  lpToken: { asset: DAI_USDC_USDT_SUSD_LP, decimals: 18 },
};

const THREE_CRV_POOL_INFO = {
  pool: THREE_CRV_POOL,
  poolType: SWAP_POOL_TYPE.CURVE,
  coins: [
    { asset: DAI, decimals: 18 },
    { asset: USDC, decimals: 6 },
    { asset: USDT, decimals: 6 },
  ],
  lpToken: { asset: THREE_CRV_LP, decimals: 18 },
};

const FRAX_USDC_POOL_INFO = {
  pool: FRAX_USDC_POOL,
  poolType: SWAP_POOL_TYPE.CURVE,
  coins: [
    { asset: FRAX, decimals: 18 },
    { asset: USDC, decimals: 6 },
  ],
  lpToken: { asset: FRAX_USDC_LP, decimals: 18 },
};

const FRAX_3CRV_POOL_INFO = {
  pool: FRAX_3CRV_POOL,
  poolType: SWAP_POOL_TYPE.CURVE,
  coins: [
    { asset: FRAX, decimals: 18 },
    { asset: THREE_CRV_POOL_INFO, decimals: 18 },
  ],
  lpToken: { asset: FRAX_3CRV_LP, decimals: 18 },
};

const MIM_3CRV_POOL_INFO = {
  pool: MIM_3CRV_POOL,
  poolType: SWAP_POOL_TYPE.CURVE,
  coins: [
    { asset: MIM, decimals: 18 },
    { asset: THREE_CRV_POOL_INFO, decimals: 18 },
  ],
  lpToken: { asset: MIM_3CRV_LP, decimals: 18 },
};

const TUSD_3CRV_POOL_INFO = {
  pool: TUSD_3CRV_POOL,
  poolType: SWAP_POOL_TYPE.CURVE,
  coins: [
    { asset: TUSD, decimals: 18 },
    { asset: THREE_CRV_POOL_INFO, decimals: 18 },
  ],
  lpToken: { asset: TUSD_3CRV_LP, decimals: 18 },
};

const TUSD_FRAXBP_POOL_INFO = {
  pool: TUSD_FRAXBP_POOL,
  poolType: SWAP_POOL_TYPE.CURVE,
  coins: [
    { asset: TUSD, decimals: 18 },
    { asset: FRAX_USDC_POOL_INFO, decimals: 18 },
  ],
  lpToken: { asset: TUSD_FRAXBP_LP, decimals: 18 },
};

const BAL_BB_A3_USD_POOL_INFO = {
  pool: BB_A3_USD_POOLID,
  poolType: SWAP_POOL_TYPE.BALANCER,
  coins: [],
  lpToken: { asset: BB_A3_USD, decimals: 18 },
};

const multiSwapPathInitData = {
  routes: new Array(9).fill(ZERO_ADDRESS),
  routeParams: new Array(4).fill([0, 0, 0]) as any,
  swapType: 0, //NONE
  poolCount: 0,
  swapFrom: ZERO_ADDRESS,
  swapTo: ZERO_ADDRESS,
  inAmount: '0',
  outAmount: '0',
};

const getDAI_USDC_USDT_SUSD_SwapInfo = async (
  fromAsset: tEthereumAddress,
  amount: string,
  slippage: number,
  leverage: number,
  curvePool: ICurvePoolServiceInterface,
  curveAPI: any,
  levSwapper: GeneralLevSwap2Interface
): Promise<{ info: GLSwapInfoType; expectedSlippage: number }> => {
  let expectedSlippage;
  const swapInfo: GLSwapInfoType = {
    paths: new Array(3).fill(multiSwapPathInitData) as any,
    reversePaths: new Array(3).fill(multiSwapPathInitData) as any,
    pathLength: 3,
  };
  const coinIndex =
    fromAsset.toLowerCase() === USDT.toLowerCase()
      ? 2
      : fromAsset.toLowerCase() === USDC.toLowerCase()
      ? 1
      : 0;
  const inAmount =
    leverage > 0
      ? (
          await levSwapper.getBorrowAmountToEnterPosition(
            amount,
            leverage,
            { asset: fromAsset, decimals: fromAsset.toLowerCase() === DAI.toLowerCase() ? 18 : 6 },
            DAI_USDC_USDT_SUSD_POOL_INFO,
            BORROW_SLIPPAGE
          )
        ).toString()
      : '0';
  const expectOutAmount =
    leverage > 0
      ? valueToBigNumber(
          (
            await curvePool.getMinAmountOfSwap(
              coinIndex,
              0,
              inAmount,
              true,
              true,
              false,
              DAI_USDC_USDT_SUSD_POOL_INFO
            )
          ).toString()
        )
          .multipliedBy(1 - slippage)
          .toFixed(0)
      : '0';

  swapInfo.paths[0] = {
    ...multiSwapPathInitData,
    swapType: 1, //NO_SWAP: Join/Exit pool
    swapFrom: fromAsset,
    swapTo: DAI_USDC_USDT_SUSD_LP,
    inAmount,
    outAmount: expectOutAmount,
  } as any;

  // Reverse Path
  const outAmount =
    leverage > 0
      ? '0'
      : valueToBigNumber(amount.toString())
          .multipliedBy(1.0005) // aave v3 flashloan fee 0.05%
          .plus(0.5)
          .dp(0, 1) //round down with decimal 0
          .toFixed(0);
  const expectInAmount =
    leverage > 0
      ? '0'
      : valueToBigNumber(
          (
            await curvePool.getMinAmountOfSwap(
              coinIndex,
              0,
              outAmount,
              false,
              true,
              false,
              DAI_USDC_USDT_SUSD_POOL_INFO
            )
          ).toString()
        )
          .multipliedBy(1 + slippage)
          .toFixed(0);

  swapInfo.reversePaths[0] = {
    ...multiSwapPathInitData,
    swapType: 1, //NO_SWAP: Join/Exit pool
    swapFrom: DAI_USDC_USDT_SUSD_LP,
    swapTo: fromAsset,
    inAmount: expectInAmount,
    outAmount,
  } as any;
  swapInfo.pathLength = 1;

  if (leverage > 0) {
    expectedSlippage = await curvePool.getSlippageExpected(
      coinIndex,
      0,
      normalize(inAmount, DAI_USDC_USDT_SUSD_POOL_INFO.coins[coinIndex].decimals),
      DAI_USDC_USDT_SUSD_POOL,
      SLIPPAGE_TYPE.DEPOSIT_BONUS,
      curveAPI
    );
  } else {
    expectedSlippage = await curvePool.getSlippageExpected(
      coinIndex,
      0,
      normalize(outAmount, DAI_USDC_USDT_SUSD_POOL_INFO.coins[coinIndex].decimals),
      DAI_USDC_USDT_SUSD_POOL,
      SLIPPAGE_TYPE.WITHDRAW_IMBALANCE_BONUS,
      curveAPI
    );
  }

  return { info: swapInfo, expectedSlippage: Number(expectedSlippage) };
};

const getFRAX_3CRV_SwapInfo = async (
  fromAsset: tEthereumAddress,
  amount: string,
  slippage: number,
  leverage: number,
  curvePool: ICurvePoolServiceInterface,
  curveAPI: any,
  levSwapper: GeneralLevSwap2Interface,
  userData: UserSummaryData,
  collateralReserve: CollateralReserveMarketData,
  userCollateralBalance: string
): Promise<{ info: GLSwapInfoType; expectedSlippage: number }> => {
  let expectedSlippage;
  const swapInfo: GLSwapInfoType = {
    paths: new Array(3).fill(multiSwapPathInitData) as any,
    reversePaths: new Array(3).fill(multiSwapPathInitData) as any,
    pathLength: 3,
  };
  const coinIndex =
    fromAsset.toLowerCase() === USDT.toLowerCase()
      ? 2
      : fromAsset.toLowerCase() === USDC.toLowerCase()
      ? 1
      : 0;
  const inAmount =
    leverage > 0
      ? (
          await levSwapper.getBorrowAmountToEnterPosition(
            amount,
            leverage,
            { asset: fromAsset, decimals: fromAsset.toLowerCase() === DAI.toLowerCase() ? 18 : 6 },
            FRAX_3CRV_POOL_INFO,
            BORROW_SLIPPAGE
          )
        ).toString()
      : '0';
  const expectOutAmount1 =
    leverage > 0
      ? valueToBigNumber(
          (
            await curvePool.getMinAmountOfSwap(
              coinIndex,
              0,
              inAmount,
              true,
              true,
              false,
              THREE_CRV_POOL_INFO
            )
          ).toString()
        )
          .multipliedBy(1 - slippage)
          .toFixed(0)
      : '0';
  const expectOutAmount2 =
    leverage > 0
      ? valueToBigNumber(
          (
            await curvePool.getMinAmountOfSwap(
              1,
              0,
              expectOutAmount1,
              true,
              true,
              false,
              FRAX_3CRV_POOL_INFO
            )
          ).toString()
        )
          .multipliedBy(1 - slippage)
          .toFixed(0)
      : '0';

  swapInfo.paths = [
    {
      ...multiSwapPathInitData,
      routes: [fromAsset, THREE_CRV_POOL, THREE_CRV_LP, ...new Array(6).fill(ZERO_ADDRESS)],
      routeParams: [
        [coinIndex, 0, 8 /*3-coin-pool add_liquidity*/],
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
      ],
      swapType: 4, // curve
      poolCount: 1,
      swapFrom: fromAsset,
      swapTo: THREE_CRV_LP,
      inAmount,
      outAmount: expectOutAmount1,
    },
    {
      ...multiSwapPathInitData,
      swapType: 1, //NO_SWAP: Join/Exit pool
      poolCount: 0,
      swapFrom: THREE_CRV_LP,
      swapTo: FRAX_3CRV_LP,
      inAmount: expectOutAmount1,
      outAmount: expectOutAmount2,
    },
    multiSwapPathInitData,
  ] as any;

  // Reverse Path
  const reverseInAmount =
    leverage > 0
      ? '0'
      : (
          await levSwapper.getWithdrawAmountToExitPosition(
            parseNumber(userData.totalCollateralETH, 18),
            parseNumber(userData.totalBorrowsETH, 18),
            amount,
            userData.currentLiquidationThreshold,
            collateralReserve.liquidationThreshold,
            userCollateralBalance,
            { asset: FRAX_3CRV_LP, decimals: 18 },
            { asset: fromAsset, decimals: fromAsset.toLowerCase() === DAI.toLowerCase() ? 18 : 6 }
          )
        ).toString();
  const reverseExpectOutAmount1 =
    leverage > 0
      ? '0'
      : valueToBigNumber(
          (
            await curvePool.getMinAmountOfSwap(
              0,
              1,
              reverseInAmount,
              false,
              false,
              false,
              FRAX_3CRV_POOL_INFO
            )
          ).toString()
        )
          .multipliedBy(1 - slippage)
          .toFixed(0);
  const reverseExpectOutAmount2 =
    leverage > 0
      ? '0'
      : valueToBigNumber(
          (
            await curvePool.getMinAmountOfSwap(
              0,
              coinIndex,
              reverseExpectOutAmount1,
              false,
              false,
              false,
              THREE_CRV_POOL_INFO
            )
          ).toString()
        )
          .multipliedBy(1 - slippage)
          .toFixed(0);

  swapInfo.reversePaths = [
    {
      ...multiSwapPathInitData,
      swapType: 1, //NO_SWAP: Join/Exit pool
      swapFrom: FRAX_3CRV_LP,
      swapTo: THREE_CRV_LP,
      inAmount: reverseInAmount,
      outAmount: reverseExpectOutAmount1,
    },
    {
      ...multiSwapPathInitData,
      routes: [THREE_CRV_LP, THREE_CRV_POOL, fromAsset, ...new Array(6).fill(ZERO_ADDRESS)],
      routeParams: [
        [0, coinIndex, 12 /*remove_liquidity_one_coin*/],
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
      ],
      swapType: 4, //Curve
      poolCount: 1,
      swapFrom: THREE_CRV_LP,
      swapTo: fromAsset,
      inAmount: reverseExpectOutAmount1,
      outAmount: reverseExpectOutAmount2,
    },
    multiSwapPathInitData,
  ] as any;
  swapInfo.pathLength = 2;

  if (leverage > 0) {
    expectedSlippage = await curvePool.getSlippageExpected(
      coinIndex + 1,
      0,
      normalize(inAmount, THREE_CRV_POOL_INFO.coins[coinIndex].decimals),
      FRAX_3CRV_POOL,
      SLIPPAGE_TYPE.DEPOSIT_BONUS,
      curveAPI
    );
  } else {
    expectedSlippage = await curvePool.getSlippageExpected(
      0,
      coinIndex + 1,
      normalize(reverseInAmount, FRAX_3CRV_POOL_INFO.lpToken.decimals),
      FRAX_3CRV_POOL,
      SLIPPAGE_TYPE.WITHDRAW_ONECOIN_BONUS,
      curveAPI
    );
  }

  return { info: swapInfo, expectedSlippage: Number(expectedSlippage) };
};

const getMIM_3CRV_SwapInfo = async (
  fromAsset: tEthereumAddress,
  amount: string,
  slippage: number,
  leverage: number,
  curvePool: ICurvePoolServiceInterface,
  curveAPI: any,
  levSwapper: GeneralLevSwap2Interface,
  userData: UserSummaryData,
  collateralReserve: CollateralReserveMarketData,
  userCollateralBalance: string
): Promise<{ info: GLSwapInfoType; expectedSlippage: number }> => {
  let expectedSlippage;
  const swapInfo: GLSwapInfoType = {
    paths: new Array(3).fill(multiSwapPathInitData) as any,
    reversePaths: new Array(3).fill(multiSwapPathInitData) as any,
    pathLength: 3,
  };
  const coinIndex =
    fromAsset.toLowerCase() === USDT.toLowerCase()
      ? 2
      : fromAsset.toLowerCase() === USDC.toLowerCase()
      ? 1
      : 0;
  const inAmount =
    leverage > 0
      ? (
          await levSwapper.getBorrowAmountToEnterPosition(
            amount,
            leverage,
            { asset: fromAsset, decimals: fromAsset.toLowerCase() === DAI.toLowerCase() ? 18 : 6 },
            MIM_3CRV_POOL_INFO,
            BORROW_SLIPPAGE
          )
        ).toString()
      : '0';
  const expectOutAmount1 =
    leverage > 0
      ? valueToBigNumber(
          (
            await curvePool.getMinAmountOfSwap(
              coinIndex,
              0,
              inAmount,
              true,
              true,
              false,
              THREE_CRV_POOL_INFO
            )
          ).toString()
        )
          .multipliedBy(1 - slippage)
          .toFixed(0)
      : '0';
  const expectOutAmount2 =
    leverage > 0
      ? valueToBigNumber(
          (
            await curvePool.getMinAmountOfSwap(
              1,
              0,
              expectOutAmount1,
              true,
              true,
              false,
              MIM_3CRV_POOL_INFO
            )
          ).toString()
        )
          .multipliedBy(1 - slippage)
          .toFixed(0)
      : '0';

  swapInfo.paths = [
    {
      ...multiSwapPathInitData,
      routes: [fromAsset, THREE_CRV_POOL, THREE_CRV_LP, ...new Array(6).fill(ZERO_ADDRESS)],
      routeParams: [
        [coinIndex, 0, 8 /*3-coin-pool add_liquidity*/],
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
      ],
      swapType: 4, // curve
      poolCount: 1,
      swapFrom: fromAsset,
      swapTo: THREE_CRV_LP,
      inAmount,
      outAmount: expectOutAmount1,
    },
    {
      ...multiSwapPathInitData,
      swapType: 1, //NO_SWAP: Join/Exit pool
      poolCount: 0,
      swapFrom: THREE_CRV_LP,
      swapTo: MIM_3CRV_LP,
      inAmount: expectOutAmount1,
      outAmount: expectOutAmount2,
    },
    multiSwapPathInitData,
  ] as any;

  // Reverse Path
  const reverseInAmount =
    leverage > 0
      ? '0'
      : (
          await levSwapper.getWithdrawAmountToExitPosition(
            parseNumber(userData.totalCollateralETH, 18),
            parseNumber(userData.totalBorrowsETH, 18),
            amount,
            userData.currentLiquidationThreshold,
            collateralReserve.liquidationThreshold,
            userCollateralBalance,
            { asset: MIM_3CRV_LP, decimals: 18 },
            { asset: fromAsset, decimals: fromAsset.toLowerCase() === DAI.toLowerCase() ? 18 : 6 }
          )
        ).toString();
  const reverseExpectOutAmount1 =
    leverage > 0
      ? '0'
      : valueToBigNumber(
          (
            await curvePool.getMinAmountOfSwap(
              0,
              1,
              reverseInAmount,
              false,
              false,
              false,
              MIM_3CRV_POOL_INFO
            )
          ).toString()
        )
          .multipliedBy(1 - slippage)
          .toFixed(0);
  const reverseExpectOutAmount2 =
    leverage > 0
      ? '0'
      : valueToBigNumber(
          (
            await curvePool.getMinAmountOfSwap(
              0,
              coinIndex,
              reverseExpectOutAmount1,
              false,
              false,
              false,
              THREE_CRV_POOL_INFO
            )
          ).toString()
        )
          .multipliedBy(1 - slippage)
          .toFixed(0);

  swapInfo.reversePaths = [
    {
      ...multiSwapPathInitData,
      swapType: 1, //NO_SWAP: Join/Exit pool
      swapFrom: MIM_3CRV_LP,
      swapTo: THREE_CRV_LP,
      inAmount: reverseInAmount,
      outAmount: reverseExpectOutAmount1,
    },
    {
      ...multiSwapPathInitData,
      routes: [THREE_CRV_LP, THREE_CRV_POOL, fromAsset, ...new Array(6).fill(ZERO_ADDRESS)],
      routeParams: [
        [0, coinIndex, 12 /*remove_liquidity_one_coin*/],
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
      ],
      swapType: 4, //Curve
      poolCount: 1,
      swapFrom: THREE_CRV_LP,
      swapTo: fromAsset,
      inAmount: reverseExpectOutAmount1,
      outAmount: reverseExpectOutAmount2,
    },
    multiSwapPathInitData,
  ] as any;
  swapInfo.pathLength = 2;

  if (leverage > 0) {
    expectedSlippage = await curvePool.getSlippageExpected(
      coinIndex + 1,
      0,
      normalize(inAmount, THREE_CRV_POOL_INFO.coins[coinIndex].decimals),
      MIM_3CRV_POOL,
      SLIPPAGE_TYPE.DEPOSIT_BONUS,
      curveAPI
    );
  } else {
    expectedSlippage = await curvePool.getSlippageExpected(
      0,
      coinIndex + 1,
      normalize(reverseInAmount, MIM_3CRV_POOL_INFO.lpToken.decimals),
      MIM_3CRV_POOL,
      SLIPPAGE_TYPE.WITHDRAW_ONECOIN_BONUS,
      curveAPI
    );
  }

  return { info: swapInfo, expectedSlippage: Number(expectedSlippage) };
};

const getFRAX_USDC_SwapInfo = async (
  fromAsset: tEthereumAddress,
  amount: string,
  slippage: number,
  leverage: number,
  curvePool: ICurvePoolServiceInterface,
  curveAPI: any,
  levSwapper: GeneralLevSwap2Interface,
  userData: UserSummaryData,
  collateralReserve: CollateralReserveMarketData,
  userCollateralBalance: string
): Promise<{ info: GLSwapInfoType; expectedSlippage: number }> => {
  let expectedSlippage;
  const swapInfo: GLSwapInfoType = {
    paths: new Array(3).fill(multiSwapPathInitData) as any,
    reversePaths: new Array(3).fill(multiSwapPathInitData) as any,
    pathLength: 3,
  };
  const fromUSDCToLPPath = {
    ...multiSwapPathInitData,
    routes: [USDC, FRAX_USDC_POOL, FRAX_USDC_LP, ...new Array(6).fill(ZERO_ADDRESS)],
    routeParams: [
      [1, 0, 7 /*2-coin-pool add_liquidity*/],
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
    ] as any,
    swapType: 4, // curve
    poolCount: 1,
    swapFrom: USDC,
    swapTo: FRAX_USDC_LP,
  };
  const fromUSDCToLPReversePath = {
    ...multiSwapPathInitData,
    routes: [FRAX_USDC_LP, FRAX_USDC_POOL, USDC, ...new Array(6).fill(ZERO_ADDRESS)],
    routeParams: [
      [0, 1, 12 /*2-coin-pool remove_liquidity_one_coin*/],
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
    ] as any,
    swapType: 4, // curve
    poolCount: 1,
    swapFrom: FRAX_USDC_LP,
    swapTo: USDC,
  };
  const coinIndex = fromAsset.toLowerCase() === USDT.toLowerCase() ? 2 : 0; // DAI case
  const inAmount =
    leverage > 0
      ? (
          await levSwapper.getBorrowAmountToEnterPosition(
            amount,
            leverage,
            { asset: fromAsset, decimals: fromAsset.toLowerCase() === DAI.toLowerCase() ? 18 : 6 },
            FRAX_USDC_POOL_INFO,
            BORROW_SLIPPAGE
          )
        ).toString()
      : '0';
  const reverseInAmount =
    leverage > 0
      ? '0'
      : (
          await levSwapper.getWithdrawAmountToExitPosition(
            parseNumber(userData.totalCollateralETH, 18),
            parseNumber(userData.totalBorrowsETH, 18),
            amount,
            userData.currentLiquidationThreshold,
            collateralReserve.liquidationThreshold,
            userCollateralBalance,
            { asset: FRAX_USDC_LP, decimals: 18 },
            { asset: fromAsset, decimals: fromAsset.toLowerCase() === DAI.toLowerCase() ? 18 : 6 }
          )
        ).toString();

  if (fromAsset.toLowerCase() === USDC.toLowerCase()) {
    const expectOutAmount =
      leverage > 0
        ? valueToBigNumber(
            (
              await curvePool.getMinAmountOfSwap(
                1,
                0,
                inAmount,
                true,
                true,
                false,
                FRAX_USDC_POOL_INFO
              )
            ).toString()
          )
            .multipliedBy(1 - slippage)
            .toFixed(0)
        : '0';
    fromUSDCToLPPath.inAmount = inAmount;
    fromUSDCToLPPath.outAmount = expectOutAmount;
    swapInfo.paths[0] = fromUSDCToLPPath as any;

    // Reverse Path - USDC case
    const reverseExpectOutAmount =
      leverage > 0
        ? '0'
        : valueToBigNumber(
            (
              await curvePool.getMinAmountOfSwap(
                0,
                1,
                reverseInAmount,
                false,
                false,
                false,
                FRAX_USDC_POOL_INFO
              )
            ).toString()
          )
            .multipliedBy(1 - slippage)
            .toFixed(0);
    fromUSDCToLPReversePath.inAmount = reverseInAmount;
    fromUSDCToLPReversePath.outAmount = reverseExpectOutAmount;
    swapInfo.reversePaths[0] = fromUSDCToLPReversePath as any;
    swapInfo.pathLength = 1;

    if (leverage > 0) {
      expectedSlippage = await curvePool.getSlippageExpected(
        1,
        0,
        normalize(inAmount, 6),
        FRAX_USDC_POOL,
        SLIPPAGE_TYPE.DEPOSIT_BONUS,
        curveAPI
      );
    } else {
      expectedSlippage = await curvePool.getSlippageExpected(
        0,
        1,
        normalize(reverseInAmount, FRAX_USDC_POOL_INFO.lpToken.decimals),
        FRAX_USDC_POOL,
        SLIPPAGE_TYPE.WITHDRAW_ONECOIN_BONUS,
        curveAPI
      );
    }
  } else {
    const expectOutAmount1 =
      leverage > 0
        ? valueToBigNumber(
            (
              await curvePool.getMinAmountOfSwap(
                coinIndex,
                1,
                inAmount,
                false,
                false,
                true,
                THREE_CRV_POOL_INFO
              )
            ).toString()
          )
            .multipliedBy(1 - slippage)
            .toFixed(0)
        : '0';
    const expectOutAmount2 =
      leverage > 0
        ? valueToBigNumber(
            (
              await curvePool.getMinAmountOfSwap(
                1,
                0,
                expectOutAmount1,
                true,
                true,
                false,
                FRAX_USDC_POOL_INFO
              )
            ).toString()
          )
            .multipliedBy(1 - slippage)
            .toFixed(0)
        : '0';
    fromUSDCToLPPath.inAmount = expectOutAmount1;
    fromUSDCToLPPath.outAmount = expectOutAmount2;

    swapInfo.paths = [
      {
        ...multiSwapPathInitData,
        routes: [fromAsset, THREE_CRV_POOL, USDC, ...new Array(6).fill(ZERO_ADDRESS)],
        routeParams: [
          [coinIndex, 1, 1 /*exchange*/],
          [0, 0, 0],
          [0, 0, 0],
          [0, 0, 0],
        ] as any,
        swapType: 4, // curve
        poolCount: 1,
        swapFrom: fromAsset,
        swapTo: USDC,
        inAmount,
        outAmount: expectOutAmount1,
      },
      fromUSDCToLPPath,
      multiSwapPathInitData,
    ] as any;

    // Reverse Path - Not USDC case
    const reverseExpectOutAmount1 =
      leverage > 0
        ? '0'
        : valueToBigNumber(
            (
              await curvePool.getMinAmountOfSwap(
                0,
                1,
                reverseInAmount,
                false,
                false,
                false,
                FRAX_USDC_POOL_INFO
              )
            ).toString()
          )
            .multipliedBy(1 - slippage)
            .toFixed(0);
    const reverseExpectOutAmount2 =
      leverage > 0
        ? '0'
        : valueToBigNumber(
            (
              await curvePool.getMinAmountOfSwap(
                1,
                coinIndex,
                reverseExpectOutAmount1,
                false,
                false,
                true,
                THREE_CRV_POOL_INFO
              )
            ).toString()
          )
            .multipliedBy(1 - slippage)
            .toFixed(0);
    fromUSDCToLPReversePath.inAmount = reverseInAmount;
    fromUSDCToLPReversePath.outAmount = reverseExpectOutAmount1;

    swapInfo.reversePaths = [
      fromUSDCToLPReversePath,
      {
        ...multiSwapPathInitData,
        routes: [USDC, THREE_CRV_POOL, fromAsset, ...new Array(6).fill(ZERO_ADDRESS)],
        routeParams: [
          [1, coinIndex, 1 /*exchange*/],
          [0, 0, 0],
          [0, 0, 0],
          [0, 0, 0],
        ] as any,
        swapType: 4, // curve
        poolCount: 1,
        swapFrom: USDC,
        swapTo: fromAsset,
        inAmount: reverseExpectOutAmount1,
        outAmount: reverseExpectOutAmount2,
      },
      multiSwapPathInitData,
    ] as any;
    swapInfo.pathLength = 2;

    if (leverage > 0) {
      // const expectedSlippage1 = await curvePool.getSlippageExpected(
      //   coinIndex,
      //   1,
      //   normalize(inAmount, THREE_CRV_POOL_INFO.coins[coinIndex].decimals),
      //   THREE_CRV_POOL,
      //   SLIPPAGE_TYPE.SWAP_PRICE_IMPACT,
      //   curveAPI
      // );

      const expectedSlippage2 = await curvePool.getSlippageExpected(
        1,
        0,
        normalize(expectOutAmount1, 6),
        FRAX_USDC_POOL,
        SLIPPAGE_TYPE.DEPOSIT_BONUS,
        curveAPI
      );

      // expectedSlippage = Math.min(-Number(expectedSlippage1), Number(expectedSlippage2));
      expectedSlippage = expectedSlippage2;
    } else {
      const expectedSlippage1 = await curvePool.getSlippageExpected(
        0,
        1,
        normalize(reverseInAmount, FRAX_USDC_POOL_INFO.lpToken.decimals),
        FRAX_USDC_POOL,
        SLIPPAGE_TYPE.WITHDRAW_ONECOIN_BONUS,
        curveAPI
      );

      // const expectedSlippage2 = await curvePool.getSlippageExpected(
      //   1,
      //   coinIndex,
      //   normalize(reverseExpectOutAmount1, 6),
      //   THREE_CRV_POOL,
      //   SLIPPAGE_TYPE.SWAP_PRICE_IMPACT,
      //   curveAPI
      // );

      // expectedSlippage = Math.min(Number(expectedSlippage1), -Number(expectedSlippage2));
      expectedSlippage = expectedSlippage1;
    }
  }

  return { info: swapInfo, expectedSlippage: Number(expectedSlippage) };
};

const getTUSD_FRAXBP_SwapInfo = async (
  fromAsset: tEthereumAddress,
  amount: string,
  slippage: number,
  leverage: number,
  curvePool: ICurvePoolServiceInterface,
  curveAPI: any,
  levSwapper: GeneralLevSwap2Interface,
  userData: UserSummaryData,
  collateralReserve: CollateralReserveMarketData,
  userCollateralBalance: string
): Promise<{ info: GLSwapInfoType; expectedSlippage: number }> => {
  let expectedSlippage;
  const swapInfo: GLSwapInfoType = {
    paths: new Array(3).fill(multiSwapPathInitData) as any,
    reversePaths: new Array(3).fill(multiSwapPathInitData) as any,
    pathLength: 3,
  };
  const coinIndex = fromAsset.toLowerCase() === USDT.toLowerCase() ? 3 : 1; // DAI case
  const inAmount =
    leverage > 0
      ? (
          await levSwapper.getBorrowAmountToEnterPosition(
            amount,
            leverage,
            { asset: fromAsset, decimals: fromAsset.toLowerCase() === DAI.toLowerCase() ? 18 : 6 },
            TUSD_FRAXBP_POOL_INFO,
            BORROW_SLIPPAGE
          )
        ).toString()
      : '0';
  const reverseInAmount =
    leverage > 0
      ? '0'
      : (
          await levSwapper.getWithdrawAmountToExitPosition(
            parseNumber(userData.totalCollateralETH, 18),
            parseNumber(userData.totalBorrowsETH, 18),
            amount,
            userData.currentLiquidationThreshold,
            collateralReserve.liquidationThreshold,
            userCollateralBalance,
            { asset: TUSD_FRAXBP_LP, decimals: 18 },
            { asset: fromAsset, decimals: fromAsset.toLowerCase() === DAI.toLowerCase() ? 18 : 6 }
          )
        ).toString();

  if (fromAsset.toLowerCase() === USDC.toLowerCase()) {
    const expectOutAmount1 =
      leverage > 0
        ? valueToBigNumber(
            (
              await curvePool.getMinAmountOfSwap(
                1,
                0,
                inAmount,
                true,
                true,
                false,
                FRAX_USDC_POOL_INFO
              )
            ).toString()
          )
            .multipliedBy(1 - slippage)
            .toFixed(0)
        : '0';
    const expectOutAmount2 =
      leverage > 0
        ? valueToBigNumber(
            (
              await curvePool.getMinAmountOfSwap(
                1,
                0,
                expectOutAmount1,
                true,
                true,
                false,
                TUSD_FRAXBP_POOL_INFO
              )
            ).toString()
          )
            .multipliedBy(1 - slippage)
            .toFixed(0)
        : '0';
    swapInfo.paths = [
      {
        ...multiSwapPathInitData,
        routes: [fromAsset, FRAX_USDC_POOL, FRAX_USDC_LP, ...new Array(6).fill(ZERO_ADDRESS)],
        routeParams: [
          [1, 0, 7 /*2-coin-pool add_liquidity*/],
          [0, 0, 0],
          [0, 0, 0],
          [0, 0, 0],
        ] as any,
        swapType: 4, // curve
        poolCount: 1,
        swapFrom: fromAsset,
        swapTo: FRAX_USDC_LP,
        inAmount,
        outAmount: expectOutAmount1,
      },
      {
        ...multiSwapPathInitData,
        swapType: 1, //NO_SWAP: Join/Exit pool
        swapFrom: FRAX_USDC_LP,
        swapTo: TUSD_FRAXBP_LP,
        inAmount: expectOutAmount1,
        outAmount: expectOutAmount2,
      },
      multiSwapPathInitData,
    ] as any;

    // Reverse Path - USDC case
    const reverseExpectOutAmount1 =
      leverage > 0
        ? '0'
        : valueToBigNumber(
            (
              await curvePool.getMinAmountOfSwap(
                0,
                1,
                reverseInAmount,
                false,
                false,
                false,
                TUSD_FRAXBP_POOL_INFO
              )
            ).toString()
          )
            .multipliedBy(1 - slippage)
            .toFixed(0);
    const reverseExpectOutAmount2 =
      leverage > 0
        ? '0'
        : valueToBigNumber(
            (
              await curvePool.getMinAmountOfSwap(
                0,
                1,
                reverseExpectOutAmount1,
                false,
                false,
                false,
                FRAX_USDC_POOL_INFO
              )
            ).toString()
          )
            .multipliedBy(1 - slippage)
            .toFixed(0);
    swapInfo.reversePaths = [
      {
        ...multiSwapPathInitData,
        swapType: 1, //NO_SWAP: Join/Exit pool
        swapFrom: TUSD_FRAXBP_LP,
        swapTo: FRAX_USDC_LP,
        inAmount: reverseInAmount,
        outAmount: reverseExpectOutAmount1,
      },
      {
        ...multiSwapPathInitData,
        routes: [FRAX_USDC_LP, FRAX_USDC_POOL, fromAsset, ...new Array(6).fill(ZERO_ADDRESS)],
        routeParams: [
          [0, 1, 12 /*remove_liquidity_one_coin*/],
          [0, 0, 0],
          [0, 0, 0],
          [0, 0, 0],
        ] as any,
        swapType: 4, // curve
        poolCount: 1,
        swapFrom: FRAX_USDC_LP,
        swapTo: fromAsset,
        inAmount: reverseExpectOutAmount1,
        outAmount: reverseExpectOutAmount2,
      },
      multiSwapPathInitData,
    ] as any;

    if (leverage > 0) {
      const expectedSlippage1 = await curvePool.getSlippageExpected(
        1,
        0,
        normalize(inAmount, 6),
        FRAX_USDC_POOL,
        SLIPPAGE_TYPE.DEPOSIT_BONUS,
        curveAPI
      );

      const expectedSlippage2 = await curvePool.getSlippageExpected(
        1,
        0,
        normalize(expectOutAmount1, TUSD_FRAXBP_POOL_INFO.coins[1].decimals),
        TUSD_FRAXBP_POOL,
        SLIPPAGE_TYPE.DEPOSIT_BONUS,
        curveAPI
      );

      expectedSlippage = Math.min(Number(expectedSlippage1), Number(expectedSlippage2));
    } else {
      const expectedSlippage1 = await curvePool.getSlippageExpected(
        0,
        1,
        normalize(reverseInAmount, TUSD_FRAXBP_POOL_INFO.lpToken.decimals),
        TUSD_FRAXBP_POOL,
        SLIPPAGE_TYPE.WITHDRAW_ONECOIN_BONUS,
        curveAPI
      );

      const expectedSlippage2 = await curvePool.getSlippageExpected(
        0,
        1,
        normalize(reverseExpectOutAmount1, FRAX_USDC_POOL_INFO.lpToken.decimals),
        FRAX_USDC_POOL,
        SLIPPAGE_TYPE.WITHDRAW_ONECOIN_BONUS,
        curveAPI
      );

      expectedSlippage = Math.min(Number(expectedSlippage1), Number(expectedSlippage2));
    }
  } else {
    const expectOutAmount1 =
      leverage > 0
        ? valueToBigNumber(
            (
              await curvePool.getMinAmountOfSwap(
                coinIndex,
                0,
                inAmount,
                false,
                false,
                true,
                TUSD_3CRV_POOL_INFO
              )
            ).toString()
          )
            .multipliedBy(1 - slippage)
            .toFixed(0)
        : '0';
    const expectOutAmount2 =
      leverage > 0
        ? valueToBigNumber(
            (
              await curvePool.getMinAmountOfSwap(
                0,
                0,
                expectOutAmount1,
                true,
                true,
                false,
                TUSD_FRAXBP_POOL_INFO
              )
            ).toString()
          )
            .multipliedBy(1 - slippage)
            .toFixed(0)
        : '0';
    swapInfo.paths = [
      {
        ...multiSwapPathInitData,
        routes: [fromAsset, TUSD_3CRV_POOL, TUSD, ...new Array(6).fill(ZERO_ADDRESS)],
        routeParams: [
          [coinIndex, 0, 2 /*exchange_underlying*/],
          [0, 0, 0],
          [0, 0, 0],
          [0, 0, 0],
        ] as any,
        swapType: 4, // curve
        poolCount: 1,
        swapFrom: fromAsset,
        swapTo: TUSD,
        inAmount,
        outAmount: expectOutAmount1,
      },
      {
        ...multiSwapPathInitData,
        swapType: 1, //NO_SWAP: Join/Exit pool
        swapFrom: TUSD,
        swapTo: TUSD_FRAXBP_LP,
        inAmount: expectOutAmount1,
        outAmount: expectOutAmount2,
      },
      multiSwapPathInitData,
    ] as any;

    // Reverse Path - not USDC case
    const reverseExpectOutAmount1 =
      leverage > 0
        ? '0'
        : valueToBigNumber(
            (
              await curvePool.getMinAmountOfSwap(
                0,
                0,
                reverseInAmount,
                false,
                false,
                false,
                TUSD_FRAXBP_POOL_INFO
              )
            ).toString()
          )
            .multipliedBy(1 - slippage)
            .toFixed(0);
    const reverseExpectOutAmount2 =
      leverage > 0
        ? '0'
        : valueToBigNumber(
            (
              await curvePool.getMinAmountOfSwap(
                0,
                coinIndex,
                reverseExpectOutAmount1,
                false,
                false,
                true,
                TUSD_3CRV_POOL_INFO
              )
            ).toString()
          )
            .multipliedBy(1 - slippage)
            .toFixed(0);

    swapInfo.reversePaths = [
      {
        ...multiSwapPathInitData,
        swapType: 1, //NO_SWAP: Join/Exit pool
        swapFrom: TUSD_FRAXBP_LP,
        swapTo: TUSD,
        inAmount: reverseInAmount,
        outAmount: reverseExpectOutAmount1,
      },
      {
        ...multiSwapPathInitData,
        routes: [TUSD, TUSD_3CRV_POOL, fromAsset, ...new Array(6).fill(ZERO_ADDRESS)],
        routeParams: [
          [0, coinIndex, 2 /*exchange_underlying*/],
          [0, 0, 0],
          [0, 0, 0],
          [0, 0, 0],
        ] as any,
        swapType: 4, // curve
        poolCount: 1,
        swapFrom: TUSD,
        swapTo: fromAsset,
        inAmount: reverseExpectOutAmount1,
        outAmount: reverseExpectOutAmount2,
      },
      multiSwapPathInitData,
    ] as any;

    if (leverage > 0) {
      // const expectedSlippage1 = await curvePool.getSlippageExpected(
      //   coinIndex,
      //   0,
      //   normalize(inAmount, TUSD_3CRV_POOL_INFO.coins[coinIndex].decimals),
      //   TUSD_3CRV_POOL,
      //   SLIPPAGE_TYPE.SWAP_PRICE_IMPACT,
      //   curveAPI
      // );

      const expectedSlippage2 = await curvePool.getSlippageExpected(
        0,
        0,
        normalize(expectOutAmount1, TUSD_FRAXBP_POOL_INFO.coins[0].decimals),
        TUSD_FRAXBP_POOL,
        SLIPPAGE_TYPE.DEPOSIT_BONUS,
        curveAPI
      );

      // expectedSlippage = Math.min(-Number(expectedSlippage1), Number(expectedSlippage2));
      expectedSlippage = expectedSlippage2;
    } else {
      const expectedSlippage1 = await curvePool.getSlippageExpected(
        0,
        0,
        normalize(reverseInAmount, TUSD_FRAXBP_POOL_INFO.lpToken.decimals),
        TUSD_FRAXBP_POOL,
        SLIPPAGE_TYPE.WITHDRAW_ONECOIN_BONUS,
        curveAPI
      );

      // const expectedSlippage2 = await curvePool.getSlippageExpected(
      //   0,
      //   coinIndex,
      //   normalize(reverseExpectOutAmount1, TUSD_3CRV_POOL_INFO.coins[0].decimals),
      //   TUSD_3CRV_POOL,
      //   SLIPPAGE_TYPE.SWAP_PRICE_IMPACT,
      //   curveAPI
      // );

      // expectedSlippage = Math.min(-Number(expectedSlippage1), -Number(expectedSlippage2));
      expectedSlippage = expectedSlippage1;
    }
  }
  swapInfo.pathLength = 2;

  return { info: swapInfo, expectedSlippage: Number(expectedSlippage) };
};

const getBAL_BB_A3_USD_SwapInfo = async (
  fromAsset: tEthereumAddress,
  amount: string,
  slippage: number,
  leverage: number,
  balancerPool: IBalancerPoolServiceInterface,
  levSwapper: GeneralLevSwap2Interface,
  userData: UserSummaryData,
  collateralReserve: CollateralReserveMarketData,
  userCollateralBalance: string
): Promise<{ info: GLSwapInfoType; expectedSlippage: number }> => {
  const swapInfo: GLSwapInfoType = {
    paths: new Array(3).fill(multiSwapPathInitData) as any,
    reversePaths: new Array(3).fill(multiSwapPathInitData) as any,
    pathLength: 3,
  };
  const inAmount =
    leverage > 0
      ? (
          await levSwapper.getBorrowAmountToEnterPosition(
            amount,
            leverage,
            { asset: fromAsset, decimals: fromAsset.toLowerCase() === DAI.toLowerCase() ? 18 : 6 },
            BAL_BB_A3_USD_POOL_INFO,
            BORROW_SLIPPAGE
          )
        ).toString()
      : '0';
  const reverseInAmount =
    leverage > 0
      ? '0'
      : (
          await levSwapper.getWithdrawAmountToExitPosition(
            parseNumber(userData.totalCollateralETH, 18),
            parseNumber(userData.totalBorrowsETH, 18),
            amount,
            userData.currentLiquidationThreshold,
            collateralReserve.liquidationThreshold,
            userCollateralBalance,
            { asset: BB_A3_USD, decimals: 18 },
            { asset: fromAsset, decimals: fromAsset.toLowerCase() === DAI.toLowerCase() ? 18 : 6 }
          )
        ).toString();

  if (fromAsset.toLowerCase() === USDC.toLowerCase()) {
    const expectOutAmount1 =
      leverage > 0
        ? (
            await balancerPool.getMinAmountOfSwap(
              inAmount,
              { asset: USDC, decimals: 6 },
              { asset: BB_A3_USD, decimals: 18 },
              slippage
            )
          ).toString()
        : '0';

    const reverseExpectOutAmount1 =
      leverage > 0
        ? (
            await balancerPool.getMinAmountOfSwap(
              reverseInAmount,
              { asset: BB_A3_USD, decimals: 18 },
              { asset: USDC, decimals: 6 },
              slippage
            )
          ).toString()
        : '0';

    swapInfo.paths = [
      {
        ...multiSwapPathInitData,
        routes: [
          fromAsset,
          ZERO_ADDRESS,
          BB_A3_USDC,
          ZERO_ADDRESS,
          BB_A3_USD,
          ...new Array(4).fill(ZERO_ADDRESS),
        ],
        routeParams: [
          [BB_A3_USDC_POOLID, 0, 0],
          [BB_A3_USD_POOLID, 0, 0],
          [0, 0, 0],
          [0, 0, 0],
        ] as any,
        swapType: 3, // balancer
        poolCount: 2,
        swapFrom: fromAsset,
        swapTo: BB_A3_USD,
        inAmount,
        outAmount: expectOutAmount1,
      },
      multiSwapPathInitData,
      multiSwapPathInitData,
    ] as any;

    swapInfo.reversePaths = [
      {
        ...multiSwapPathInitData,
        routes: [
          BB_A3_USD,
          ZERO_ADDRESS,
          BB_A3_USDC,
          ZERO_ADDRESS,
          fromAsset,
          ...new Array(4).fill(ZERO_ADDRESS),
        ],
        routeParams: [
          [BB_A3_USD_POOLID, 0, 0],
          [BB_A3_USDC_POOLID, 0, 0],
          [0, 0, 0],
          [0, 0, 0],
        ] as any,
        swapType: 3, // balancer
        poolCount: 2,
        swapFrom: BB_A3_USD,
        swapTo: fromAsset,
        inAmount: reverseInAmount,
        outAmount: reverseExpectOutAmount1,
      },
      multiSwapPathInitData,
      multiSwapPathInitData,
    ] as any;
  } else if (fromAsset.toLowerCase() === USDT.toLowerCase()) {
    const expectOutAmount1 =
      leverage > 0
        ? (
            await balancerPool.getMinAmountOfSwap(
              inAmount,
              { asset: USDT, decimals: 6 },
              { asset: BB_A3_USD, decimals: 18 },
              slippage
            )
          ).toString()
        : '0';
    const reverseExpectOutAmount1 =
      leverage > 0
        ? (
            await balancerPool.getMinAmountOfSwap(
              reverseInAmount,
              { asset: BB_A3_USD, decimals: 18 },
              { asset: USDT, decimals: 6 },
              slippage
            )
          ).toString()
        : '0';
    swapInfo.paths = [
      {
        ...multiSwapPathInitData,
        routes: [
          fromAsset,
          ZERO_ADDRESS,
          BB_A3_USDT,
          ZERO_ADDRESS,
          BB_A3_USD,
          ...new Array(4).fill(ZERO_ADDRESS),
        ],
        routeParams: [
          [BB_A3_USDT_POOLID, 0, 0],
          [BB_A3_USD_POOLID, 0, 0],
          [0, 0, 0],
          [0, 0, 0],
        ] as any,
        swapType: 3, // balancer
        poolCount: 2,
        swapFrom: fromAsset,
        swapTo: BB_A3_USD,
        inAmount,
        outAmount: expectOutAmount1,
      },
      multiSwapPathInitData,
      multiSwapPathInitData,
    ] as any;

    swapInfo.reversePaths = [
      {
        ...multiSwapPathInitData,
        routes: [
          BB_A3_USD,
          ZERO_ADDRESS,
          BB_A3_USDT,
          ZERO_ADDRESS,
          fromAsset,
          ...new Array(4).fill(ZERO_ADDRESS),
        ],
        routeParams: [
          [BB_A3_USD_POOLID, 0, 0],
          [BB_A3_USDT_POOLID, 0, 0],
          [0, 0, 0],
          [0, 0, 0],
        ] as any,
        swapType: 3, // balancer
        poolCount: 2,
        swapFrom: BB_A3_USD,
        swapTo: fromAsset,
        inAmount: reverseInAmount,
        outAmount: reverseExpectOutAmount1,
      },
      multiSwapPathInitData,
      multiSwapPathInitData,
    ] as any;
  } else if (fromAsset.toLowerCase() === DAI.toLowerCase()) {
    const expectOutAmount1 =
      leverage > 0
        ? valueToBigNumber(
            (
              await balancerPool.getMinAmountOfSwap(
                inAmount,
                { asset: DAI, decimals: 18 },
                { asset: BB_A3_USD, decimals: 18 },
                slippage
              )
            ).toString()
          )
            .multipliedBy(0.95) // hotfix: to figure out error - BAL#507
            .toFixed(0)
        : '0';
    const reverseExpectOutAmount1 =
      leverage > 0
        ? (
            await balancerPool.getMinAmountOfSwap(
              reverseInAmount,
              { asset: BB_A3_USD, decimals: 18 },
              { asset: DAI, decimals: 18 },
              slippage
            )
          ).toString()
        : '0';
    swapInfo.paths = [
      {
        ...multiSwapPathInitData,
        routes: [
          fromAsset,
          ZERO_ADDRESS,
          BB_A3_DAI,
          ZERO_ADDRESS,
          BB_A3_USD,
          ...new Array(4).fill(ZERO_ADDRESS),
        ],
        routeParams: [
          [BB_A3_DAI_POOLID, 0, 0],
          [BB_A3_USD_POOLID, 0, 0],
          [0, 0, 0],
          [0, 0, 0],
        ] as any,
        swapType: 3, // balancer
        poolCount: 2,
        swapFrom: fromAsset,
        swapTo: BB_A3_USD,
        inAmount,
        outAmount: expectOutAmount1,
      },
      multiSwapPathInitData,
      multiSwapPathInitData,
    ] as any;

    swapInfo.reversePaths = [
      {
        ...multiSwapPathInitData,
        routes: [
          BB_A3_USD,
          ZERO_ADDRESS,
          BB_A3_DAI,
          ZERO_ADDRESS,
          fromAsset,
          ...new Array(4).fill(ZERO_ADDRESS),
        ],
        routeParams: [
          [BB_A3_USD_POOLID, 0, 0],
          [BB_A3_DAI_POOLID, 0, 0],
          [0, 0, 0],
          [0, 0, 0],
        ] as any,
        swapType: 3, // balancer
        poolCount: 2,
        swapFrom: BB_A3_USD,
        swapTo: fromAsset,
        inAmount: reverseInAmount,
        outAmount: reverseExpectOutAmount1,
      },
      multiSwapPathInitData,
      multiSwapPathInitData,
    ] as any;
  }

  swapInfo.pathLength = 1;

  return { info: swapInfo, expectedSlippage: slippage };
};

export const getSwapInfo = async (
  collateralSymbol: string,
  fromAsset: tEthereumAddress,
  amount: string,
  slippage: number,
  leverage: number,
  poolService: ICurvePoolServiceInterface | IBalancerPoolServiceInterface,
  curveAPI: any,
  levSwapper: GeneralLevSwap2Interface,
  userData: UserSummaryData,
  collateralReserve: CollateralReserveMarketData,
  userCollateralBalance: string
): Promise<{ info: GLSwapInfoType; expectedSlippage: number }> => {
  let swapInfo: { info: GLSwapInfoType; expectedSlippage: number } = {
    info: {
      paths: new Array(3).fill(multiSwapPathInitData) as any,
      reversePaths: new Array(3).fill(multiSwapPathInitData) as any,
      pathLength: 3,
    },
    expectedSlippage: 0,
  };

  if (collateralSymbol === 'DAI_USDC_USDT_SUSD_LP') {
    swapInfo = await getDAI_USDC_USDT_SUSD_SwapInfo(
      fromAsset,
      amount,
      slippage,
      leverage,
      poolService as ICurvePoolServiceInterface,
      curveAPI,
      levSwapper
    );
  }

  if (collateralSymbol === 'FRAX_3CRV_LP') {
    swapInfo = await getFRAX_3CRV_SwapInfo(
      fromAsset,
      amount,
      slippage,
      leverage,
      poolService as ICurvePoolServiceInterface,
      curveAPI,
      levSwapper,
      userData,
      collateralReserve,
      userCollateralBalance
    );
  }

  if (collateralSymbol === 'MIM_3CRV_LP') {
    swapInfo = await getMIM_3CRV_SwapInfo(
      fromAsset,
      amount,
      slippage,
      leverage,
      poolService as ICurvePoolServiceInterface,
      curveAPI,
      levSwapper,
      userData,
      collateralReserve,
      userCollateralBalance
    );
  }

  if (collateralSymbol === 'FRAX_USDC_LP') {
    swapInfo = await getFRAX_USDC_SwapInfo(
      fromAsset,
      amount,
      slippage,
      leverage,
      poolService as ICurvePoolServiceInterface,
      curveAPI,
      levSwapper,
      userData,
      collateralReserve,
      userCollateralBalance
    );
  }

  if (collateralSymbol === 'TUSD_FRAXBP_LP') {
    swapInfo = await getTUSD_FRAXBP_SwapInfo(
      fromAsset,
      amount,
      slippage,
      leverage,
      poolService as ICurvePoolServiceInterface,
      curveAPI,
      levSwapper,
      userData,
      collateralReserve,
      userCollateralBalance
    );
  }

  if (collateralSymbol === 'BAL_BB_A3_USD_LP') {
    swapInfo = await getBAL_BB_A3_USD_SwapInfo(
      fromAsset,
      amount,
      slippage,
      leverage,
      poolService as IBalancerPoolServiceInterface,
      levSwapper,
      userData,
      collateralReserve,
      userCollateralBalance
    );
  }

  return swapInfo;
};

const getDAI_USDC_USDT_SUSD_ZapSwapInfo = async (
  zapAsset: tEthereumAddress,
  inAmount: string,
  slippage: number,
  curvePool: ICurvePoolServiceInterface,
  curveAPI: any
): Promise<any> => {
  const swapInfo: Array<any> = [];
  const coinIndex =
    zapAsset.toLowerCase() === USDT.toLowerCase()
      ? 2
      : zapAsset.toLowerCase() === USDC.toLowerCase()
      ? 1
      : 0;
  const expectOutAmount = valueToBigNumber(
    (
      await curvePool.getMinAmountOfSwap(
        coinIndex,
        0,
        inAmount,
        true,
        true,
        false,
        DAI_USDC_USDT_SUSD_POOL_INFO
      )
    ).toString()
  );

  swapInfo.push({
    ...multiSwapPathInitData,
    swapType: 1, //NO_SWAP: Join/Exit pool
    swapFrom: zapAsset,
    swapTo: DAI_USDC_USDT_SUSD_LP,
    inAmount,
    outAmount: expectOutAmount.multipliedBy(1 - slippage).toFixed(0),
  });

  const expectedSlippage = await curvePool.getSlippageExpected(
    coinIndex,
    0,
    normalize(inAmount, DAI_USDC_USDT_SUSD_POOL_INFO.coins[coinIndex].decimals),
    DAI_USDC_USDT_SUSD_POOL,
    SLIPPAGE_TYPE.DEPOSIT_BONUS,
    curveAPI
  );

  return { swapInfo, outAmount: expectOutAmount.toFixed(0), expectedSlippage };
};

const getFRAX_3CRV_ZapSwapInfo = async (
  zapAsset: tEthereumAddress,
  inAmount: string,
  slippage: number,
  curvePool: ICurvePoolServiceInterface,
  curveAPI: any
): Promise<any> => {
  const swapInfo: Array<any> = [];
  const coinIndex =
    zapAsset.toLowerCase() === USDT.toLowerCase()
      ? 2
      : zapAsset.toLowerCase() === USDC.toLowerCase()
      ? 1
      : 0;

  const expectOutAmount1 = valueToBigNumber(
    (
      await curvePool.getMinAmountOfSwap(
        coinIndex,
        0,
        inAmount,
        true,
        true,
        false,
        THREE_CRV_POOL_INFO
      )
    ).toString()
  )
    .multipliedBy(1 - slippage)
    .toFixed(0);
  const expectOutAmount2 = valueToBigNumber(
    (
      await curvePool.getMinAmountOfSwap(
        1,
        0,
        expectOutAmount1,
        true,
        true,
        false,
        FRAX_3CRV_POOL_INFO
      )
    ).toString()
  );

  swapInfo.push({
    ...multiSwapPathInitData,
    routes: [zapAsset, THREE_CRV_POOL, THREE_CRV_LP, ...new Array(6).fill(ZERO_ADDRESS)],
    routeParams: [
      [coinIndex, 0, 8 /*3-coin-pool add_liquidity*/],
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
    ],
    swapType: 4, // curve
    poolCount: 1,
    swapFrom: zapAsset,
    swapTo: THREE_CRV_LP,
    inAmount,
    outAmount: expectOutAmount1,
  });
  swapInfo.push({
    ...multiSwapPathInitData,
    swapType: 1, //NO_SWAP: Join/Exit pool
    poolCount: 0,
    swapFrom: THREE_CRV_LP,
    swapTo: FRAX_3CRV_LP,
    inAmount: expectOutAmount1,
    outAmount: expectOutAmount2.multipliedBy(1 - slippage).toFixed(0),
  });

  const expectedSlippage = await curvePool.getSlippageExpected(
    coinIndex + 1,
    0,
    normalize(inAmount, THREE_CRV_POOL_INFO.coins[coinIndex].decimals),
    FRAX_3CRV_POOL,
    SLIPPAGE_TYPE.DEPOSIT_BONUS,
    curveAPI
  );

  return { swapInfo, outAmount: expectOutAmount2.toFixed(0), expectedSlippage };
};

const getMIM_3CRV_ZapSwapInfo = async (
  zapAsset: tEthereumAddress,
  inAmount: string,
  slippage: number,
  curvePool: ICurvePoolServiceInterface,
  curveAPI: any
): Promise<any> => {
  const swapInfo: Array<any> = [];
  const coinIndex =
    zapAsset.toLowerCase() === USDT.toLowerCase()
      ? 2
      : zapAsset.toLowerCase() === USDC.toLowerCase()
      ? 1
      : 0;

  const expectOutAmount1 = valueToBigNumber(
    (
      await curvePool.getMinAmountOfSwap(
        coinIndex,
        0,
        inAmount,
        true,
        true,
        false,
        THREE_CRV_POOL_INFO
      )
    ).toString()
  )
    .multipliedBy(1 - slippage)
    .toFixed(0);
  const expectOutAmount2 = valueToBigNumber(
    (
      await curvePool.getMinAmountOfSwap(
        1,
        0,
        expectOutAmount1,
        true,
        true,
        false,
        MIM_3CRV_POOL_INFO
      )
    ).toString()
  );

  swapInfo.push({
    ...multiSwapPathInitData,
    routes: [zapAsset, THREE_CRV_POOL, THREE_CRV_LP, ...new Array(6).fill(ZERO_ADDRESS)],
    routeParams: [
      [coinIndex, 0, 8 /*3-coin-pool add_liquidity*/],
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
    ],
    swapType: 4, // curve
    poolCount: 1,
    swapFrom: zapAsset,
    swapTo: THREE_CRV_LP,
    inAmount,
    outAmount: expectOutAmount1,
  });
  swapInfo.push({
    ...multiSwapPathInitData,
    swapType: 1, //NO_SWAP: Join/Exit pool
    poolCount: 0,
    swapFrom: THREE_CRV_LP,
    swapTo: MIM_3CRV_LP,
    inAmount: expectOutAmount1,
    outAmount: expectOutAmount2.multipliedBy(1 - slippage).toFixed(0),
  });

  const expectedSlippage = await curvePool.getSlippageExpected(
    coinIndex + 1,
    0,
    normalize(inAmount, THREE_CRV_POOL_INFO.coins[coinIndex].decimals),
    MIM_3CRV_POOL,
    SLIPPAGE_TYPE.DEPOSIT_BONUS,
    curveAPI
  );

  return { swapInfo, outAmount: expectOutAmount2.toFixed(0), expectedSlippage };
};

const getFRAX_USDC_ZapSwapInfo = async (
  zapAsset: tEthereumAddress,
  inAmount: string,
  slippage: number,
  curvePool: ICurvePoolServiceInterface,
  curveAPI: any
): Promise<any> => {
  let expectOutAmount2;
  let expectedSlippage;
  const swapInfo: Array<any> = [];
  const fromUSDCToLPPath = {
    ...multiSwapPathInitData,
    routes: [USDC, FRAX_USDC_POOL, FRAX_USDC_LP, ...new Array(6).fill(ZERO_ADDRESS)],
    routeParams: [
      [1, 0, 7 /*2-coin-pool add_liquidity*/],
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
    ] as any,
    swapType: 4, // curve
    poolCount: 1,
    swapFrom: USDC,
    swapTo: FRAX_USDC_LP,
  };
  const coinIndex = zapAsset.toLowerCase() === USDT.toLowerCase() ? 2 : 0;

  if (zapAsset.toLowerCase() === USDC.toLowerCase()) {
    const expectOutAmount = valueToBigNumber(
      (
        await curvePool.getMinAmountOfSwap(1, 0, inAmount, true, true, false, FRAX_USDC_POOL_INFO)
      ).toString()
    );
    fromUSDCToLPPath.inAmount = inAmount;
    fromUSDCToLPPath.outAmount = expectOutAmount.multipliedBy(1 - slippage).toFixed(0);
    expectOutAmount2 = expectOutAmount;
    swapInfo.push(fromUSDCToLPPath);

    expectedSlippage = await curvePool.getSlippageExpected(
      1,
      0,
      normalize(inAmount, 6),
      FRAX_USDC_POOL,
      SLIPPAGE_TYPE.DEPOSIT_BONUS,
      curveAPI
    );
  } else {
    const expectOutAmount1 = valueToBigNumber(
      (
        await curvePool.getMinAmountOfSwap(
          coinIndex,
          1,
          inAmount,
          false,
          false,
          true,
          THREE_CRV_POOL_INFO
        )
      ).toString()
    )
      .multipliedBy(1 - slippage)
      .toFixed(0);
    expectOutAmount2 = valueToBigNumber(
      (
        await curvePool.getMinAmountOfSwap(
          1,
          0,
          expectOutAmount1,
          true,
          true,
          false,
          FRAX_USDC_POOL_INFO
        )
      ).toString()
    );

    swapInfo.push({
      ...multiSwapPathInitData,
      routes: [zapAsset, THREE_CRV_POOL, USDC, ...new Array(6).fill(ZERO_ADDRESS)],
      routeParams: [
        [coinIndex, 1, 1 /*exchange*/],
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
      ] as any,
      swapType: 4, // curve
      poolCount: 1,
      swapFrom: zapAsset,
      swapTo: USDC,
      inAmount,
      outAmount: expectOutAmount1,
    });

    fromUSDCToLPPath.inAmount = expectOutAmount1;
    fromUSDCToLPPath.outAmount = expectOutAmount2.multipliedBy(1 - slippage).toFixed(0);
    swapInfo.push(fromUSDCToLPPath);

    // const expectedSlippage1 = await curvePool.getSlippageExpected(
    //   coinIndex,
    //   1,
    //   normalize(inAmount, THREE_CRV_POOL_INFO.coins[coinIndex].decimals),
    //   THREE_CRV_POOL,
    //   SLIPPAGE_TYPE.SWAP_PRICE_IMPACT,
    //   curveAPI
    // );

    const expectedSlippage2 = await curvePool.getSlippageExpected(
      1,
      0,
      normalize(expectOutAmount1, 6),
      FRAX_USDC_POOL,
      SLIPPAGE_TYPE.DEPOSIT_BONUS,
      curveAPI
    );

    // expectedSlippage = Math.min(-Number(expectedSlippage1), Number(expectedSlippage2));
    expectedSlippage = expectedSlippage2;
  }

  return { swapInfo, outAmount: expectOutAmount2.toFixed(0), expectedSlippage };
};

const getTUSD_FRAXBP_ZapSwapInfo = async (
  zapAsset: tEthereumAddress,
  inAmount: string,
  slippage: number,
  curvePool: ICurvePoolServiceInterface,
  curveAPI: any
): Promise<any> => {
  let expectOutAmount2;
  let expectedSlippage;
  const swapInfo: Array<any> = [];
  const coinIndex = zapAsset.toLowerCase() === USDT.toLowerCase() ? 3 : 0;

  if (zapAsset.toLowerCase() === USDC.toLowerCase()) {
    const expectOutAmount1 = valueToBigNumber(
      (
        await curvePool.getMinAmountOfSwap(1, 0, inAmount, true, true, false, FRAX_USDC_POOL_INFO)
      ).toString()
    )
      .multipliedBy(1 - slippage)
      .toFixed(0);
    expectOutAmount2 = valueToBigNumber(
      (
        await curvePool.getMinAmountOfSwap(
          1,
          0,
          expectOutAmount1,
          true,
          true,
          false,
          TUSD_FRAXBP_POOL_INFO
        )
      ).toString()
    );

    swapInfo.push({
      ...multiSwapPathInitData,
      routes: [zapAsset, FRAX_USDC_POOL, FRAX_USDC_LP, ...new Array(6).fill(ZERO_ADDRESS)],
      routeParams: [
        [1, 0, 7 /*2-coin-pool add_liquidity*/],
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
      ] as any,
      swapType: 4, // curve
      poolCount: 1,
      swapFrom: zapAsset,
      swapTo: FRAX_USDC_LP,
      inAmount,
      outAmount: expectOutAmount1,
    });
    swapInfo.push({
      ...multiSwapPathInitData,
      swapType: 1, //NO_SWAP: Join/Exit pool
      swapFrom: FRAX_USDC_LP,
      swapTo: TUSD_FRAXBP_LP,
      inAmount: expectOutAmount1,
      outAmount: expectOutAmount2.multipliedBy(1 - slippage).toFixed(0),
    });

    const expectedSlippage1 = await curvePool.getSlippageExpected(
      1,
      0,
      normalize(inAmount, 6),
      FRAX_USDC_POOL,
      SLIPPAGE_TYPE.DEPOSIT_BONUS,
      curveAPI
    );

    const expectedSlippage2 = await curvePool.getSlippageExpected(
      1,
      0,
      normalize(expectOutAmount1, TUSD_FRAXBP_POOL_INFO.coins[1].decimals),
      TUSD_FRAXBP_POOL,
      SLIPPAGE_TYPE.DEPOSIT_BONUS,
      curveAPI
    );

    expectedSlippage = Math.min(Number(expectedSlippage1), Number(expectedSlippage2));
  } else {
    const expectOutAmount1 = valueToBigNumber(
      (
        await curvePool.getMinAmountOfSwap(
          coinIndex,
          0,
          inAmount,
          false,
          false,
          true,
          TUSD_3CRV_POOL_INFO
        )
      ).toString()
    )
      .multipliedBy(1 - slippage)
      .toFixed(0);
    expectOutAmount2 = valueToBigNumber(
      (
        await curvePool.getMinAmountOfSwap(
          0,
          0,
          expectOutAmount1,
          true,
          true,
          false,
          TUSD_FRAXBP_POOL_INFO
        )
      ).toString()
    );

    swapInfo.push({
      ...multiSwapPathInitData,
      routes: [zapAsset, TUSD_3CRV_POOL, TUSD, ...new Array(6).fill(ZERO_ADDRESS)],
      routeParams: [
        [coinIndex, 0, 2 /*exchange_underlying*/],
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
      ] as any,
      swapType: 4, // curve
      poolCount: 1,
      swapFrom: zapAsset,
      swapTo: TUSD,
      inAmount,
      outAmount: expectOutAmount1,
    });
    swapInfo.push({
      ...multiSwapPathInitData,
      swapType: 1, //NO_SWAP: Join/Exit pool
      swapFrom: TUSD,
      swapTo: TUSD_FRAXBP_LP,
      inAmount: expectOutAmount1,
      outAmount: expectOutAmount2.multipliedBy(1 - slippage).toFixed(0),
    });

    // const expectedSlippage1 = await curvePool.getSlippageExpected(
    //   coinIndex,
    //   0,
    //   normalize(inAmount, TUSD_3CRV_POOL_INFO.coins[coinIndex].decimals),
    //   TUSD_3CRV_POOL,
    //   SLIPPAGE_TYPE.SWAP_PRICE_IMPACT,
    //   curveAPI
    // );

    const expectedSlippage2 = await curvePool.getSlippageExpected(
      0,
      0,
      normalize(expectOutAmount1, TUSD_FRAXBP_POOL_INFO.coins[0].decimals),
      TUSD_FRAXBP_POOL,
      SLIPPAGE_TYPE.DEPOSIT_BONUS,
      curveAPI
    );

    // expectedSlippage = Math.min(-Number(expectedSlippage1), Number(expectedSlippage2));
    expectedSlippage = expectedSlippage2;
  }

  return { swapInfo, outAmount: expectOutAmount2.toFixed(0), expectedSlippage };
};

const getBAL_BB_A3_USD_ZapSwapInfo = async (
  zapAsset: tEthereumAddress,
  inAmount: string,
  slippage: number,
  balancerPool: IBalancerPoolServiceInterface
): Promise<any> => {
  const expectOutAmount = (
    await balancerPool.getMinAmountOfSwap(
      inAmount,
      { asset: zapAsset, decimals: zapAsset.toLowerCase() === DAI.toLowerCase() ? 18 : 6 },
      { asset: BB_A3_USD, decimals: 18 },
      slippage
    )
  ).toString();
  const swapInfo: Array<any> = [];

  if (zapAsset.toLowerCase() === USDC.toLowerCase()) {
    swapInfo.push({
      ...multiSwapPathInitData,
      routes: [
        zapAsset,
        ZERO_ADDRESS,
        BB_A3_USDC,
        ZERO_ADDRESS,
        BB_A3_USD,
        ...new Array(4).fill(ZERO_ADDRESS),
      ],
      routeParams: [
        [BB_A3_USDC_POOLID, 0, 0],
        [BB_A3_USD_POOLID, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
      ] as any,
      swapType: 3, // balancer
      poolCount: 2,
      swapFrom: zapAsset,
      swapTo: BB_A3_USD,
      inAmount,
      outAmount: expectOutAmount,
    });
  } else if (zapAsset.toLowerCase() === USDT.toLowerCase()) {
    swapInfo.push({
      ...multiSwapPathInitData,
      routes: [
        zapAsset,
        ZERO_ADDRESS,
        BB_A3_USDT,
        ZERO_ADDRESS,
        BB_A3_USD,
        ...new Array(4).fill(ZERO_ADDRESS),
      ],
      routeParams: [
        [BB_A3_USDT_POOLID, 0, 0],
        [BB_A3_USD_POOLID, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
      ] as any,
      swapType: 3, // balancer
      poolCount: 2,
      swapFrom: zapAsset,
      swapTo: BB_A3_USD,
      inAmount,
      outAmount: expectOutAmount,
    });
  } else {
    swapInfo.push({
      ...multiSwapPathInitData,
      routes: [
        zapAsset,
        ZERO_ADDRESS,
        BB_A3_DAI,
        ZERO_ADDRESS,
        BB_A3_USD,
        ...new Array(4).fill(ZERO_ADDRESS),
      ],
      routeParams: [
        [BB_A3_DAI_POOLID, 0, 0],
        [BB_A3_USD_POOLID, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
      ] as any,
      swapType: 3, // balancer
      poolCount: 2,
      swapFrom: zapAsset,
      swapTo: BB_A3_USD,
      inAmount,
      outAmount: expectOutAmount,
    });
  }

  return { swapInfo, outAmount: valueToBigNumber(expectOutAmount).toFixed(0), slippage };
};

export const getZapSwapInfo = async (
  collateralSymbol: string,
  zapAsset: tEthereumAddress,
  amount: string,
  slippage: number,
  poolService: ICurvePoolServiceInterface | IBalancerPoolServiceInterface,
  curveAPI: any
): Promise<any> => {
  let info = { swapInfo: [], outAmount: '0', expectedSlippage: 0 };

  if (collateralSymbol === 'DAI_USDC_USDT_SUSD_LP') {
    info = await getDAI_USDC_USDT_SUSD_ZapSwapInfo(
      zapAsset,
      amount,
      slippage,
      poolService as ICurvePoolServiceInterface,
      curveAPI
    );
  }

  if (collateralSymbol === 'FRAX_3CRV_LP') {
    info = await getFRAX_3CRV_ZapSwapInfo(
      zapAsset,
      amount,
      slippage,
      poolService as ICurvePoolServiceInterface,
      curveAPI
    );
  }

  if (collateralSymbol === 'MIM_3CRV_LP') {
    info = await getMIM_3CRV_ZapSwapInfo(
      zapAsset,
      amount,
      slippage,
      poolService as ICurvePoolServiceInterface,
      curveAPI
    );
  }

  if (collateralSymbol === 'FRAX_USDC_LP') {
    info = await getFRAX_USDC_ZapSwapInfo(
      zapAsset,
      amount,
      slippage,
      poolService as ICurvePoolServiceInterface,
      curveAPI
    );
  }

  if (collateralSymbol === 'TUSD_FRAXBP_LP') {
    info = await getTUSD_FRAXBP_ZapSwapInfo(
      zapAsset,
      amount,
      slippage,
      poolService as ICurvePoolServiceInterface,
      curveAPI
    );
  }

  if (collateralSymbol === 'BAL_BB_A3_USD_LP') {
    info = await getBAL_BB_A3_USD_ZapSwapInfo(
      zapAsset,
      amount,
      slippage,
      poolService as IBalancerPoolServiceInterface
    );
  }

  return {
    swapInfo: new Array<GLSwapInfoPathType>(3)
      .fill(multiSwapPathInitData as GLSwapInfoPathType)
      .map((item, id) => (id < info.swapInfo.length ? info.swapInfo[id] : item)) as [
      GLSwapInfoPathType,
      GLSwapInfoPathType,
      GLSwapInfoPathType
    ],
    outAmount: info.outAmount,
    length: info.swapInfo.length,
    expectedSlippage: info.expectedSlippage,
  };
};
