import ScreenWrapper from '../../../components/Wrappers/ScreenWrapper';
import ProvideCollateralWrapper from '../components/ProvideCollateralWrapper';
import { StatsDataType } from '../../../components/ReserveOverviewPanel/StatsPanel/types';
import { ReserveOverviewDataType } from '../../../components/ReserveOverviewPanel/types';
import ReserveOverviewPanel from '../../../components/ReserveOverviewPanel';

import staticStyles from './style';
import routeParamValidationHOC, {
  ValidationWrapperComponentProps,
} from 'src/components/RouteParamsValidationWrapper';
import { CollateralReserveMarketData, normalize, valueToBigNumber } from '@sturdyfi/sturdy-js';
import { useMemo } from 'react';
import { useStaticPoolDataContext } from 'src/libs/pool-data-provider';

function Provide(props: ValidationWrapperComponentProps) {
  const { action, currencySymbol, poolReserve } = props;

  // const asset = getAssetInfo(currencySymbol);
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const reserve = poolReserve as CollateralReserveMarketData;

  const statsData: StatsDataType = useMemo(() => {
    const price = normalize(valueToBigNumber(reserve.priceInEth).div(marketRefPriceInUsd), 18);

    return {
      transactionType: action === 'deposit' || action === 'leverage' ? action : 'deposit',
      assetType: 'collateral',
      TVL: reserve.totalLiquidityInUSD,
      APY: reserve.depositAPY,
      maxLeverage: reserve.maxLeverage,
      maxAPY: reserve.maxAPY,
      maxLTV: reserve.LTV,
      liquidationThreshold: reserve.liquidationThreshold,
      liquidationBonus: reserve.liquidationBonus,
      price: price,
    };
  }, []);

  // const aboutData: AboutDataType = {
  //   title: asset.name,
  //   description:
  //     '3Crv token represents a share in the Curve DAI-USDC-USDT pool. Liquidity providers earn 0.04% fee on every trade made through the protocol as well as yield generated by iEarn protocol on stablecoins.',
  //   url: 'https://curve.fi/#/ethereum/pools/susd/deposit',
  //   twitter: 'https://curve.fi/#/ethereum/pools/susd/deposit',
  //   coingecko: 'https://curve.fi/#/ethereum/pools/susd/deposit',
  // };

  const reserveOverviewData: ReserveOverviewDataType = {
    assetSymbol: currencySymbol,
    assetType: 'collateral',
    statsData: statsData,
    chartData: reserve.ratesData,
    // aboutData: aboutData,
  };

  return (
    <ScreenWrapper>
      <div className="Provide">
        <ProvideCollateralWrapper {...props} className="Provide__left-content" />
        <ReserveOverviewPanel
          data={reserveOverviewData}
          className="Provide__right-content"
        ></ReserveOverviewPanel>
        <style jsx={true} global={true}>
          {staticStyles}
        </style>
      </div>
    </ScreenWrapper>
  );
}

export default routeParamValidationHOC({
  Component: Provide,
  withWalletBalance: true,
});
