import React from 'react';
import staticStyles from './style';
import { SocialIcon, SocialIcons, SocialType } from '@sturdyfi/sturdy-ui-kit';
import AuditButton from '../AuditButton';

const socialIcons: SocialIcon[] = [
  {
    url: 'https://twitter.com/SturdyFinance',
    type: SocialType.Twitter,
  },
  {
    url: 'https://discord.gg/tRVHp6Vx5N',
    type: SocialType.Discord,
  },
  {
    url: 'https://github.com/sturdyfi',
    type: SocialType.Github,
  },
  {
    url: 'https://docs.sturdy.finance',
    type: SocialType.Gitbook,
  },
];

export default function Footer() {
  return (
    <footer>
      <div className={'Footer'}>
        <div className="Footer__left">
          <AuditButton />
        </div>
        <div className="Footer__right">
          <SocialIcons
            icons={socialIcons}
            iconHeight={20}
            iconWidth={20}
            linkClassName="Footer__right__socialIcon"
          />
        </div>

        <style jsx={true}>{staticStyles}</style>
      </div>
    </footer>
  );
}
