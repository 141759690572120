import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .LendBorrowAssetTable {
    box-sizing: border-box;
    border: 1px solid var(--tabs-color);
    // backdrop-filter: blur(4px);
    box-shadow: var(--table-box-shadow);
    border-radius: 16px;
    padding: 24px;

    .TableHeaderWrapper {
      width: auto;

      &__title {
        margin: 7px 0 0 0;
        @include body_14;
        color: var(--text2-color);
      }

      .TableColumnWrapper {
        & > * {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        :first-child {
          padding-left: 24px;
        }
      }
    }

    .TableItemWrapper {
      @include body_14;
      color: var(--selected-color);
      width: auto;
      background: white;
      border: 1px solid var(--tabs-color);
      box-shadow: var(--box-shadow);
      // backdrop-filter: blur(4px);
      border-radius: 16px;
      margin-top: 24px;
      padding: 24px 16px 24px 16px;
      background-color: var(--dropdown-background-color);

      .TokenIcon__name {
        margin: 0;

        b {
          @include body_18_sb;
          color: var(--selected-color);
        }
      }

      .TableColumnWrapper {
        p {
          margin: 0;
        }

        .ZeroAPY {
          // @include body_18_sb;
          color: var(--health-factor-color);
        }

        .BorrowAPY {
          // @include body_18_sb;
          background: var(--linear-apy-color);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .OldAPY {
          color: var(--text2-color);
          text-decoration: line-through;
        }

        .IncentiveTokenIcon {
          margin-left: 3px;
        }

        .Amount__token-icon {
          .TokenIcon__image {
            margin-right: 2px;
          }
        }

        .DepositAPY_wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 4px;

          .Value__value,
          .ValuePercent__value {
            line-height: 14px;
          }

          .IncentiveTokens {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }

          .IncentiveToken {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 4px;

            &__amount {
              height: 100%;
              @include body_14;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;

              .SturdyIncentive {
                display: flex;
                flex-direction: row;
                align-items: center;

                span {
                  margin: 0 4px;
                }
              }
            }

            .TokenIcon {
              margin-top: -2px;
              &__image {
                margin-right: 0px;
              }
            }
          }
        }
      }

      .ButtonColumn {
        flex-direction: row;
        justify-content: center;
        gap: 15px;

        & > * {
          flex: 1;
        }
      }
    }

    @include respond-to(sm) {
      & {
        border: none;
        padding: 0px;
      }
    }
  }
`;

export default staticStyles;
