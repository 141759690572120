import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { FormattedNumber } from 'react-intl';
import SliderWrapper from '../Wrappers/SliderWrapper';
import TextWithInfoWrapper from '../Wrappers/TextWithInfoWrapper';
import cancelIcon from 'src/images/cancel.svg';

import staticStyles from './style';

interface LeveragePanelProps {
  currencySymbol: string;
  values: number[];
  className?: string;
  onChangeValue?: (value: number) => void;
}

export default function LeveragePanel({
  currencySymbol,
  values,
  className,
  onChangeValue,
}: LeveragePanelProps) {
  const [leverageValue, setLeverageValue] = useState(values[0]);
  const [sliderValue, setSliderValue] = useState(0);

  const handleSliderClick = (value: number) => {
    setSliderValue(value);

    const realValue = Number(value / 100).toFixed(1);
    setLeverageValue(Number(realValue) + values[0]);
    onChangeValue && onChangeValue(Number((Number(realValue) + values[0]).toFixed(2)));
  };

  const maxValue = useMemo(() => {
    return (values[1] - values[0]) * 100;
  }, []);

  return (
    <div className={classNames('LeveragePanel', className)}>
      <div className={'LeveragePanel__header'}>
        <TextWithInfoWrapper
          text={'Leverage'}
          infoPosition={'right'}
          tooltipName={
            currencySymbol === 'BAL_WSTETH_WETH_LP'
              ? 'howAuraLeverageWorking'
              : 'howCurveLeverageWorking'
          }
        />
        <div className={'LeveragePanel__header__value'} onClick={() => handleSliderClick(0)}>
          <FormattedNumber value={Number(leverageValue)} maximumFractionDigits={2} />
          <span>x</span>
          <img src={cancelIcon} alt="" />
        </div>
      </div>
      <div className={'LeveragePanel__content'}>
        <SliderWrapper
          max={maxValue}
          marks={1}
          value={sliderValue}
          onClick={(value) => handleSliderClick(value)}
        />
      </div>

      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
