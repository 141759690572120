import classNames from 'classnames';
import staticStyles from './style';

import { announcementBanner } from 'src/images';
import Link from '../basic/Link';

export default function AnnouncementBar() {
  return (
    <Link
      to="https://sturdyfinance.medium.com/introducing-sturdy-v2-4a0135aca675"
      absolute={true}
      inNewWindow={true}
    >
      <div className={classNames('AnnouncementBar')}>
        <div className="AnnouncementBar__graphic">
          <img src={announcementBanner} alt="Sturdy" />
        </div>
        <div className="AnnouncementBar__content">
          <div className="AnnouncementBar__content-caption">
            <span>news</span>
          </div>
          <div className="AnnouncementBar__content-desc">
            <p>
              {`You're visiting Sturdy V1, which has been deprecated in anticipation of the upcoming
              release of Sturdy V2. Learn more about Sturdy V2 ->`}
            </p>
          </div>
        </div>
        <style jsx={true}>{staticStyles}</style>
      </div>
    </Link>
  );
}
