import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Dashboard {
    &__section {
      margin-bottom: 40px;

      & > * {
        margin-bottom: 24px;
        width: 100%;
      }
      &-summary {
        display: flex;
        flex-direction: row;
        @include respond-to(sm) {
          flex-direction: column;
          gap: 8px;
        }
        & > * {
          margin-right: 18px;
        }
      }
    }

    &__cards {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      padding: 0px;
      gap: 24px;

      @include respond-to(md) {
        flex-direction: column;
        align-items: flex-start;
        & > * {
          width: 100%;
        }
      }

      @include respond-to(sm) {
        gap: 16px;
      }

      &-primary {
        flex: 2;
      }

      &-secondary {
        flex: 3;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 24px;

        @include respond-to(md) {
          flex-direction: row;
        }
        @include respond-to(sm) {
          flex-direction: column;
          gap: 16px;
        }
      }
    }

    &__positions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      gap: 24px;

      @include respond-to(sm) {
        align-items: center;
        flex-direction: column;
        .PanelWrapper {
          border: 1px solid var(--tabs-color);
          background: var(--marketcad-background-color);
          box-shadow: var(--table-box-shadow);
        }

        .slide {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .carousel .slider-wrapper {
          margin-bottom: 40px;
        }
      }

      &-main {
        flex: 1;
      }

      &-secondary {
        flex: 2;
        display: grid;
        grid-template-columns: auto auto;
        gap: 24px;

        @include respond-to(sm) {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
      }

      &-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: 24px;
        flex: 1;

        .PanelWrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }
      }
    }
  }
`;

export default staticStyles;
