import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import css from 'styled-jsx/css';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import { useMenuContext } from './libs/menu';
import useGoogleAnalytics from './libs/hooks/use-ga';

import { Markets, Dashboard, Lend, Borrow, Provide, Withdraw, Reward } from './modules';
import ScreensWrapper from './components/Wrappers/ScreensWrapper';

const staticStyles = css.global`
  .App {
    display: flex;
    flex-direction: column;
    flex: auto;
    overflow: hidden;
    height: 1px;
    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
      position: relative;
    }
  }
`;

const App: React.FC = () => {
  useGoogleAnalytics();
  const { md } = useThemeContext();
  const { openMobileMenu } = useMenuContext();

  const handlers = useSwipeable({
    // onSwipedLeft: () => (md ? openMobileMenu() : null),
  });

  return (
    <div className="App">
      <div {...handlers} className="App__content">
        <ScreensWrapper>
          <Routes>
            <Route path="markets" element={<Markets />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="lend">
              <Route index element={<Lend />} />
              <Route path=":action" element={<Lend />} />
              <Route path=":action/:underlyingAsset" element={<Lend />} />
            </Route>
            <Route path="borrow">
              <Route index element={<Borrow />} />
              <Route path=":action" element={<Borrow />} />
              <Route path=":action/:underlyingAsset" element={<Borrow />} />
            </Route>
            <Route path="provide">
              <Route index element={<Provide />} />
              <Route path=":action" element={<Provide />} />
              <Route path=":action/:underlyingAsset" element={<Provide />} />
            </Route>
            <Route path="withdraw">
              <Route index element={<Withdraw />} />
              <Route path=":action" element={<Withdraw />} />
              <Route path=":action/:underlyingAsset" element={<Withdraw />} />
            </Route>
            <Route path="/rewards/:id" element={<Reward />} />
            <Route path="*" element={<Navigate to="/markets" replace />} />
          </Routes>
        </ScreensWrapper>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
};

export default App;
