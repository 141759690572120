import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .OverviewCard {
    flex: 1;
    width: 100%;
    padding: 24px;

    .PanelWrapper__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;

      & > * {
        width: 100%;
      }
    }

    h3 {
      @include body_18;
      color: var(--text2-color);

      @include respond-to(sm) {
        @include body_16;
      }
    }
    &__info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0px;
      @include respond-to(md) {
        display: grid;
        grid-template-columns: auto auto;
        row-gap: 24px;
      }
    }

    article {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
    }

    article h4 {
      @include body_16;
      color: var(--text2-color);

      @include respond-to(sm) {
        @include body_12;
      }
    }
    article .OverviewCard__value {
      @include body_24;
      color: var(--selected-color);

      @include respond-to(sm) {
        @include body_18;
      }

      @include respond-to(xs) {
        @include body_14;
      }
    }
  }
  @include respond-to(sm) {
    .OverviewCard.PanelWrapper {
      border: 1px solid var(--tabs-color);
      background: var(--marketcad-background-color);
      box-shadow: var(--table-box-shadow);
    }
  }
`;

export default staticStyles;
