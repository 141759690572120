import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { ExtraAssetPrice, Network } from '@sturdyfi/sturdy-js';

import { getProvider } from '../../../helpers/markets/markets-data';
import { REWARD_TYPE } from '../../../helpers/markets/types';
import {
  UiIncentiveDataProvider,
  UserReserveIncentiveDataHumanizedResponse,
  Denominations,
} from '@sturdyfi/contract-helpers';
import { useProtocolDataContext } from '../../protocol-data-provider';

// interval in which the rpc data is refreshed
const POOLING_INTERVAL = 30 * 1000;
// decreased interval in case there was a network error for faster recovery
const RECOVER_INTERVAL = 10 * 1000;

export interface ReserveRewardData {
  underlyingAsset: string;
  rewardData: ReserveTokenRewards[];
}

export interface UserReserveRewardData {
  // rewardTokenAddress: string;
  // rewardUserDatas: UserTokenRewards[];
  // totalUnclaimedRewards: number;
  // id: string;
  underlyingAsset: string;
  rewardUserDatas: UserTokenRewards[];
}

interface ReserveTokenRewards {
  emissionPerSecond: string;
  incentivesLastUpdateTimestamp: number;
  emissionEndTimestamp: number;
  incentiveRatio: number;
  lastAvailableRewards: string;
  tokenIncentivesIndex: string;
  tokenAddress: string;
  rewardTokenAddress: string;
  rewardTokenSymbol: string;
  distributorAddress: string;
  rewardTokenDecimals: number;
  rewardType?: REWARD_TYPE;
  id: string;
}

interface UserTokenRewards {
  tokenIncentivesUserIndex: string;
  userUnclaimedRewards: string;
  rewardsBalance: string;
  tokenAddress: string;
  rewardTokenAddress: string;
  rewardTokenSymbol: string;
  distributorAddress: string;
  rewardTokenDecimals: number;
  rewardType?: REWARD_TYPE;
}

export interface IncentiveDataResponse {
  loading: boolean;
  error: boolean;
  data: {
    reserveRewardData?: ReserveRewardData[];
    userRewardData?: UserReserveRewardData[];
  };
  refresh: () => Promise<void>;
}

// Fetch reserve and user incentive data from UiIncentiveDataProvider
export function useIncentivesData(
  lendingPoolAddressProvider: string,
  network: Network,
  incentiveDataProviderAddress: string,
  skip: boolean,
  userAddress?: string
): IncentiveDataResponse {
  const { networkConfig, chainId } = useProtocolDataContext();
  const currentAccount: string | undefined = userAddress ? userAddress.toLowerCase() : undefined;
  const [loadingReserveIncentives, setLoadingReserveIncentives] = useState<boolean>(true);
  const [errorReserveIncentives, setErrorReserveIncentives] = useState<boolean>(false);
  const [loadingUserIncentives, setLoadingUserIncentives] = useState<boolean>(true);
  const [errorUserIncentives, setErrorUserIncentives] = useState<boolean>(false);
  const [reserveRewardData, setReserveRewardData] = useState<ReserveRewardData[] | undefined>(
    undefined
  );
  const [userRewardData, setUserRewardData] = useState<UserReserveRewardData[] | undefined>(
    undefined
  );

  // Fetch reserve incentive data and user incentive data only if currentAccount is set
  const fetchData = async (
    currentAccount: string | undefined,
    lendingPoolAddressProvider: string,
    incentiveDataProviderAddress: string
  ) => {
    fetchReserveIncentiveData(lendingPoolAddressProvider, incentiveDataProviderAddress);
    if (currentAccount && currentAccount !== ethers.constants.AddressZero) {
      fetchUserIncentiveData(
        currentAccount,
        lendingPoolAddressProvider,
        incentiveDataProviderAddress
      );
    } else {
      setLoadingUserIncentives(false);
    }
  };

  // Fetch and format reserve incentive data from UiIncentiveDataProvider contract
  const fetchReserveIncentiveData = async (
    lendingPoolAddressProvider: string,
    incentiveDataProviderAddress: string
  ) => {
    setErrorReserveIncentives(false);
    setLoadingReserveIncentives(false);

    const provider = getProvider(network);
    const incentiveDataProviderContract = new UiIncentiveDataProvider({
      incentiveDataProviderAddress,
      provider,
      chainId,
    });

    try {
      const rawData = await incentiveDataProviderContract.getIncentivesDataWithPrice({
        lendingPoolAddressProvider,
        quote: networkConfig.usdMarket ? Denominations.usd : Denominations.eth,
        chainlinkFeedsRegistry: networkConfig.addresses.chainlinkFeedRegistry,
      });

      const rawReserveRewardData: ReserveRewardData[] = [];
      rawData.forEach((item) => {
        const rewardData: ReserveTokenRewards[] = [];
        // stable rewards
        item.stableRewardDatas.forEach((reward) => {
          const stableReserveReward = networkConfig?.collateralRewardAddresses?.find(
            (rewardInfo) =>
              rewardInfo.token.toLowerCase() === reward.rewardTokenAddress.toLowerCase()
          );

          if (stableReserveReward) {
            const rewardTokenSymbol = stableReserveReward.symbol;
            rewardData.push({
              ...reward,
              rewardTokenSymbol,
              incentiveRatio: 0,
              lastAvailableRewards: '0',
              rewardType: REWARD_TYPE.STABLE,
              id: `${reward.tokenAddress}-${reward.distributorAddress}`.toLowerCase(),
            });
          }
        });

        // variable reward
        const variableReward = networkConfig?.collateralRewardAddresses?.find(
          (rewardInfo) =>
            rewardInfo.token.toLowerCase() ===
            item.variableRewardData.rewardTokenAddress.toLowerCase()
        );
        if (variableReward) {
          const rewardTokenSymbol = variableReward.symbol;
          rewardData.push({
            ...item.variableRewardData,
            rewardTokenSymbol,
            emissionPerSecond: '0',
            incentivesLastUpdateTimestamp: 0,
            emissionEndTimestamp: 0,
            rewardType: REWARD_TYPE.VARIABLE,
            id: `${item.variableRewardData.tokenAddress}-${item.variableRewardData.distributorAddress}`.toLowerCase(),
          });
        }

        rawReserveRewardData.push({
          underlyingAsset: item.underlyingAsset,
          rewardData,
        });
      });
      setReserveRewardData(rawReserveRewardData);
      setErrorReserveIncentives(false);
    } catch (e) {
      console.log('e', e);
      setErrorReserveIncentives(e.message);
    }
    setLoadingReserveIncentives(false);
  };

  // Fetch and format user incentive data from UiIncentiveDataProvider
  const fetchUserIncentiveData = async (
    currentAccount: string,
    lendingPoolAddressProvider: string,
    incentiveDataProviderAddress: string
  ) => {
    setUserRewardData([]);
    setErrorUserIncentives(false);
    setLoadingUserIncentives(false);

    const provider = getProvider(network);
    const incentiveDataProviderContract = new UiIncentiveDataProvider({
      incentiveDataProviderAddress,
      provider,
      chainId,
    });

    try {
      const rawData: UserReserveIncentiveDataHumanizedResponse[] =
        await incentiveDataProviderContract.getUserReservesIncentivesDataHumanized(
          currentAccount,
          lendingPoolAddressProvider
        );

      const rawUserRewardData: UserReserveRewardData[] = [];
      rawData.forEach((item) => {
        const userRewardData: UserTokenRewards[] = [];

        // main rewards ==> $STRDY
        if (item.aTokenIncentivesUserData.rewardTokenAddress !== '') {
          const strdyUserReward = networkConfig?.collateralRewardAddresses?.find(
            (rewardInfo) =>
              rewardInfo.token.toLowerCase() ===
              item.aTokenIncentivesUserData.rewardTokenAddress.toLowerCase()
          );

          if (strdyUserReward) {
            userRewardData.push({
              ...item.aTokenIncentivesUserData,
              rewardTokenSymbol: strdyUserReward.symbol,
              rewardType: REWARD_TYPE.MAIN,
              distributorAddress: item.aTokenIncentivesUserData.incentiveControllerAddress,
            });
          }
        }

        // stable rewards
        item.stableRewardUserDatas.forEach((reward) => {
          const stableUserReward = networkConfig?.collateralRewardAddresses?.find(
            (rewardInfo) =>
              rewardInfo.token.toLowerCase() === reward.rewardTokenAddress.toLowerCase()
          );
          if (stableUserReward) {
            const rewardTokenSymbol = stableUserReward.symbol;

            userRewardData.push({
              ...reward,
              rewardTokenSymbol,
              rewardType: REWARD_TYPE.STABLE,
            });
          }
        });
        // variable reward
        const variableUserReward = networkConfig?.collateralRewardAddresses?.find(
          (rewardInfo) =>
            rewardInfo.token.toLowerCase() ===
            item.variableRewardUserData.rewardTokenAddress.toLowerCase()
        );
        if (variableUserReward) {
          const rewardTokenSymbol = variableUserReward.symbol;
          userRewardData.push({
            ...item.variableRewardUserData,
            rewardTokenSymbol,
            rewardType: REWARD_TYPE.VARIABLE,
          });
        }

        rawUserRewardData.push({
          underlyingAsset: item.underlyingAsset,
          rewardUserDatas: userRewardData,
        });
      });

      setUserRewardData(rawUserRewardData);
      setErrorUserIncentives(false);
    } catch (e) {
      console.log('e', e);
      setErrorUserIncentives(e.message);
    }
    setLoadingUserIncentives(false);
  };

  useEffect(() => {
    setLoadingReserveIncentives(true);
    setLoadingUserIncentives(true);

    if (!skip) {
      fetchData(currentAccount, lendingPoolAddressProvider, incentiveDataProviderAddress);
      const intervalID = setInterval(
        () => fetchData(currentAccount, lendingPoolAddressProvider, incentiveDataProviderAddress),
        errorReserveIncentives || errorUserIncentives ? RECOVER_INTERVAL : POOLING_INTERVAL
      );
      return () => clearInterval(intervalID);
    } else {
      setLoadingReserveIncentives(false);
      setLoadingUserIncentives(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, lendingPoolAddressProvider, skip]);

  const loading = loadingReserveIncentives || loadingUserIncentives;
  const error = errorReserveIncentives || errorUserIncentives;
  return {
    loading,
    error,
    data: { reserveRewardData, userRewardData },
    refresh: () =>
      fetchData(currentAccount, lendingPoolAddressProvider, incentiveDataProviderAddress),
  };
}
