import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ClaimModal {
    &.ReactModal__Content {
      max-width: 600px !important;
      width: 600px;
      @include respond-to(xl) {
        max-width: 100% !important;
      }
    }

    .BasicModal__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      color: var(--text2-color);

      a {
        color: #5677ed;
      }

      & > p {
        @include body_16_regular;
        text-align: left;
      }

      & > div {
        width: 100%;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        gap: 16px;
        isolation: isolate;

        border: 1px solid #e1e4e6;
        border-radius: 8px;
      }

      & > div > * {
        width: 100%;
      }

      & > div h3 {
        @include body_18;
        text-align: left;
        text-transform: capitalize;
        color: var(--selected-color);
      }

      .Wallet > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .IncentiveToken {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;

        & > div {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 0px;
          gap: 8px;
        }

        & > p {
          text-align: left;
          @include body_12;
          button {
            color: #5677ed;
          }
        }

        &__icon {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 4px;

          &-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;

            & > div:first-child {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 4px;
              color: var(--selected-color) !important;
              & > span:fist-child {
                @include body_16;
              }
              & > span:nth-child(2) {
                @include body_16_sb;
              }
            }

            & > div:nth-child(2) {
              .TokenIcon__dollar,
              .Value__value {
                @include body_12;
                color: var(--text2-color);
              }
            }
          }
        }
        button.ClaimButton {
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          padding: 4px 8px;
          border-radius: 10px;
          @include body_14;
          background: var(--button1-color);
          box-shadow: 0px 2px 4px rgba(57, 96, 236, 0.18);
          color: white;
          // &:hover {
          //   background: var(--button1-hover-color);
          // }

          &:disabled {
            opacity: 0.7;
          }
        }
      }
    }
  }
`;

export default staticStyles;
