import classNames from 'classnames';
import { ChangeEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import BasicModal from '../basic/BasicModal';
import DefaultButton from '../basic/DefaultButton';
import messages from './messages';
import staticStyles from './style';

interface PriceSlippageModalProps {
  isVisible: boolean;
  onBackdropPress: () => void;
  slippage: number;
  maxSlippageValue: number;
  onSubmitSlippageValue?: (value: number) => void;
  className?: string;
}

const SLIPPAGE_VALUES = ['0.001', '0.005', 'custom'] as const;

type SLIPPAGE_VALUE_TYPE = typeof SLIPPAGE_VALUES[number];

export default function PriceSlippageModal({
  isVisible,
  onBackdropPress,
  slippage,
  maxSlippageValue,
  onSubmitSlippageValue,
  className,
}: PriceSlippageModalProps) {
  const intl = useIntl();
  const [radioValue, setRadioValue] = useState<SLIPPAGE_VALUE_TYPE>(SLIPPAGE_VALUES[0]);
  const [customValue, setCustomValue] = useState('');
  const [err, setErr] = useState('');

  useEffect(() => {
    if (slippage.toString() === SLIPPAGE_VALUES[0]) {
      setRadioValue(SLIPPAGE_VALUES[0]);
    } else if (slippage.toString() === SLIPPAGE_VALUES[1]) {
      setRadioValue(SLIPPAGE_VALUES[1]);
    } else {
      setRadioValue(SLIPPAGE_VALUES[2]);
      setCustomValue((slippage * 100).toString());
    }
  }, []);

  const formattedValue = radioValue !== 'custom' ? Number(radioValue) : Number(customValue) / 100;

  const handleRadioValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as SLIPPAGE_VALUE_TYPE;
    setRadioValue(value);
    setCustomValue('');
    setErr('');
  };

  const handleCustomValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCustomValue(value);
    setErr('');
  };

  const onSave = () => {
    if (formattedValue === 0) {
      setErr('Please input slippage value');
      return;
    }
    if (formattedValue > maxSlippageValue) {
      setErr(`Please input slippage value below than ${maxSlippageValue * 100}%`);
      return;
    }

    onSubmitSlippageValue?.(formattedValue);
    onBackdropPress();
  };

  return (
    <BasicModal
      isVisible={isVisible}
      onBackdropPress={() => {
        // setErr('');
        // onBackdropPress();
      }}
      title={intl.formatMessage(messages.priceSlippage)}
      // withCloseButton={true}
      className={classNames('PriceSlippageModal', { className })}
    >
      <div className="Container">
        <p>{intl.formatMessage(messages.priceSlippage)}</p>
        <div className="Options">
          {SLIPPAGE_VALUES.map((value) => (
            <div
              key={value}
              className={classNames('Option', { Option__activated: radioValue === value })}
              onClick={() => value === 'custom' && setRadioValue(value)}
            >
              {value !== 'custom' ? (
                <>
                  <label>
                    <input
                      type="radio"
                      name="slippage"
                      value={value}
                      checked={radioValue === value}
                      onChange={handleRadioValueChange}
                    />
                    {Number(value) * 100}%
                  </label>
                </>
              ) : (
                <>
                  <input
                    type="radio"
                    name="slippage"
                    value="custom"
                    checked={radioValue === 'custom'}
                    onChange={handleRadioValueChange}
                  />
                  <div className="CustomValue_wrapper">
                    <input
                      className="CustomValue"
                      type="number"
                      disabled={radioValue !== 'custom'}
                      placeholder="Custom"
                      onChange={handleCustomValueChange}
                      value={customValue}
                    />
                    {customValue !== '' && <span>%</span>}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
        {err && <p className="error">{err}</p>}
      </div>
      {/* <button onClick={onSave} disabled={customValue === undefined}>
        {intl.formatMessage(messages.save)}
      </button> */}
      <DefaultButton
        onClick={onSave}
        disabled={formattedValue === 0}
        title={intl.formatMessage(messages.save)}
        type="primary"
        color="blue"
        className="Slippage_Submit_Button"
      />
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </BasicModal>
  );
}
