import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast, Id } from 'react-toastify';
import classNames from 'classnames';
import { EthereumTransactionTypeExtended, GasResponse, Network } from '@sturdyfi/sturdy-js';
import { useWeb3React } from '@web3-react/core';
import { providers } from 'ethers';
import * as Sentry from '@sentry/react';

import { getDefaultNetworkName, getSupportedNetworks } from '../../config';
import {
  mapChainIdToName,
  mapNameToChainID,
  useUserWalletDataContext,
} from '../../libs/web3-data-provider';
import { useProtocolDataContext } from '../../libs/protocol-data-provider';
import {
  EthTransactionData,
  sendEthTransaction,
  TxName,
  TxStatusType,
} from '../../helpers/send-ethereum-tx';
import Preloader from '../basic/Preloader';

import NetworkMismatch from './NetworkMismatch';

import messages from './messages';
import staticStyles from './style';
import TxButton from './TxButton';
import TxToastView from './TxToastView';
import TxError from './TxError';
import DefaultButton from '../basic/DefaultButton';

export interface TxConfirmationViewProps {
  txNetwork: Network;
  mainTxName: TxName;
  currencySymbol: string;
  mainTxType?: string;
  getTransactionsData: () => Promise<EthereumTransactionTypeExtended[]>;
  onMainTxConfirmed?: () => void | Promise<void>;
  onActiveTxChanged?: (gas: GasResponse) => void;
  onLowSlippageDetected?: () => void | Promise<void>;
  blockingError?: string;
  className?: string;
  allowedNetworks?: Network[];
  hideActionWrapper?: boolean;
  debtCurrencySymbol?: string;
}

export default function TxConfirmationView({
  txNetwork,
  mainTxType,
  currencySymbol,
  mainTxName,
  getTransactionsData,
  onMainTxConfirmed,
  onActiveTxChanged,
  onLowSlippageDetected,
  blockingError,
  className,
  allowedNetworks: _allowedNetworks,
  hideActionWrapper,
  debtCurrencySymbol,
}: TxConfirmationViewProps) {
  const intl = useIntl();
  const { library: provider, chainId } = useWeb3React<providers.Web3Provider>();
  const { disconnectWallet, currentProviderName } = useUserWalletDataContext();
  const [loadingTxData, setLoadingTxData] = useState(true);
  const { network: currentMarketNetwork } = useProtocolDataContext();

  const [txData, setTxData] = useState([] as EthTransactionData[]);
  const [activeTx, setActiveTx] = useState({} as EthTransactionData);
  const [failed, setFailed] = useState('');
  const toastId = useRef(0 as Id);

  /**
   * For some actions like e.g. stake/gov/migration we only allow certain networks (fork, kovan, mainnet).
   * We allow to browse these actions even while the user is on a different chain/network, therefore we can have multiple cases of mismatch,
   * 1. walletNetwork is not allowed for this action
   * 2. all networks or walletNetwork is allowed, but there the browsed market does not walletNetwork the walletNetwork
   */
  const allowedNetworks = _allowedNetworks?.filter((network) =>
    getSupportedNetworks().includes(network)
  );
  // current marketNetwork is supported if the action is either not restricted to a network or the network is in the allow list
  const currentMarketNetworkIsSupported =
    !allowedNetworks || allowedNetworks?.find((network) => network === currentMarketNetwork);

  let networkMismatch = false;
  let neededNetworkName = getDefaultNetworkName();
  let unMounted = false;

  if (currentMarketNetworkIsSupported && mapNameToChainID(currentMarketNetwork) !== chainId) {
    networkMismatch = true;
    neededNetworkName = currentMarketNetwork;
  }

  if (!currentMarketNetworkIsSupported && mapNameToChainID(txNetwork) !== chainId) {
    networkMismatch = true;
    neededNetworkName = txNetwork;
  }

  const handleGetTxData = async () => {
    setLoadingTxData(true);
    try {
      const txs = await getTransactionsData();
      if (unMounted) return;

      const formattedTxs: EthTransactionData[] = txs.map((tx, index) => {
        const name =
          tx.txType === 'ERC20_APPROVAL'
            ? intl.formatMessage(messages.approve)
            : [
                'DLP_ACTION',
                'GOVERNANCE_ACTION',
                'STAKE_ACTION',
                'GOV_DELEGATION_ACTION',
                'REWARD_ACTION',
                mainTxType,
              ].includes(tx.txType)
            ? mainTxName
            : tx.txType;
        return {
          txId: index,
          txType: tx.txType,
          unsignedData: tx.tx,
          gas: tx.gas,
          name,
        };
      });

      if (formattedTxs.length > 0) {
        setActiveTx(formattedTxs[0]);
      }
      setTxData(formattedTxs);
      setLoadingTxData(false);
    } catch (e) {
      console.log('Error on txs loading', e);
      setLoadingTxData(false);
    }
  };

  const handleTxExecuted = (txHash: string) => {
    toastId.current = toast(<TxToastView type={'pending'} txHash={txHash} />, { autoClose: false });
  };

  const handleTxConfirmed = (txHash: string, txId?: number) => {
    if (toastId) {
      toast.update(toastId.current, {
        render() {
          return <TxToastView type={'success'} txHash={txHash} />;
        },
        autoClose: 5000,
      });
    }
    if (txId === txData.length - 1) {
      onMainTxConfirmed && onMainTxConfirmed();
    } else {
      const remainedTxs = txData.filter(
        (tx) => tx.txStatus !== TxStatusType.confirmed && tx.txId !== txId
      );
      setActiveTx(remainedTxs[0]);
    }
  };

  const handleTxFailed = (txHash: string | undefined, error: string) => {
    if (toastId) {
      toast.update(toastId.current, {
        render() {
          return <TxToastView type={'error'} txHash={txHash} />;
        },
        autoClose: 5000,
      });
    }
    if (mainTxName === 'leverage' || mainTxName === 'deleverage') {
      if (error.includes('execution reverted: 116') || error.includes('execution reverted: 115')) {
        onLowSlippageDetected && onLowSlippageDetected();
      }
    }
    setFailed(error);
    Sentry.captureMessage(error);
  };

  const txError = txData.map((tx) => JSON.stringify(tx.error)).join(',');

  useEffect(() => {
    const isDisconnected = txData.filter((tx) => tx?.error?.includes('disconnected')).length > 0;
    if (isDisconnected && currentProviderName?.includes('ledger')) {
      setTimeout(() => disconnectWallet(new Error('Ledger device is disconnected')), 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txError]);

  useEffect(() => {
    if (!networkMismatch) {
      console.log('tx loading started');
      unMounted = false;
      handleGetTxData();
    } else {
      setLoadingTxData(false);
    }

    return () => {
      unMounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTransactionsData, networkMismatch]);

  useEffect(() => {
    if (activeTx) {
      onActiveTxChanged && onActiveTxChanged(activeTx.gas);
      setFailed('');
    }
  }, [activeTx]);

  if (loadingTxData && hideActionWrapper !== true) {
    // return <Preloader withText={true} />;
    return <DefaultButton title={'Loading...'} type="primary" color="blue" disabled={true} />;
  }

  const activeTxData = activeTx?.unsignedData
    ? (activeTx as EthTransactionData & {
        unsignedData: EthTransactionData;
      })
    : undefined;

  return (
    <div className={classNames('TxConfirmationView', className)}>
      <div
        className={classNames('TxConfirmationView__actions-inner', {
          TxConfirmationView__actionsInner_hidden: hideActionWrapper,
        })}
      >
        {networkMismatch && currentProviderName ? (
          <NetworkMismatch
            neededNetworkName={neededNetworkName}
            currentNetworkName={mapChainIdToName(chainId as number) as Network}
            currentProviderName={currentProviderName}
          />
        ) : (
          <div>
            {!blockingError && activeTxData && activeTxData.txStatus !== TxStatusType.confirmed ? (
              <TxButton
                mainTxName={mainTxName}
                currencySymbol={currencySymbol}
                debtCurrencySymbol={debtCurrencySymbol}
                isApproveForDebtTx={activeTxData.txType === 'DEBT_APPROVAL'}
                isApproveTx={activeTxData.txType === 'ERC20_APPROVAL'}
                onSubmitTransaction={() => {
                  setFailed('');
                  return sendEthTransaction(
                    activeTx?.txId || 0,
                    activeTxData.unsignedData,
                    provider,
                    setTxData,
                    null,
                    {
                      onExecution: handleTxExecuted,
                      onConfirmation: handleTxConfirmed,
                      onFail: handleTxFailed,
                    }
                  );
                }}
                txStatus={activeTxData.txStatus}
                loading={activeTxData.loading}
              />
            ) : (
              <span>{blockingError}</span>
            )}
          </div>
        )}
      </div>
      {failed && <TxError failed={failed} className="TxConfirmationView__error" />}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
