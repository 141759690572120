import { useIntl } from 'react-intl';
import { TooltipName } from 'src/components/Tooltip';
import SkeletonWrapper from 'src/components/Wrappers/SkeletonWrapper';

import BasicTableWrapper from 'src/components/Wrappers/Table/BasicTableWrapper';
import TableColumnWrapper from 'src/components/Wrappers/Table/TableColumnWrapper';
import TableHeaderWrapper from 'src/components/Wrappers/Table/TableHeaderWrapper';
import TextWithInfoWrapper from 'src/components/Wrappers/TextWithInfoWrapper';
import BorrowCurrencyItem from './BorrowCurrencyItem';
import messages from './messages';

import staticStyles from './style';
import { BorrowCurrencyProps } from './types';

interface BorrowCurrencyTableProps {
  listData: BorrowCurrencyProps[];
  isLoading: boolean;
}

export default function BorrowCurrencyTable({ listData, isLoading }: BorrowCurrencyTableProps) {
  const intl = useIntl();
  const columns: {
    title: string;
    minWidth?: number;
    tooltipName?: TooltipName;
  }[] = [
    {
      title: intl.formatMessage(messages.asset),
      minWidth: 315,
    },
    {
      title: intl.formatMessage(messages.debt),
      minWidth: 300,
    },
    {
      title: intl.formatMessage(messages.borrowAPY),
      tooltipName: 'borrowAPYForAsset',
    },
  ];

  return (
    <BasicTableWrapper
      className={'BorrowCurrencyTable'}
      headerColumns={
        <TableHeaderWrapper>
          {columns.map((column, index) => (
            <TableColumnWrapper key={index} minWidth={column.minWidth}>
              {column.tooltipName ? (
                <TextWithInfoWrapper
                  className="TableHeaderWrapper__title"
                  text={column.title}
                  tooltipName={column.tooltipName}
                />
              ) : (
                <p className="TableHeaderWrapper__title">{column.title}</p>
              )}
            </TableColumnWrapper>
          ))}
        </TableHeaderWrapper>
      }
    >
      {isLoading
        ? Array(3)
            .fill({})
            .map((item, index) => <BorrowCurrencyItem {...item} key={index} isLoading={isLoading} />)
        : listData.map((item) => <BorrowCurrencyItem {...item} key={item.symbol} />)}

      <style jsx={true}>{staticStyles}</style>
    </BasicTableWrapper>
  );
}
