import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .SummaryCard {
    width: 100% !important;
    height: 100% !important;
    padding: 24px;

    @include respond-to(sm) {
      padding: 16px;
    }

    .PanelWrapper__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__info {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 38px;

      article {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
      }

      article h4 {
        @include body_16_regular;
        color: var(--text2-color);

        @include respond-to(sm) {
          @include body_12;
        }
      }

      article .SummaryCard__amount-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 8px;
      }

      article .usdValue {
        @include body_20;
        color: var(--selected-color);
        display: inline-block;
        @include respond-to(sm) {
          @include body_14;
        }
      }

      article .ethValue {
        @include body_14;
        color: var(--text2-color);
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        @include respond-to(sm) {
          @include body_12;
        }
      }
    }

    &__chart {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // padding: 0px;

      width: 300px;
      height: 100%;
    }
  }

  @include respond-to(sm) {
    .SummaryCard.PanelWrapper {
      border: 1px solid var(--tabs-color);
      background: var(--marketcad-background-color);
      box-shadow: var(--table-box-shadow);
    }
  }
`;

export default staticStyles;
