import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .DepositAssetTable {
    box-sizing: border-box;
    border: 1px solid var(--tabs-color);
    // backdrop-filter: blur(4px);
    box-shadow: var(--table-box-shadow);
    border-radius: 16px;
    padding: 24px;

    .TableHeaderWrapper {
      width: auto;

      &__title {
        margin: 7px 0 0 0;
        @include body_14;
        color: var(--text2-color);
      }

      .TableColumnWrapper {
        & > * {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        :first-child {
          padding-left: 24px;
        }
      }
    }

    .TableItemWrapper {
      @include body_14;
      color: var(--selected-color);
      width: auto;
      background: white;
      border: 1px solid var(--tabs-color);
      box-shadow: var(--box-shadow);
      // backdrop-filter: blur(4px);
      border-radius: 16px;
      margin-top: 24px;
      padding: 24px 16px 24px 16px;
      background-color: var(--dropdown-background-color);

      .TokenIconWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }

      .TokenIconName {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        span:first-child {
          @include body_18_sb;
          color: var(--selected-color);
        }
        span:nth-child(2) {
          @include body_12;
          color: #9193a1;
        }
      }

      .TokenIcon__name {
        margin: 0;

        b {
          @include body_18_sb;
          color: var(--selected-color);
        }
      }

      .TableColumnWrapper {
        p {
          margin: 0;
        }

        .ZeroAPY {
          // @include body_18_sb;
          color: var(--health-factor-color);
        }

        .BorrowAPY {
          // @include body_18_sb;
          background: var(--linear-apy-color);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .OldAPY {
          color: var(--text2-color);
          text-decoration: line-through;
        }

        .TokenIconWithFullName {
          padding-right: 20px;
        }
      }

      .LeverageColumn {
        flex-direction: row;
        justify-content: flex-start;
      }

      .ButtonColumn {
        flex-direction: row;
        justify-content: center;
        gap: 15px;

        & > * {
          flex: 1;
        }
      }
    }

    @include respond-to(sm) {
      & {
        border: none;
        padding: 0px;
      }
    }
  }
`;

export default staticStyles;
