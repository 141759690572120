import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  calculateHealthFactorFromBalancesBigUnits,
  GasResponse,
  InterestRate,
  normalize,
  valueToBigNumber,
} from '@sturdyfi/sturdy-js';

import DefaultButton from 'src/components/basic/DefaultButton';
import { OverviewDataType } from 'src/components/TransactionOverviewPanel/types';
import TransactionOverviewPanel from 'src/components/TransactionOverviewPanel';
import AmountBoxWrapper from 'src/components/Wrappers/AmountBoxWrapper';

import staticStyles from './style';
import { useTxBuilderContext } from 'src/libs/tx-provider';
import PoolTxConfirmationView, { EmptyTransaction } from 'src/components/PoolTxConfirmationView';
import ConnectButton from 'src/components/ConnectButton';
import { useNavigate } from 'react-router-dom';
import { ValidationWrapperComponentProps } from 'src/components/RouteParamsValidationWrapper';
import useDebounce from 'src/libs/hooks/use-debounce';
import { AmplitudeEventType, sendAmplitudeEvent } from 'src/helpers/amplitude';
import { getDefaultNetworkNameByString } from 'src/config';
import { useStaticPoolDataContext } from 'src/libs/pool-data-provider';

interface RepayMainProps
  extends Pick<
    ValidationWrapperComponentProps,
    'currencySymbol' | 'poolReserve' | 'user' | 'userReserve' | 'walletBalance'
  > {}

export default function RepayMain({
  currencySymbol,
  user,
  poolReserve,
  userReserve,
  walletBalance,
}: RepayMainProps) {
  const navigate = useNavigate();
  const { lendingPool } = useTxBuilderContext();
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const [amount, setAmount] = useState('0');
  const [maxAmount, setMaxAmount] = useState('0');
  const [fetchGasData, setFetchGasData] = useState<GasResponse>();
  const debouncedAmount = useDebounce<string>(amount || '0', 500);
  const isAmountAvailable = useMemo(
    () => valueToBigNumber(debouncedAmount).gt('0'),
    [debouncedAmount]
  );
  const isMaxAmountAvailable = useMemo(() => valueToBigNumber(maxAmount).gt('0'), [maxAmount]);

  useEffect(() => {
    if (userReserve) {
      let amountToRepay = valueToBigNumber(normalize(userReserve.totalBorrowsETH, -18))
        .div(poolReserve.priceInEth)
        .toString();
      setMaxAmount(Math.min(Number(amountToRepay), walletBalance.toNumber()).toString());
    }
  }, []);

  const repayOverviewData: OverviewDataType = useMemo(() => {
    let healthFactorAfter = '0';
    let assetAmount = userReserve?.totalBorrows || '0';
    const amountInETH = valueToBigNumber(debouncedAmount).multipliedBy(poolReserve.priceInEth);

    if (userReserve) {
      assetAmount = valueToBigNumber(userReserve.totalBorrows).minus(debouncedAmount).toString();
    }

    if (user) {
      healthFactorAfter = calculateHealthFactorFromBalancesBigUnits(
        user.totalCollateralETH,
        valueToBigNumber(user.totalBorrowsETH).minus(normalize(amountInETH, 18)),
        user.currentLiquidationThreshold
      ).toString();
      if (valueToBigNumber(healthFactorAfter).lt(0)) healthFactorAfter = Infinity.toString();
    }

    return {
      transactionType: 'repay',
      assetType: 'borrowable',
      assetSymbol: currencySymbol,
      assetAmount: assetAmount,
      healthFactor: healthFactorAfter,
      fetchGasData,
    };
  }, [debouncedAmount, fetchGasData]);

  const handleGetTransactions = useCallback(async () => {
    if (valueToBigNumber(maxAmount).gt(0) && user) {
      return await lendingPool.repay({
        user: user.id,
        reserve: poolReserve.underlyingAsset,
        amount: debouncedAmount.toString(),
        interestRateMode: InterestRate.Variable,
      });
    }
    return EmptyTransaction;
  }, [maxAmount, debouncedAmount]);

  const handleMainTxCompleted = () => {
    const price = normalize(
      valueToBigNumber(poolReserve.priceInEth).div(marketRefPriceInUsd), 
      18
    );
    const amountInUsd = parseFloat(debouncedAmount) * parseFloat(price);
    sendAmplitudeEvent(user!.id, AmplitudeEventType.repay, {
      network: getDefaultNetworkNameByString(),
      reserve: poolReserve.symbol,
      amount: +amount,
      value: +amountInUsd,
    });

    navigate('/dashboard');
  };

  const handleGasPriceChanged = (gas: GasResponse) => {
    setFetchGasData(() => gas);
  };

  return (
    <div className="RepayMain">
      <AmountBoxWrapper
        symbols={[`${currencySymbol}`]}
        value={amount}
        title={'Amount'}
        hasMax={true}
        maxValue={maxAmount}
        onChange={setAmount}
      />
      <TransactionOverviewPanel overviewData={repayOverviewData} />
      {!user && <ConnectButton type="button" />}
      {user && isMaxAmountAvailable && !isAmountAvailable && (
        <DefaultButton title="Enter amount" type="primary" color="blue" disabled={true} />
      )}
      {user && isMaxAmountAvailable && isAmountAvailable && (
        <PoolTxConfirmationView
          mainTxName={'repay'}
          currencySymbol={currencySymbol}
          getTransactionsData={handleGetTransactions}
          hideActionWrapper={!isAmountAvailable}
          onActiveTxChanged={handleGasPriceChanged}
          onMainTxConfirmed={handleMainTxCompleted}
        />
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
