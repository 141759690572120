import { defineMessages } from 'react-intl';

export default defineMessages({
  asset: 'Assets',
  totalAndBorrowed: 'Total dep. / borrowed',
  utilRate: 'Util. rate',
  walletBalance: 'Wallet balance',
  averageAPY: 'Average APY',
  depositAPY: 'Deposit APY',
  borrowAPY: 'Borrow APY',
});
