import NoData from 'src/components/basic/NoData';
import Value from 'src/components/basic/Value';
import ValuePercent from 'src/components/basic/ValuePercent';
import InfoPanelWrapper from '../../../Wrappers/InfoPanelWrapper';
import TextWithInfoWrapper from '../../../Wrappers/TextWithInfoWrapper';

import staticStyles from './style';

interface CollateralStatsPanelProps {
  TVL?: number | string;
  APY?: number | string;
  maxLeverage?: number | string;
  maxAPY?: number | string;
  maxLTV?: number | string;
  liquidationThreshold?: number | string;
  liquidationBonus?: number | string;
  price?: number | string;
}

export default function CollateralStatsPanel({
  TVL,
  APY,
  maxLeverage,
  maxAPY,
  maxLTV,
  liquidationThreshold,
  liquidationBonus,
  price,
}: CollateralStatsPanelProps) {
  return (
    <InfoPanelWrapper title={<span>Stats</span>} className={'CollateralStatsPanel'}>
      <div className={'CollateralStatsPanel__item'}>
        <div className={'CollateralStatsPanel__item__content'}>
          <TextWithInfoWrapper text={'Reserve size'} tooltipName="reserveSize" />
          <div className={'CollateralStatsPanel__item__content__value'}>
            {TVL ? (
              <Value
                value={TVL.toString()}
                symbol={'USD'}
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      <div className={'CollateralStatsPanel__item'}>
        <div className={'CollateralStatsPanel__item__content'}>
          <TextWithInfoWrapper text={'APY'} tooltipName="depositAPYForCollateral" />
          <div className={'CollateralStatsPanel__item__content__value'}>
            {APY ? <ValuePercent value={APY} maximumDecimals={2} /> : <NoData />}
          </div>
        </div>
      </div>

      <div className={'CollateralStatsPanel__item'}>
        <div className={'CollateralStatsPanel__item__content'}>
          <TextWithInfoWrapper text={'Max Leverage'} tooltipName="maxLeverage" />
          <div className={'CollateralStatsPanel__item__content__value'}>
            {maxLeverage ? (
              <>
                <Value value={maxLeverage} maximumValueDecimals={2} />
                <span>&nbsp;x</span>
              </>
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      <div className={'CollateralStatsPanel__item'}>
        <div className={'CollateralStatsPanel__item__content'}>
          <TextWithInfoWrapper text={'Max Apy'} tooltipName="maxAPY" />
          <div className={'CollateralStatsPanel__item__content__value'}>
            {maxAPY ? <ValuePercent value={maxAPY} maximumDecimals={2} /> : <NoData />}
          </div>
        </div>
      </div>

      <div className={'CollateralStatsPanel__item'}>
        <div className={'CollateralStatsPanel__item__content'}>
          <TextWithInfoWrapper text={'Maximum LTV'} tooltipName="maxLTV" />
          <div className={'CollateralStatsPanel__item__content__value'}>
            {maxLTV ? <ValuePercent value={maxLTV} maximumDecimals={2} /> : <NoData />}
          </div>
        </div>
      </div>

      <div className={'CollateralStatsPanel__item'}>
        <div className={'CollateralStatsPanel__item__content'}>
          <TextWithInfoWrapper text={'Liquidation threshold'} tooltipName="liquidationThreshold" />
          <div className={'CollateralStatsPanel__item__content__value'}>
            {liquidationThreshold ? (
              <ValuePercent value={liquidationThreshold} maximumDecimals={2} />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      <div className={'CollateralStatsPanel__item'}>
        <div className={'CollateralStatsPanel__item__content'}>
          <TextWithInfoWrapper text={'Liquidation penalty'} tooltipName="liquidationPenalty" />
          <div className={'CollateralStatsPanel__item__content__value'}>
            {liquidationBonus ? (
              <ValuePercent value={Number(liquidationBonus) - 1} maximumDecimals={2} />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      <div className={'CollateralStatsPanel__item'}>
        <div className={'CollateralStatsPanel__item__content'}>
          <p>Asset Price</p>
          <div className={'CollateralStatsPanel__item__content__value'}>
            {price ? (
              <Value
                value={price}
                symbol="USD"
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      <style jsx={true}>{staticStyles}</style>
    </InfoPanelWrapper>
  );
}
