import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import classNames from 'classnames';
import staticStyles from './style';

interface FeedbackProps {
  variant?: 'success' | 'warning' | 'error' | 'info';
  message: string;
  withIcon?: boolean;
}

export default function Feedback({
  variant = 'success',
  message,
  withIcon = false,
}: FeedbackProps) {
  const { isCurrentThemeDark } = useThemeContext();

  return (
    <div
      className={classNames('Feedback', {
        Feedback__success: variant === 'success',
        Feedback__warning: variant === 'warning',
        Feedback__error: variant === 'error',
        Feedback__info: variant === 'info',
        Feedback__darkTheme: isCurrentThemeDark,
      })}
    >
      <div className="Feedback__inner">
        <div className="Feedback__content">
          <div className="Feedback__message">{message}</div>
        </div>
      </div>
      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
