import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BigNumber,
  bignumberMin,
  calculateHealthFactorFromBalancesBigUnits,
  CollateralReserveMarketData,
  GasResponse,
  Network,
  normalize,
  parseNumber,
  pow10,
  valueToBigNumber,
} from '@sturdyfi/sturdy-js';
import GeneralLevSwapInterface from '@sturdyfi/sturdy-js/dist/tx-builder/interfaces/v1/GeneralLevSwap';

import DefaultButton from 'src/components/basic/DefaultButton';
import ConnectButton from 'src/components/ConnectButton';
import PoolTxConfirmationView, { EmptyTransaction } from 'src/components/PoolTxConfirmationView';
import { ValidationWrapperComponentProps } from 'src/components/RouteParamsValidationWrapper';
import TransactionOverviewPanel from 'src/components/TransactionOverviewPanel';
import { OverviewDataType } from 'src/components/TransactionOverviewPanel/types';
import AmountBoxWrapper from 'src/components/Wrappers/AmountBoxWrapper';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';
import { useTxBuilderContext } from 'src/libs/tx-provider';
import staticStyles from './style';
import { useDynamicPoolDataContext, useStaticPoolDataContext } from 'src/libs/pool-data-provider';
import useDebounce from 'src/libs/hooks/use-debounce';
import ErrorMsg from 'src/components/ErrorMsg';
import { AmplitudeEventType, sendAmplitudeEvent } from 'src/helpers/amplitude';
import { getDefaultNetworkNameByString } from 'src/config';
import GeneralLevSwap2Interface from '@sturdyfi/sturdy-js/dist/tx-builder/interfaces/v1/GeneralLevSwap2';
import { getSwapInfo } from 'src/ui-config';
import { ethers } from 'ethers';
import Feedback from 'src/components/Feedback';

interface DeleverageMainProps
  extends Pick<
    ValidationWrapperComponentProps,
    'currencySymbol' | 'poolReserve' | 'user' | 'userReserve'
  > {}

const DEFAULT_LEVSWAP2_SLIPPAGE = 0.0002; // 0.02%
const DEFAULT_SLIPPAGE = 0.002; // 0.2%

export default function DeleverageMain({
  currencySymbol,
  user,
  poolReserve,
  userReserve,
}: DeleverageMainProps) {
  const LEVSWAP2_Symbols = [
    'FRAX_3CRV_LP',
    'DAI_USDC_USDT_SUSD_LP',
    'FRAX_USDC_LP',
    'TUSD_FRAXBP_LP',
    'MIM_3CRV_LP',
    'BAL_BB_A3_USD_LP',
  ];

  const navigate = useNavigate();
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { reserves } = useDynamicPoolDataContext();
  const borrowedReserves =
    user?.reservesUserData.filter((reserve) => valueToBigNumber(reserve.totalBorrows).gt(0)) || [];
  const { network, networkConfig, currentMarketData } = useProtocolDataContext();

  const [amount, setAmount] = useState('0');
  const debouncedAmount = useDebounce<string>(amount || '0', 500);
  const [repayingAssetSymbol, setRepayingAssetSymbol] = useState(borrowedReserves[0]?.symbol);
  const [fetchGasData, setFetchGasData] = useState<GasResponse>();
  const [repayingAmount, setRepayingAmount] = useState('0');
  const debouncedRepayingAmount = useDebounce<string>(repayingAmount || '0', 500);
  const isAmountAvailable = valueToBigNumber(debouncedAmount || '0').gt(0);
  const isRepayingAmountAvailable = valueToBigNumber(debouncedRepayingAmount || '0').gt(0);
  const [slippage, setSlippage] = useState(
    LEVSWAP2_Symbols.includes(currencySymbol) ? DEFAULT_LEVSWAP2_SLIPPAGE : DEFAULT_SLIPPAGE
  );
  const [leverageSlippage, setLeverageSlippage] = useState(0);
  const [isSlippageLow, setIsSlippageLow] = useState(false);
  const [slippageExpected, setSlippageExpected] = useState(
    LEVSWAP2_Symbols.includes(currencySymbol) ? 0 : undefined
  );

  const {
    convexFRAX3CRVLevSwap,
    convexDAIUSDCUSDTSUSDLevSwap,
    convexFRAXUSDCLevSwap,
    convexIRONBANKLevSwap,
    convexMIM3CRVLevSwap,
    convexTUSDFRAXBPLevSwap,
    auraBBA3USDLevSwap,
    convexETHSTETHLevSwap,
    auraWSTETHWETHLevSwap,
    auraRETHWETHLevSwap,
    curvePoolService,
    curveAPIInit,
    balancerPoolService,
  } = useTxBuilderContext();
  const symbolToLevSwap: {
    [key: string]: { [key: string]: GeneralLevSwapInterface | GeneralLevSwap2Interface };
  } = {
    [Network.mainnet]: {
      FRAX_3CRV_LP: convexFRAX3CRVLevSwap,
      DAI_USDC_USDT_SUSD_LP: convexDAIUSDCUSDTSUSDLevSwap,
      IRON_BANK_LP: convexIRONBANKLevSwap,
      FRAX_USDC_LP: convexFRAXUSDCLevSwap,
      MIM_3CRV_LP: convexMIM3CRVLevSwap,
      TUSD_FRAXBP_LP: convexTUSDFRAXBPLevSwap,
      BAL_BB_A3_USD_LP: auraBBA3USDLevSwap,
    },
    [Network.fork]: {
      FRAX_3CRV_LP: convexFRAX3CRVLevSwap,
      DAI_USDC_USDT_SUSD_LP: convexDAIUSDCUSDTSUSDLevSwap,
      IRON_BANK_LP: convexIRONBANKLevSwap,
      FRAX_USDC_LP: convexFRAXUSDCLevSwap,
      MIM_3CRV_LP: convexMIM3CRVLevSwap,
      TUSD_FRAXBP_LP: convexTUSDFRAXBPLevSwap,
      BAL_BB_A3_USD_LP: auraBBA3USDLevSwap,
    },
    [Network.eth]: {
      ETH_STETH_LP: convexETHSTETHLevSwap,
      BAL_WSTETH_WETH_LP: auraWSTETHWETHLevSwap,
      BAL_RETH_WETH_LP: auraRETHWETHLevSwap,
    },
  };

  const maxAmountToDeleverage = useMemo(() => {
    let maxAmountToWithdraw = valueToBigNumber(0);
    let maxCollateralToWithdrawInETH = valueToBigNumber(0);
    if (!userReserve || !user) return maxAmountToWithdraw;

    maxAmountToWithdraw = BigNumber.min(userReserve.underlyingBalance, poolReserve.totalLiquidity);
    maxCollateralToWithdrawInETH = maxAmountToWithdraw;

    if (valueToBigNumber(user.totalBorrowsETH).gt('0')) {
      let healthFactor = user.healthFactor;
      let totalBorrowsETH = user.totalBorrowsETH;
      let liquidationThresholdAfter = user.currentLiquidationThreshold;
      let excessHF;
      const repayingReserve = reserves.find(
        (res) => res.symbol.toUpperCase() === repayingAssetSymbol
      );

      if (repayingReserve) {
        const repayingAmountInETH = valueToBigNumber(debouncedRepayingAmount).multipliedBy(
          repayingReserve.priceInEth
        );
        const totalCollateralETHAfter = valueToBigNumber(user.totalCollateralETH).minus(
          normalize(repayingAmountInETH, 18)
        );
        liquidationThresholdAfter = valueToBigNumber(user.totalCollateralETH)
          .multipliedBy(user.currentLiquidationThreshold)
          .minus(
            normalize(
              repayingAmountInETH.multipliedBy(
                (poolReserve as CollateralReserveMarketData).liquidationThreshold
              ),
              18
            )
          )
          .dividedBy(totalCollateralETHAfter)
          .toString();

        maxAmountToWithdraw = maxAmountToWithdraw.minus(
          repayingAmountInETH.dividedBy(poolReserve.priceInEth)
        );
        totalBorrowsETH = valueToBigNumber(totalBorrowsETH)
          .minus(normalize(repayingAmountInETH, 18))
          .toString();

        // // Calculate the leverage slippage value
        // if (valueToBigNumber(debouncedAmount).gt(0)) {
        //   const amountInETH = valueToBigNumber(debouncedAmount).multipliedBy(poolReserve.priceInEth);
        //   healthFactor = calculateHealthFactorFromBalancesBigUnits(
        //     user.totalCollateralETH,
        //     totalBorrowsETH,
        //     user.currentLiquidationThreshold
        //   ).toString();
        //   excessHF = valueToBigNumber(healthFactor).minus(1);

        //   if (valueToBigNumber(totalBorrowsETH).gt(0))
        //     maxCollateralToWithdrawInETH = excessHF
        //       .multipliedBy(totalBorrowsETH)
        //       .div(Number(user.currentLiquidationThreshold));

        //   setLeverageSlippage(
        //     Number(maxCollateralToWithdrawInETH
        //       .multipliedBy(slippage)
        //       .plus(maxCollateralToWithdrawInETH
        //         .multipliedBy(1 - slippage)
        //         .minus(normalize(repayingAmountInETH, 18))
        //         .multipliedBy(slippage)
        //       )
        //       .dividedBy(normalize(amountInETH, 18))
        //       .toFixed(4)
        //     )
        //   );
        // }

        healthFactor = calculateHealthFactorFromBalancesBigUnits(
          totalCollateralETHAfter,
          totalBorrowsETH,
          liquidationThresholdAfter
        ).toString();
      }

      excessHF = valueToBigNumber(healthFactor).minus(1);
      if (excessHF.gt('0') && repayingReserve) {
        const collateralReserve = poolReserve as CollateralReserveMarketData;

        maxCollateralToWithdrawInETH = excessHF
          .multipliedBy(totalBorrowsETH)
          .div(Number(liquidationThresholdAfter));

        maxAmountToWithdraw = BigNumber.min(
          maxAmountToWithdraw,
          maxCollateralToWithdrawInETH
            .multipliedBy(pow10(18))
            // because of the rounding issue on the contracts side this value still can be incorrect
            .minus(1)
            .multipliedBy('0.99')
            .dividedBy(collateralReserve.priceInEth)
        );
      }
    }
    return maxAmountToWithdraw;
  }, [debouncedRepayingAmount, debouncedAmount, repayingAssetSymbol]);

  const repayableData = useMemo(() => {
    const reserve = poolReserve as CollateralReserveMarketData;

    const underlyingBalanceETH = normalize(
      valueToBigNumber(userReserve?.underlyingBalanceETH || '0'),
      -18
    ).toString();

    const maxRepayableInETH = valueToBigNumber(underlyingBalanceETH).multipliedBy(
      reserve.liquidationThreshold
    );

    return borrowedReserves.map((userReserve) => {
      const borrowedAmountInETH = normalize(userReserve.totalBorrowsETH, -18);
      const reserve = reserves.find(
        (res) => res.underlyingAsset.toUpperCase() === userReserve.underlyingAsset.toUpperCase()
      );
      if (!reserve) {
        throw new Error('data is inconsistent pool reserve is not available');
      }

      // select partial amount
      const repayableInETH = BigNumber.min(
        valueToBigNumber(borrowedAmountInETH),
        maxRepayableInETH
      );
      const repayableAmount = repayableInETH.dividedBy(reserve.priceInEth).toString();

      return {
        symbol: userReserve.symbol,
        underlyingAsset: userReserve.underlyingAsset,
        repayableAmount,
      };
    });
  }, [debouncedAmount]);

  const handleSlippageChanged = (value: number) => {
    if (isSlippageLow && slippage < value) {
      setIsSlippageLow(false);
    }
    setSlippage(value);
  };

  const txOverviewData: OverviewDataType = useMemo(() => {
    const price = normalize(valueToBigNumber(poolReserve.priceInEth).div(marketRefPriceInUsd), 18);
    const amountInETH = valueToBigNumber(debouncedAmount).multipliedBy(poolReserve.priceInEth);
    const repayingReserve = reserves.find(
      (res) => res.symbol.toUpperCase() === repayingAssetSymbol
    );
    let repayingAmountInETH = '0';
    let remainingBalance = valueToBigNumber(userReserve?.underlyingBalance || '0');
    let healthFactorAfter = user?.healthFactor;
    let liquidationPrice = '0';
    let liquidationPriceAfter = '0';

    if (user && repayingReserve) {
      repayingAmountInETH = valueToBigNumber(debouncedRepayingAmount)
        .multipliedBy(repayingReserve.priceInEth)
        .toString();
      const totalCollateralETHAfter = BigNumber.max(
        valueToBigNumber(user.totalCollateralETH)
          .minus(normalize(amountInETH, 18))
          .minus(normalize(repayingAmountInETH, 18)),
        valueToBigNumber(0)
      );
      const liquidationThresholdAfter = valueToBigNumber(user.totalCollateralETH)
        .multipliedBy(user.currentLiquidationThreshold)
        .minus(
          normalize(
            amountInETH
              .plus(repayingAmountInETH)
              .multipliedBy((poolReserve as CollateralReserveMarketData).liquidationThreshold),
            18
          )
        )
        .dividedBy(totalCollateralETHAfter);
      healthFactorAfter = calculateHealthFactorFromBalancesBigUnits(
        totalCollateralETHAfter,
        valueToBigNumber(user.totalBorrowsETH).minus(normalize(repayingAmountInETH, 18)),
        liquidationThresholdAfter
      ).toString();
      if (valueToBigNumber(healthFactorAfter).lt(0)) healthFactorAfter = Infinity.toString();

      liquidationPriceAfter = valueToBigNumber(user.totalBorrowsETH)
        .minus(normalize(repayingAmountInETH, 18))
        .dividedBy(liquidationThresholdAfter)
        .dividedBy(
          valueToBigNumber(user.totalCollateralETH)
            .minus(normalize(amountInETH, 18))
            .minus(normalize(repayingAmountInETH, 18))
        )
        .multipliedBy(price)
        .toString();
    }

    if (userReserve) {
      remainingBalance = valueToBigNumber(userReserve.underlyingBalance)
        .minus(debouncedAmount)
        .minus(valueToBigNumber(repayingAmountInETH).dividedBy(poolReserve.priceInEth));
      if (remainingBalance.lt(0)) remainingBalance = valueToBigNumber(0);
    }

    if (user) {
      liquidationPrice = valueToBigNumber(user.totalBorrowsETH)
        .dividedBy(user.currentLiquidationThreshold)
        .dividedBy(user.totalCollateralETH)
        .multipliedBy(price)
        .toString();
    }

    return {
      transactionType: 'deleverage',
      assetType: 'collateral',
      assetAmount: remainingBalance.toString(),
      assetSymbol: currencySymbol,
      healthFactor: user?.healthFactor,
      healthFactorAfter,
      APY: (poolReserve as CollateralReserveMarketData).depositAPY,
      price: price.toString(),
      liquidationPrice,
      liquidationPriceAfter,
      useFlashloan: true,
      slippage,
      slippageExpected,
      leverageSlippage,
      isSlippageLow,
      maxSlippage: currentMarketData.maxFlashloanSlippage
        ? currentMarketData.maxFlashloanSlippage
        : 0.05,
      onSlippageChanged: handleSlippageChanged,
      fetchGasData,
    };
  }, [
    debouncedAmount,
    debouncedRepayingAmount,
    repayingAssetSymbol,
    slippage,
    isSlippageLow,
    leverageSlippage,
    slippageExpected,
  ]);

  const handleGetTransactions = useCallback(async () => {
    if (user && userReserve && isAmountAvailable && isRepayingAmountAvailable) {
      const repayingReserve = borrowedReserves.find(
        (res) => res.symbol.toUpperCase() === repayingAssetSymbol
      );
      if (repayingReserve) {
        const entry = Object.entries(symbolToLevSwap?.[currentMarketData.network]).find(([key]) =>
          networkConfig.collateralAssets?.[key]?.includes(currencySymbol)
        );
        if (entry) {
          const isBalancerLP = currencySymbol.slice(0, 4) === 'BAL_';
          const usingAaveFlashloan = network === Network.eth || isBalancerLP;
          const poolService = isBalancerLP ? balancerPoolService : curvePoolService;
          if (LEVSWAP2_Symbols.includes(currencySymbol)) {
            const leverageSwapper = entry[1] as GeneralLevSwap2Interface;
            const swapInfo = await getSwapInfo(
              currencySymbol,
              repayingReserve.underlyingAsset,
              parseNumber(debouncedRepayingAmount, repayingReserve.decimals),
              slippage,
              0,
              poolService,
              await curveAPIInit,
              leverageSwapper,
              user,
              poolReserve as CollateralReserveMarketData,
              parseNumber(userReserve.underlyingBalance, userReserve.decimals)
            );

            setSlippageExpected(swapInfo.expectedSlippage / 100);

            return await leverageSwapper.withdrawWithFlashloan({
              _user: user.id,
              _asset:
                networkConfig.collateralAddresses?.[currencySymbol] || poolReserve.underlyingAsset,
              _repayAmount: debouncedRepayingAmount,
              _withdrawAmount: debouncedAmount,
              _sasset: poolReserve.aTokenAddress,
              _sassetAmount: userReserve.underlyingBalance,
              _stableAsset: repayingReserve.underlyingAsset,
              _flashloanType: usingAaveFlashloan ? '0' : '1',
              _swapInfo: swapInfo.info,
            });
          } else {
            const leverageSwapper = entry[1] as GeneralLevSwapInterface;
            return await leverageSwapper.withdrawWithFlashloan({
              _user: user.id,
              _asset:
                networkConfig.collateralAddresses?.[currencySymbol] || poolReserve.underlyingAsset,
              _repayAmount: debouncedRepayingAmount,
              _withdrawAmount: debouncedAmount,
              _sasset: poolReserve.aTokenAddress,
              _sassetAmount: userReserve.underlyingBalance,
              _slippage: (slippage * 10000).toFixed(0),
              _stableAsset: repayingReserve.underlyingAsset,
              _flashloanType: usingAaveFlashloan ? '0' : '1',
            });
          }
        }
      }
    }
    return EmptyTransaction;
  }, [repayableData, debouncedRepayingAmount, repayingAssetSymbol, slippage]);

  const handleMainTxCompleted = () => {
    const price = normalize(valueToBigNumber(poolReserve.priceInEth).div(marketRefPriceInUsd), 18);
    const amountInUsd = parseFloat(debouncedAmount) * parseFloat(price);
    sendAmplitudeEvent(user!.id, AmplitudeEventType.deleverage, {
      network: getDefaultNetworkNameByString(),
      reserve: poolReserve.symbol,
      amount: +amount,
      value: +amountInUsd,
    });

    navigate('/dashboard');
  };

  const handleGasPriceChanged = (gas: GasResponse) => {
    setFetchGasData(() => gas);
  };

  const handleTokenSwitcher = (token: string) => {
    setRepayingAssetSymbol(token);
  };

  const err = borrowedReserves.length === 0 ? 'There is no debt' : undefined;

  const isHighSlippageExpected =
    isAmountAvailable &&
    isRepayingAmountAvailable &&
    slippageExpected !== undefined &&
    slippageExpected <= -0.01;

  return (
    <div className="DeleverageMain">
      <AmountBoxWrapper
        symbols={[currencySymbol]}
        value={amount}
        title={'Amount'}
        hasMax={true}
        maxValue={maxAmountToDeleverage.toString()}
        onChange={setAmount}
      />
      <AmountBoxWrapper
        title={'Repaying'}
        titleTooltipName={
          currencySymbol === 'BAL_WSTETH_WETH_LP'
            ? 'howAuraDeleverageWorking'
            : 'howCurveDeleverageWorking'
        }
        hasMax={true}
        maxValue={
          repayableData.find((item) => item.symbol === repayingAssetSymbol)?.repayableAmount || '0'
        }
        symbols={repayableData.map((item) => item.symbol)}
        value={repayingAmount}
        onChange={setRepayingAmount}
        selectedSymbol={repayingAssetSymbol}
        onChangeSymbol={handleTokenSwitcher}
      />
      <TransactionOverviewPanel overviewData={txOverviewData} />
      {isHighSlippageExpected && (
        <Feedback
          variant="warning"
          message="Warning: high slippage. You may want to repay the loan and withdraw manually."
        />
      )}
      {!user && <ConnectButton type="button" />}
      {user && (!isAmountAvailable || !isRepayingAmountAvailable) && (
        <DefaultButton title="Enter amount" type="primary" color="blue" disabled={true} />
      )}
      {user && isAmountAvailable && isRepayingAmountAvailable && (
        <PoolTxConfirmationView
          mainTxName={'deleverage'}
          currencySymbol={currencySymbol}
          getTransactionsData={handleGetTransactions}
          hideActionWrapper={valueToBigNumber(amount).eq('0')}
          onActiveTxChanged={handleGasPriceChanged}
          onMainTxConfirmed={handleMainTxCompleted}
          onLowSlippageDetected={() => setIsSlippageLow(true)}
        />
      )}
      <ErrorMsg failed={err} />
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
