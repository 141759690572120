import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .AuditTooltip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 8px;
    gap: 24px;

    a {
      display: block;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export default staticStyles;
