import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .TableItemWrapper {
    width: 100%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    // border: 1px solid transparent;
    font-size: 12px;

    &.TableItemWrapper__disabled {
      cursor: default;
      // border: 1px solid transparent;
      box-shadow: none;
      &:hover,
      &:active {
        // border: 1px solid transparent;
        box-shadow: none;
      }
    }

    @include respond-to(sm) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
    }
  }
`;

export default staticStyles;
