import { Carousel } from 'react-responsive-carousel';
import { valueToBigNumber, normalize, CollateralReserveMarketData } from '@sturdyfi/sturdy-js';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import NoDataPanel from 'src/components/NoDataPanel';
import Caption from 'src/components/basic/Caption';
import ScreenWrapper from 'src/components/Wrappers/ScreenWrapper';
import ValueBoxWrapper from 'src/components/Wrappers/ValueBoxWrapper';

import { useDynamicPoolDataContext, useStaticPoolDataContext } from 'src/libs/pool-data-provider';
import { useUserWalletDataContext } from 'src/libs/web3-data-provider';

import { DepositAssetProps } from '../components/DepositAssetTable/types';
import DepositAssetTable from '../components/DepositAssetTable';
import LendCurrencyTable from '../components/LendCurrencyTable';
import { LendCurrencyProps } from '../components/LendCurrencyTable/types';
import BorrowCurrencyTable from '../components/BorrowCurrencyTable';
import { BorrowCurrencyProps } from '../components/BorrowCurrencyTable/types';

import staticStyles from './style';
import { useIncentivesDataContext } from 'src/libs/pool-data-provider/hooks/use-incentives-data-context';
import SkeletonWrapper from 'src/components/Wrappers/SkeletonWrapper';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';
import { OverViewCard, RewardsCard, RewardsTokenDataType, SummaryCard } from '../components/Cards';
import { REWARD_TYPE } from 'src/helpers/markets/types';

export default function Dashboard() {
  const { marketRefPriceInUsd, extraAssetPrices } = useStaticPoolDataContext();
  const { reserves, user, isLoading } = useDynamicPoolDataContext();
  const { networkConfig } = useProtocolDataContext();
  const { currentAccount, showSelectWalletModal } = useUserWalletDataContext();
  const { userReward, isLoading: isIncentiveLoading } = useIncentivesDataContext();

  if (!currentAccount) {
    return (
      <NoDataPanel
        title="Connect your wallet"
        description="Please connect your wallet in order to see the dashboard page"
        iconName="wallet"
        onClick={() => showSelectWalletModal()}
      />
    );
  }

  let totalLentInUSD = valueToBigNumber('0');
  let totalCollateralInUSD = valueToBigNumber('0');
  let totalBorrowedInUSD = valueToBigNumber('0');

  const lendAssetsData: LendCurrencyProps[] = [];
  const depositAssetsData: DepositAssetProps[] = [];
  const borrowAssetsData: BorrowCurrencyProps[] = [];
  const rewardData: RewardsTokenDataType[] = [];

  if (reserves && reserves.length > 0) {
    // lent & borrow
    reserves
      .filter((reserve) => reserve.isActive && reserve.isBorrowingEnabled)
      .forEach((reserve) => {
        const userReserve = user?.reservesUserData.find(
          (res) => res.underlyingAsset === reserve.underlyingAsset
        );

        if (userReserve && Number(userReserve.underlyingBalanceUSD) > 0) {
          totalLentInUSD = totalLentInUSD.plus(userReserve.underlyingBalanceUSD);

          lendAssetsData.push({
            symbol: userReserve.symbol,
            underlyingAsset: userReserve.underlyingAsset,
            underlyingBalance: userReserve.underlyingBalance,
            depositAPY: userReserve.depositAPY,
            avgAPY: userReserve.avgAPY,
          });
        }
        if (userReserve && Number(userReserve.totalBorrowsUSD) > 0) {
          totalBorrowedInUSD = totalBorrowedInUSD.plus(userReserve.totalBorrowsUSD);

          borrowAssetsData.push({
            symbol: userReserve.symbol,
            borrowAPY: userReserve.borrowAPY,
            debt: userReserve.totalBorrows,
            underlyingAsset: userReserve.underlyingAsset,
          });
        }
      });

    // provided collateral
    reserves
      .filter((reserve) => reserve.isActive && reserve.isCollateralEnabled)
      .forEach((reserve) => {
        const userReserve = user?.reservesUserData.find(
          (res) => res.underlyingAsset === reserve.underlyingAsset
        );
        if (userReserve && Number(userReserve.underlyingBalanceUSD) > 0) {
          totalCollateralInUSD = totalCollateralInUSD.plus(userReserve.underlyingBalanceUSD);

          depositAssetsData.push({
            symbol: userReserve.symbol,
            underlyingAsset: userReserve.underlyingAsset,
            underlyingBalance: userReserve.underlyingBalance,
            depositAPY: userReserve.depositAPY,
            aprDetails: (reserve as CollateralReserveMarketData).aprDetails,
            avgAPY: userReserve.avgAPY,
          });
        }
      });
  }

  networkConfig.collateralRewardAddresses
    ?.map((rewardAddress) => {
      const {
        token: rewardTokenAddress,
        decimals: rewardTokenDecimals,
        symbol,
        type,
      } = rewardAddress;

      const rewardTokenPrice =
        extraAssetPrices.find(
          (asset) => asset.address.toLowerCase() === rewardTokenAddress.toLowerCase()
        )?.price || '0';

      return {
        symbol,
        rewardTokenAddress,
        rewardTokenDecimals,
        rewardTokenPrice,
        rewardType: type,
      };
    })
    .forEach((rewardToken) => {
      let claimableAmount = valueToBigNumber(0);
      let shouldSubtract = false;
      userReward?.forEach((userReward) => {
        userReward.rewardUserDatas.forEach((item) => {
          if (
            item.rewardTokenAddress.toLowerCase() === rewardToken.rewardTokenAddress.toLowerCase()
          ) {
            claimableAmount = claimableAmount.plus(valueToBigNumber(item.rewardsBalance));
            if (rewardToken.rewardType !== REWARD_TYPE.VARIABLE) {
              if (shouldSubtract) {
                claimableAmount = claimableAmount.minus(
                  valueToBigNumber(item.userUnclaimedRewards)
                );
              } else {
                shouldSubtract = true;
              }
            }
          }
        });
      });

      // ToDo: using distributionEnd
      if (rewardToken.rewardType === REWARD_TYPE.STABLE && claimableAmount.eq(0)) {
        return;
      }
      rewardData.push({
        symbol: rewardToken.symbol,
        claimableAmount: normalize(claimableAmount, rewardToken.rewardTokenDecimals),
      });
    });

  const totalDepositedInUSD = totalLentInUSD.plus(totalCollateralInUSD);
  const totalDepositedInETH = totalDepositedInUSD.multipliedBy(marketRefPriceInUsd);
  const totalBorrowedInETH = totalBorrowedInUSD.multipliedBy(marketRefPriceInUsd);

  const summaryData = {
    totalDepositedInETH,
    totalDepositedInUSD,
    totalBorrowedInUSD,
    totalBorrowedInETH,
    borrowPowerUsed: user?.totalBorrowPowerUsed || '0',
    currentLTV: user?.currentLTV || '0',
  };

  const healthFactor = borrowAssetsData.length === 0 ? 0 : user?.healthFactor || 0;

  const overviewData = {
    avgAPY: user?.totalAPY || '0',
    borrowAPY: user?.totalBorrowAPY || '0',
    healthFactor,
  };

  return (
    <ScreenWrapper>
      <div className="Dashboard">
        <div className="Dashboard__section">
          <Caption title="Your Positions" />
          <div className="Dashboard__cards">
            <div className="Dashboard__cards-primary">
              <SummaryCard data={summaryData} isLoading={isLoading} />
            </div>
            <div className="Dashboard__cards-secondary">
              <OverViewCard data={overviewData} isLoading={isLoading} />
              <RewardsCard data={rewardData} isLoading={isLoading} />
            </div>
          </div>
        </div>
        {isLoading ? (
          <>
            <div className="Dashboard__section">
              <Caption title="Lending Positions" />
              <div className="Dashboard__section-summary">
                <SkeletonWrapper width={300} height={30} />
              </div>
              <LendCurrencyTable listData={lendAssetsData} isLoading={isLoading} />
            </div>
            <div className="Dashboard__section">
              <Caption title="Deposits" />
              <div className="Dashboard__section-summary">
                <SkeletonWrapper width={300} height={30} />
              </div>
              <DepositAssetTable listData={depositAssetsData} isLoading={isLoading} />
            </div>
            <div className="Dashboard__section">
              <Caption title="Borrows" />
              <div className="Dashboard__section-summary">
                <SkeletonWrapper width={500} height={30} />
              </div>
              <BorrowCurrencyTable listData={borrowAssetsData} isLoading={isLoading} />
            </div>
          </>
        ) : (
          <>
            {lendAssetsData.length > 0 && (
              <div className="Dashboard__section">
                <Caption title="Lending Positions" />
                <div className="Dashboard__section-summary">
                  <ValueBoxWrapper
                    type={'price'}
                    title={'Balance'}
                    value={user?.totalSupplyBalanceUSD}
                  />
                  <ValueBoxWrapper
                    type={'percent'}
                    title={'Total APY'}
                    value={user?.totalSupplyAPY}
                  />
                </div>
                <LendCurrencyTable listData={lendAssetsData} isLoading={isLoading} />
              </div>
            )}
            {depositAssetsData.length > 0 && (
              <div className="Dashboard__section">
                <Caption title="Deposits" />
                <div className="Dashboard__section-summary">
                  <ValueBoxWrapper
                    type={'price'}
                    title={'Balance'}
                    value={user?.totalCollateralUSD}
                  />
                  <ValueBoxWrapper
                    type={'percent'}
                    title={'Total APY'}
                    value={user?.totalCollateralAPY}
                  />
                </div>
                <DepositAssetTable listData={depositAssetsData} isLoading={isLoading} />
              </div>
            )}
            {borrowAssetsData.length > 0 && (
              <div className="Dashboard__section">
                <Caption title="Borrows" />
                <div className="Dashboard__section-summary">
                  <ValueBoxWrapper type={'price'} title={'Balance'} value={user?.totalBorrowsUSD} />
                  <ValueBoxWrapper
                    type={'percent'}
                    title={'Total APY'}
                    value={user?.totalBorrowAPY}
                  />
                  <ValueBoxWrapper
                    type={'percent'}
                    title={'Borrow Power Used'}
                    value={user?.totalBorrowPowerUsed}
                  />
                </div>
                <BorrowCurrencyTable listData={borrowAssetsData} isLoading={isLoading} />
              </div>
            )}
          </>
        )}
        <style jsx={true} global={true}>
          {staticStyles}
        </style>
      </div>
    </ScreenWrapper>
  );
}
