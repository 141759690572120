import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import {
  auditC4DarkIcon,
  auditC4LightIcon,
  auditCertikDarkIcon,
  auditCertikLightIcon,
  auditQuantstampDarkIcon,
  auditQuantstampLightIcon,
} from 'src/images';
import staticStyles from './style';

export default function Audit() {
  const { isCurrentThemeDark } = useThemeContext();
  return (
    <div className="AuditTooltip">
      <div>
        <a href="https://certificate.quantstamp.com/full/sturdy" target={'_blank'}>
          <img
            src={isCurrentThemeDark ? auditQuantstampDarkIcon : auditQuantstampLightIcon}
            alt="Sturdy Audit"
          />
        </a>
      </div>
      <div>
        <a href="https://code4rena.com/reports/2022-05-sturdy/" target={'_blank'}>
          <img src={isCurrentThemeDark ? auditC4DarkIcon : auditC4LightIcon} alt="Sturdy Audit" />
        </a>
      </div>
      <div>
        <a href="https://www.certik.com/projects/sturdy" target={'_blank'}>
          <img
            src={isCurrentThemeDark ? auditCertikDarkIcon : auditCertikLightIcon}
            alt="Sturdy Audit"
          />
        </a>
      </div>
      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
