import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BigNumber,
  BorrowableReserveMarketData,
  calculateHealthFactorFromBalancesBigUnits,
  CollateralReserveMarketData,
  GasResponse,
  Network,
  normalize,
  parseNumber,
  valueToBigNumber,
} from '@sturdyfi/sturdy-js';
import GeneralLevSwapInterface from '@sturdyfi/sturdy-js/dist/tx-builder/interfaces/v1/GeneralLevSwap';

import DefaultButton from 'src/components/basic/DefaultButton';
import ConnectButton from 'src/components/ConnectButton';
import PoolTxConfirmationView, { EmptyTransaction } from 'src/components/PoolTxConfirmationView';
import { ValidationWrapperComponentProps } from 'src/components/RouteParamsValidationWrapper';
import TransactionOverviewPanel from 'src/components/TransactionOverviewPanel';
import { OverviewDataType } from 'src/components/TransactionOverviewPanel/types';
import AmountBoxWrapper from 'src/components/Wrappers/AmountBoxWrapper';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';
import { useTxBuilderContext } from 'src/libs/tx-provider';
import staticStyles from './style';
import LeveragePanel from 'src/components/LeveragePanel';
import { useDynamicPoolDataContext, useStaticPoolDataContext } from 'src/libs/pool-data-provider';
import BorrowPosition, { BorrowPositionType } from './BorrowPosition';
import useDebounce from 'src/libs/hooks/use-debounce';
import { useWalletBalanceProviderContext } from 'src/libs/wallet-balance-provider/WalletBalanceProvider';
import ZapModal from 'src/components/ZapModal';
import ErrorMsg from 'src/components/ErrorMsg';
import { AmplitudeEventType, sendAmplitudeEvent } from 'src/helpers/amplitude';
import { getDefaultNetworkNameByString } from 'src/config';
import GeneralLevSwap2Interface from '@sturdyfi/sturdy-js/dist/tx-builder/interfaces/v1/GeneralLevSwap2';
import { getSwapInfo, getZapSwapInfo } from 'src/ui-config';

type LeverageMode = 'normal' | 'zap';

interface LeverageMainProps
  extends Pick<
    ValidationWrapperComponentProps,
    'currencySymbol' | 'poolReserve' | 'walletBalance' | 'user' | 'userReserve'
  > {}

const MIN_LEVERAGE = 1.9;
const OPTIMAL_UTILIZATION_RATE = 0.8;
const EXCESS_UTILIZATION_RATE = 1 - OPTIMAL_UTILIZATION_RATE;
const DEFAULT_LEVSWAP2_SLIPPAGE = 0.0002; // 0.02%
const DEFAULT_SLIPPAGE = 0.002; // 0.2%

export default function LeverageMain({
  currencySymbol,
  walletBalance,
  user,
  poolReserve,
  userReserve,
}: LeverageMainProps) {
  const LEVSWAP2_Symbols = [
    'FRAX_3CRV_LP',
    'DAI_USDC_USDT_SUSD_LP',
    'FRAX_USDC_LP',
    'TUSD_FRAXBP_LP',
    'MIM_3CRV_LP',
    'BAL_BB_A3_USD_LP',
  ];

  const navigate = useNavigate();
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { reserves } = useDynamicPoolDataContext();
  const { walletData } = useWalletBalanceProviderContext();
  const [currentMode, setCurrentMode] = useState<LeverageMode>('zap');
  const [zapModalVisible, setZapModalVisible] = useState(false);

  const [fetchGasData, setFetchGasData] = useState<GasResponse>();
  const { network, networkConfig, currentMarketData } = useProtocolDataContext();
  const [amount, setAmount] = useState('0');
  const [zapAmount, setZapAmount] = useState('0');
  const debouncedAmount = useDebounce<string>(amount || '0', 500);
  const debouncedZapAmount = useDebounce<string>(zapAmount || '0', 500);
  const [leverage, setLeverage] = useState(MIN_LEVERAGE);
  const debouncedLeverage = useDebounce<number>(leverage || MIN_LEVERAGE, 500);
  const [borrowingAssetSymbol, setBorrowAssetSymbol] = useState('');
  const [slippage, setSlippage] = useState(
    LEVSWAP2_Symbols.includes(currencySymbol) ? DEFAULT_LEVSWAP2_SLIPPAGE : DEFAULT_SLIPPAGE
  );
  const [isSlippageLow, setIsSlippageLow] = useState(false);
  const [slippageExpected, setSlippageExpected] = useState(
    LEVSWAP2_Symbols.includes(currencySymbol) ? 0 : undefined
  );

  const defaultZapSymbol = network === Network.eth ? 'WETH' : 'USDC';
  const borrowableReserves = reserves.filter(
    (res) => res.isActive && res.isBorrowingEnabled
  ) as BorrowableReserveMarketData[];
  const borrowableSymbols = borrowableReserves
    .sort((a, b) => {
      if (a.symbol.toUpperCase() === defaultZapSymbol.toUpperCase()) {
        return -1;
      }
      if (b.symbol.toUpperCase() === defaultZapSymbol.toUpperCase()) {
        return 1;
      }
      return a.symbol < b.symbol ? -1 : 1;
    })
    .map((item) => item.symbol)
    .concat([currencySymbol]);
  const zapAssetsData = borrowableReserves.map((item) => {
    return {
      symbol: item.symbol,
      underylingAsset: item.underlyingAsset,
      walletBalance: normalize(walletData[item.underlyingAsset], item.decimals),
    };
  });
  const [currentZapSymbol, setCurrentZapSymbol] = useState(borrowableSymbols[0]);

  const {
    convexFRAX3CRVLevSwap,
    convexDAIUSDCUSDTSUSDLevSwap,
    convexFRAXUSDCLevSwap,
    convexIRONBANKLevSwap,
    convexMIM3CRVLevSwap,
    convexTUSDFRAXBPLevSwap,
    auraBBA3USDLevSwap,
    convexETHSTETHLevSwap,
    auraWSTETHWETHLevSwap,
    auraRETHWETHLevSwap,
    curvePoolService,
    curveAPIInit,
    balancerPoolService,
  } = useTxBuilderContext();
  const symbolToLevSwap: {
    [key: string]: { [key: string]: GeneralLevSwapInterface | GeneralLevSwap2Interface };
  } = {
    [Network.mainnet]: {
      FRAX_3CRV_LP: convexFRAX3CRVLevSwap,
      DAI_USDC_USDT_SUSD_LP: convexDAIUSDCUSDTSUSDLevSwap,
      IRON_BANK_LP: convexIRONBANKLevSwap,
      FRAX_USDC_LP: convexFRAXUSDCLevSwap,
      MIM_3CRV_LP: convexMIM3CRVLevSwap,
      TUSD_FRAXBP_LP: convexTUSDFRAXBPLevSwap,
      BAL_BB_A3_USD_LP: auraBBA3USDLevSwap,
    },
    [Network.fork]: {
      FRAX_3CRV_LP: convexFRAX3CRVLevSwap,
      DAI_USDC_USDT_SUSD_LP: convexDAIUSDCUSDTSUSDLevSwap,
      IRON_BANK_LP: convexIRONBANKLevSwap,
      FRAX_USDC_LP: convexFRAXUSDCLevSwap,
      MIM_3CRV_LP: convexMIM3CRVLevSwap,
      TUSD_FRAXBP_LP: convexTUSDFRAXBPLevSwap,
      BAL_BB_A3_USD_LP: auraBBA3USDLevSwap,
    },
    [Network.eth]: {
      ETH_STETH_LP: convexETHSTETHLevSwap,
      BAL_WSTETH_WETH_LP: auraWSTETHWETHLevSwap,
      BAL_RETH_WETH_LP: auraRETHWETHLevSwap,
    },
  };

  const currentZapReserve = useMemo(() => {
    return borrowableReserves.find((item) => item.symbol === currentZapSymbol);
  }, [currentZapSymbol]);

  const calcMaxAmountBasedOnCapacity = useCallback(
    (amount: BigNumber, availableCapacity: BigNumber): BigNumber => {
      let maxAmount;

      if (valueToBigNumber(poolReserve.capacity).gt(0)) {
        maxAmount = BigNumber.min(amount, availableCapacity);
      } else {
        maxAmount = amount;
      }

      return maxAmount;
    },
    []
  );

  const maxAmountToZap = useMemo(() => {
    if (currentZapReserve) {
      let availableCapacity = valueToBigNumber(poolReserve.capacity).minus(
        poolReserve.totalLiquidity
      );
      if (availableCapacity.lte(0)) {
        availableCapacity = valueToBigNumber('0');
      }

      return calcMaxAmountBasedOnCapacity(
        valueToBigNumber(
          normalize(walletData[currentZapReserve.underlyingAsset], currentZapReserve.decimals)
        ),
        availableCapacity
          .multipliedBy(poolReserve.priceInEth)
          .dividedBy(currentZapReserve.priceInEth)
          .dividedBy(0.99) // 1% slippage
      ).toString();
    }
    return '0';
  }, [currentZapReserve]);

  const maxAmountToLeverage = useMemo(() => {
    let availableAmount = calcMaxAmountBasedOnCapacity(
      walletBalance,
      valueToBigNumber(poolReserve.capacity).minus(poolReserve.totalLiquidity)
    );

    if (availableAmount.lte(0)) {
      availableAmount = valueToBigNumber('0');
    }
    return availableAmount;
  }, []);

  const leverageValues = useMemo(() => {
    let maxLeverage = (poolReserve as CollateralReserveMarketData).maxLeverage;

    if (valueToBigNumber(debouncedAmount).gt('0')) {
      let leveragedAmount = calcMaxAmountBasedOnCapacity(
        valueToBigNumber(debouncedAmount).multipliedBy(
          (poolReserve as CollateralReserveMarketData).maxLeverage
        ),
        valueToBigNumber(poolReserve.capacity).minus(poolReserve.totalLiquidity)
      );

      if (leveragedAmount.lte(0)) {
        leveragedAmount = valueToBigNumber('0');
      }

      maxLeverage = leveragedAmount.dividedBy(debouncedAmount).toFixed(2);
    }

    if (Number(maxLeverage) > MIN_LEVERAGE) return [MIN_LEVERAGE, Number(maxLeverage)];
    return [];
  }, [debouncedAmount]);

  const borrowData: BorrowPositionType[] = useMemo(() => {
    const amountInETH = valueToBigNumber(debouncedAmount).multipliedBy(poolReserve.priceInEth);
    const data = borrowableReserves.map((reserve) => {
      const borrowAmount = amountInETH
        .dividedBy(reserve.priceInEth)
        .multipliedBy(debouncedLeverage - 1);

      return {
        underlyingAsset: reserve.underlyingAsset,
        borrowAmount: borrowAmount.toString(),
        symbol: reserve.symbol.toUpperCase(),
        debtToken: reserve.debtTokenAddress,
        availableLiquidity: reserve.availableLiquidity,
        priceInEth: reserve.priceInEth,
        totalLiquidity: reserve.totalLiquidity,
      };
    });

    return data.filter(
      (item) =>
        valueToBigNumber(item.borrowAmount).gte('0') &&
        valueToBigNumber(item.availableLiquidity)
          .minus(valueToBigNumber(item.borrowAmount))
          .gte(valueToBigNumber(item.totalLiquidity).multipliedBy(EXCESS_UTILIZATION_RATE))
    );
  }, [debouncedAmount, debouncedLeverage]);

  const borrowPositions = () => {
    if (valueToBigNumber(amount).eq(0) || borrowData.length === 0) {
      return null;
    }
    return (
      <BorrowPosition
        data={borrowData}
        selectedSymbol={borrowingAssetSymbol}
        onChangeSymbol={setBorrowAssetSymbol}
      />
    );
  };

  const handleSlippageChanged = (value: number) => {
    if (isSlippageLow && slippage < value) {
      setIsSlippageLow(false);
    }
    setSlippage(value);
  };

  const txOverviewData: OverviewDataType = useMemo(() => {
    let healthFactorAfter = user?.healthFactor;
    let borrowAmountInETH = '0';
    let liquidationPrice = '0';
    let depositAPY = (poolReserve as CollateralReserveMarketData).depositAPY;

    const price = normalize(valueToBigNumber(poolReserve.priceInEth).div(marketRefPriceInUsd), 18);
    const amountInETH = valueToBigNumber(debouncedAmount).multipliedBy(poolReserve.priceInEth);
    const borrowingData = borrowingAssetSymbol
      ? borrowData.find((res) => res.symbol.toUpperCase() === borrowingAssetSymbol)
      : borrowData[0];

    if (user && borrowingData) {
      const totalCollateralETHAfter = valueToBigNumber(user.totalCollateralETH).plus(
        normalize(amountInETH.multipliedBy(debouncedLeverage), 18)
      );
      const liquidationThresholdAfter = valueToBigNumber(user.totalCollateralETH)
        .multipliedBy(user.currentLiquidationThreshold)
        .plus(
          normalize(
            amountInETH
              .multipliedBy(debouncedLeverage)
              .multipliedBy((poolReserve as CollateralReserveMarketData).liquidationThreshold),
            18
          )
        )
        .dividedBy(totalCollateralETHAfter);

      borrowAmountInETH = valueToBigNumber(borrowingData.borrowAmount)
        .multipliedBy(borrowingData.priceInEth)
        .toString();
      healthFactorAfter = calculateHealthFactorFromBalancesBigUnits(
        totalCollateralETHAfter,
        valueToBigNumber(user.totalBorrowsETH).plus(normalize(borrowAmountInETH, 18)),
        liquidationThresholdAfter
      ).toString();

      if (amountInETH.gt(0))
        depositAPY = valueToBigNumber((poolReserve as CollateralReserveMarketData).depositAPY)
          .multipliedBy(debouncedLeverage)
          .toString();

      liquidationPrice = valueToBigNumber(user.totalBorrowsETH)
        .plus(normalize(borrowAmountInETH, 18))
        .dividedBy(liquidationThresholdAfter.toString())
        .dividedBy(
          valueToBigNumber(user.totalCollateralETH).plus(
            normalize(amountInETH.multipliedBy(debouncedLeverage), 18)
          )
        )
        .multipliedBy(price)
        .toString();
    }

    return {
      transactionType: 'leverage',
      assetType: 'collateral',
      assetSymbol: currencySymbol,
      healthFactor: healthFactorAfter,
      APY: depositAPY,
      avgAPY: userReserve?.avgAPY,
      price: price.toString(),
      liquidationPrice,
      useFlashloan: true,
      slippage,
      slippageExpected,
      leverageSlippage: Math.abs((slippageExpected || 0) - slippage) * (leverage - 1),
      isSlippageLow,
      maxSlippage: currentMarketData.maxFlashloanSlippage
        ? currentMarketData.maxFlashloanSlippage
        : 0.05,
      onSlippageChanged: handleSlippageChanged,
      fetchGasData,
      borrowPositionComponent: borrowPositions(),
    };
  }, [borrowData, borrowingAssetSymbol, fetchGasData, slippage, slippageExpected, isSlippageLow]);

  const handleGetTransactions = useCallback(async () => {
    if (user && valueToBigNumber(debouncedAmount).gt(0) && debouncedLeverage > 1) {
      const borrowingData = borrowingAssetSymbol
        ? borrowData.find((res) => res.symbol.toUpperCase() === borrowingAssetSymbol)
        : borrowData[0];
      if (borrowingData) {
        const entry = Object.entries(symbolToLevSwap?.[currentMarketData.network]).find(([key]) =>
          networkConfig.collateralAssets?.[key]?.includes(currencySymbol)
        );
        if (entry) {
          const isBalancerLP = currencySymbol.slice(0, 4) === 'BAL_';
          const usingAaveFlashloan = network === Network.eth || isBalancerLP;
          const poolService = isBalancerLP ? balancerPoolService : curvePoolService;
          if (LEVSWAP2_Symbols.includes(currencySymbol)) {
            const leverageSwapper = entry[1] as GeneralLevSwap2Interface;
            if (currentMode === 'zap') {
              const {
                swapInfo: zapSwapInfo,
                length: zapSwapLength,
                outAmount,
                expectedSlippage: zapExpectedSlippage,
              } = await getZapSwapInfo(
                currencySymbol,
                currentZapReserve?.underlyingAsset || '',
                parseNumber(debouncedZapAmount, currentZapReserve?.decimals || 18),
                slippage,
                poolService,
                await curveAPIInit
              );

              const swapInfo = await getSwapInfo(
                currencySymbol,
                borrowingData.underlyingAsset,
                normalize(outAmount, poolReserve.decimals),
                slippage,
                debouncedLeverage - 1,
                poolService,
                await curveAPIInit,
                leverageSwapper,
                user,
                poolReserve as CollateralReserveMarketData,
                '0'
              );

              setSlippageExpected(Math.min(zapExpectedSlippage, swapInfo.expectedSlippage) / 100);

              return await leverageSwapper.zapLeverageWithFlashloan({
                _user: user.id,
                _asset: currentZapReserve?.underlyingAsset || '',
                _amount: debouncedZapAmount,
                _leverage: ((debouncedLeverage - 1) * 10000).toFixed(0),
                _borrowAsset: borrowingData.underlyingAsset,
                _debtTokenAddress: borrowingData.debtToken,
                _debtEstimatedAmount: borrowingData.borrowAmount,
                _flashloanType: usingAaveFlashloan ? '0' : '1',
                _zapPaths: zapSwapInfo,
                _zapPathLength: zapSwapLength,
                _swapInfo: swapInfo.info,
              });
            } else {
              const swapInfo = await getSwapInfo(
                currencySymbol,
                borrowingData.underlyingAsset,
                debouncedAmount,
                slippage,
                debouncedLeverage - 1,
                poolService,
                await curveAPIInit,
                leverageSwapper,
                user,
                poolReserve as CollateralReserveMarketData,
                '0'
              );

              setSlippageExpected(swapInfo.expectedSlippage / 100);

              return await leverageSwapper.enterPositionWithFlashloan({
                _user: user.id,
                _asset:
                  networkConfig.collateralAddresses?.[currencySymbol] ||
                  poolReserve.underlyingAsset,
                _amount: debouncedAmount,
                _leverage: ((debouncedLeverage - 1) * 10000).toFixed(0),
                _stableAsset: borrowingData.underlyingAsset,
                _debtTokenAddress: borrowingData.debtToken,
                _debtEstimatedAmount: borrowingData.borrowAmount,
                _flashloanType: usingAaveFlashloan ? '0' : '1',
                _swapInfo: swapInfo.info,
              });
            }
          } else {
            const leverageSwapper = entry[1] as GeneralLevSwapInterface;
            if (currentMode === 'zap')
              return await leverageSwapper.zapLeverageWithFlashloan({
                _user: user.id,
                _asset: currentZapReserve?.underlyingAsset || '',
                _amount: debouncedZapAmount,
                _leverage: ((debouncedLeverage - 1) * 10000).toFixed(0),
                _slippage: (slippage * 10000).toFixed(0),
                _borrowAsset: borrowingData.underlyingAsset,
                _debtTokenAddress: borrowingData.debtToken,
                _debtEstimatedAmount: borrowingData.borrowAmount,
                _flashloanType: usingAaveFlashloan ? '0' : '1',
              });
            else
              return await leverageSwapper.enterPositionWithFlashloan({
                _user: user.id,
                _asset:
                  networkConfig.collateralAddresses?.[currencySymbol] ||
                  poolReserve.underlyingAsset,
                _amount: debouncedAmount,
                _leverage: ((debouncedLeverage - 1) * 10000).toFixed(0),
                _slippage: (slippage * 10000).toFixed(0),
                _stableAsset: borrowingData.underlyingAsset,
                _debtTokenAddress: borrowingData.debtToken,
                _debtEstimatedAmount: borrowingData.borrowAmount,
                _flashloanType: usingAaveFlashloan ? '0' : '1',
              });
          }
        }
      }
    }
    return EmptyTransaction;
  }, [borrowData, borrowingAssetSymbol, slippage]);

  const handleMainTxCompleted = () => {
    const price = normalize(valueToBigNumber(poolReserve.priceInEth).div(marketRefPriceInUsd), 18);
    const amountInUsd = parseFloat(debouncedAmount) * parseFloat(price);
    sendAmplitudeEvent(user!.id, AmplitudeEventType.leverage, {
      network: getDefaultNetworkNameByString(),
      reserve: poolReserve.symbol,
      amount: +amount,
      value: +amountInUsd,
    });

    navigate('/dashboard');
  };

  const handleGasPriceChanged = (gas: GasResponse) => {
    setFetchGasData(() => gas);
  };

  const onSelectZapAsset = (symbol: string) => {
    setCurrentZapSymbol(symbol);
    setZapModalVisible(false);
    setCurrentMode('zap');
    setAmount('0');
  };

  const handleZapSymbolChanged = (symbol: string) => {
    if (symbol === currencySymbol) {
      setCurrentMode('normal');
      setCurrentZapSymbol(borrowableSymbols[0]);
    } else {
      setCurrentZapSymbol(symbol);
    }
  };

  useEffect(() => {
    if (!currentZapReserve || currentMode === 'normal') return;

    const estimatedReserveAmount = valueToBigNumber(debouncedZapAmount)
      .multipliedBy(currentZapReserve.priceInEth)
      .multipliedBy(0.99) // 1% slippage
      .dividedBy(poolReserve.priceInEth)
      .toFixed(5);
    setAmount(estimatedReserveAmount);
  }, [debouncedZapAmount]);

  const err =
    borrowData.length === 0
      ? 'Insufficient liquidity'
      : leverageValues.length == 0
      ? 'Overflow the max collateral deposit capacity'
      : undefined;

  return (
    <div className="LeverageMain">
      <ZapModal
        isVisible={zapModalVisible}
        onBackdropPress={() => {
          setZapModalVisible(false);
        }}
        zapAssets={zapAssetsData}
        onSelectZapAsset={onSelectZapAsset}
      />
      {currentMode === 'zap' ? (
        <AmountBoxWrapper
          symbols={borrowableSymbols}
          value={zapAmount}
          title={'Paying with'}
          titleTooltipName={'payingWith'}
          hasMax={true}
          maxValue={maxAmountToZap}
          onChange={setZapAmount}
          selectedSymbol={currentZapSymbol}
          onChangeSymbol={handleZapSymbolChanged}
        />
      ) : (
        <AmountBoxWrapper
          symbols={[currencySymbol]}
          value={amount}
          title={'Amount'}
          hasMax={true}
          maxValue={maxAmountToLeverage.toString()}
          onChange={setAmount}
          onClickAsset={() => setZapModalVisible(true)}
        />
      )}
      {leverageValues.length > 0 && (
        <LeveragePanel
          currencySymbol={currencySymbol}
          values={leverageValues}
          onChangeValue={setLeverage}
        />
      )}
      {currentMode === 'zap' && (
        <AmountBoxWrapper
          symbols={[currencySymbol]}
          value={amount}
          title={'Depositing'}
          titleTooltipName={'depositing'}
        />
      )}
      <TransactionOverviewPanel overviewData={txOverviewData} />
      {!user && <ConnectButton type="button" />}
      {user && valueToBigNumber(amount).eq('0') && (
        <DefaultButton title="Enter amount" type="primary" color="blue" disabled={true} />
      )}
      {user && valueToBigNumber(amount).gt('0') && borrowData.length > 0 && (
        <PoolTxConfirmationView
          mainTxName={'leverage'}
          currencySymbol={currencySymbol}
          debtCurrencySymbol={borrowingAssetSymbol}
          getTransactionsData={handleGetTransactions}
          hideActionWrapper={valueToBigNumber(amount).eq('0')}
          onActiveTxChanged={handleGasPriceChanged}
          onMainTxConfirmed={handleMainTxCompleted}
          onLowSlippageDetected={() => setIsSlippageLow(true)}
        />
      )}
      <ErrorMsg failed={err} />
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
