import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .PriceSlippageModal {
    &.ReactModal__Content {
      max-width: 488px !important;
      width: 488px;
      @include respond-to(xl) {
        max-width: 100% !important;
      }
    }

    .BasicModal__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      color: var(--text2-color);

      .Container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;

        & > p {
          @include body_16;
        }

        & > p.error {
          @include body_14;
          color: red;
        }

        .Options {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 16px;
        }

        .Option {
          flex: 1;
          border-radius: 4px;
          padding: 14px 16px;

          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          box-sizing: border-box;
          border: 1px solid var(--border-color);

          label,
          span,
          input[type='number'] {
            @include body_16;
            color: var(--text2-color);
          }

          label {
            width: 100%;
            text-align: left;
          }

          input[type='radio'] {
            margin-right: 4px;
          }
        }

        .Option__activated {
          border-color: var(--selected-color);

          label,
          span,
          input[type='number'] {
            color: var(--selected-color);
          }
        }

        .CustomValue_wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;

          input[type='number'] {
            width: 100%;
            background-color: transparent;
            border-style: hidden;
            -moz-appearance: textfield;
            margin: 0;
            text-align: right;
          }

          input[type='number']::-webkit-outer-spin-button,
          input[type='number']::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          input[type='number']:focus {
            outline: none;
          }

          span {
            margin-left: 8px;
          }
        }
      }

      .Slippage_Submit_Button {
        width: 100%;
      }
    }
  }
`;

export default staticStyles;
