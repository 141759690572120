import { useIntl } from 'react-intl';
import messages from '../messages';

export default function StrdyToken() {
  const intl = useIntl();

  return (
    <div className="Tooltip__content">
      <span>
        {intl.formatMessage(messages.strdyIncentiveDesc)}
        <a
          href="https://snapshot.org/#/sturdyfi.eth/proposal/0x90bad8e505e08ba52d5e3a1d66f1204d10b9c165592153e70b611a99c065844e"
          target="_blank"
          rel="noreferrer"
        >
          see details here
        </a>
      </span>
    </div>
  );
}
