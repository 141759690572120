import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .AnnouncementBar {
    width: 100%;
    padding: 24px;
    margin-bottom: 40px;
    border-radius: 8px;
    background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.95) 51.04%, #000 100%);

    display: flex;
    align-items: flex-start;
    flex-shrink: 0;

    &__content {
      max-width: 708px;

      @include respond-to(md) {
        max-width: 100%;
      }

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      flex-shrink: 0;

      &-caption {
        display: flex;
        align-items: flex-start;

        & > span {
          color: #1fadea;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 160%; /* 19.2px */
          text-transform: uppercase;
        }
      }

      &-desc {
        display: flex;
        align-items: flex-start;
        align-self: stretch;

        & > p {
          color: #fff;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 24px */
        }
      }
    }

    &__graphic {
      position: absolute;
      right: 20.219px;
      top: 0px;

      & > img {
        border-radius: 8px;
        width: 100%;
        height: 100%;
      }

      @include respond-to(md) {
        display: none;
      }

    }
  }
`;

export default staticStyles;
