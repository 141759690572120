import React, { ReactNode } from 'react';
import classNames from 'classnames';

import staticStyles from './style';
import Snackbar from 'src/components/Snackbar';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';
import { Network } from '@sturdyfi/sturdy-js';
import AnnouncementBar from 'src/components/AnnouncementBar';

interface ScreenWrapperProps {
  isTitleOnDesktop?: boolean;
  className?: string;
  children: ReactNode;
}

export default function ScreenWrapper({
  isTitleOnDesktop,
  className,
  children,
}: ScreenWrapperProps) {
  const { currentMarketData } = useProtocolDataContext();

  const shouldDisplaySnackbar = currentMarketData.network === Network.eth;
  return (
    <section
      className={classNames('ScreenWrapper', className, {
        ScreenWrapper__withDesktopTitle: isTitleOnDesktop,
      })}
    >
      {/* {shouldDisplaySnackbar && (
        <Snackbar
          variant="warning"
          title="Attention: Sturdy is investigating an exploit."
          message="The Ether market has been paused, no additional funds are at risk, and no user action is required.\n
        \n
        We will be posting further updates on Twitter (https://twitter.com/sturdyfinance) as they become available."
          withIcon={true}
        />
      )} */}
      <AnnouncementBar />
      {children}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </section>
  );
}
