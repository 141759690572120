import React from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const TRACKING_ID = process.env.REACT_APP_GA_ID;

function init() {
  // Enable debug mode on the local development environment
  // const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  if (TRACKING_ID) {
    ReactGA.initialize(TRACKING_ID);
  }
}

// function sendEvent(payload) {
//   if (TRACKING_ID) {
//     ReactGA.event(payload);
//   }
// }

function sendPageview(path: string) {
  if (TRACKING_ID) {
    ReactGA.send({ hitType: 'pageview', page: path });
  }
}

export default function useGoogleAnalytics() {
  const location = useLocation();

  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    const currentPath = location.pathname + location.search;
    sendPageview(currentPath);
  }, [location]);
}
