import { useState } from 'react';
import { TokenIcon, useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import DefaultButton from 'src/components/basic/DefaultButton';
import ClaimModal from 'src/components/ClaimModal';
import PanelWrapper from 'src/components/Wrappers/PanelWrapper';
import staticStyles from './style';
import SkeletonWrapper from 'src/components/Wrappers/SkeletonWrapper';
import Value from 'src/components/basic/Value';

export type RewardsTokenDataType = {
  symbol: string;
  claimableAmount: string;
};

interface RewardsCardProps {
  data: RewardsTokenDataType[];
  isLoading: boolean;
}

export default function RewardsCard({ data, isLoading }: RewardsCardProps) {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <PanelWrapper className="RewardsCard">
      <div className="RewardsCard__title">
        <h3>Rewards</h3>
        <DefaultButton
          onClick={() => setModalVisible(true)}
          disabled={isLoading}
          title="claim"
          type="primary"
          color="blue"
          className="RewardsCard__claim"
        />
      </div>
      {isLoading ? (
        <SkeletonWrapper height={30} />
      ) : (
        <div className="RewardsCard__inner">
          {data.map(({ symbol, claimableAmount }) => (
            <div className="RewardsCard__token" key={symbol}>
              <TokenIcon
                className="RewardsCard__token-icon"
                tokenSymbol={symbol}
                width={30}
                height={30}
              />
              <div className="RewardsCard__token-info">
                <Value
                  value={claimableAmount}
                  maximumValueDecimals={2}
                  className="RewardsCard__token-amount"
                />
                <span className="RewardsCard__token-symbol">{symbol.toUpperCase()}</span>
              </div>
            </div>
          ))}
        </div>
      )}

      <ClaimModal
        isVisible={modalVisible}
        onBackdropPress={() => {
          setModalVisible(false);
        }}
      />
      <style jsx={true}>{staticStyles}</style>
    </PanelWrapper>
  );
}
