import staticStyles from './style';
import DropdownWrapper from '../../DropdownWrapper';
import classNames from 'classnames';
import { TokenIcon } from '@sturdyfi/sturdy-ui-kit';

interface AssetSwitcherWrapperProps {
  className?: string;
  data: string[];
  activeAsset: string;
  onChangeSymbol?: (symbol: string) => void;
  horizontalPosition?: 'left' | 'right' | 'center';
}

export default function AssetSwitcherWrapper({
  className,
  data,
  activeAsset,
  onChangeSymbol,
  horizontalPosition = 'left',
}: AssetSwitcherWrapperProps) {
  const onSelectAsset = (asset: string) => {
    onChangeSymbol && onChangeSymbol(asset);
  };

  return (
    <DropdownWrapper
      className={classNames('AssetSwitcherWrapper', className)}
      horizontalPosition={horizontalPosition}
      verticalPosition={'bottom'}
      buttonComponent={
        <div className="AssetSwitcherWrapper__button-content">
          <TokenIcon
            tokenSymbol={activeAsset}
            height={24}
            width={24}
            withShortName={true}
            className=""
          />
        </div>
      }
    >
      <div
        className={classNames('AssetSwitcherWrapper__content', {
          AssetSwitcherWrapper__content_large: horizontalPosition === 'right',
        })}
      >
        {data?.map((item, id) => {
          return (
            <button
              onClick={(e) => onSelectAsset(item)}
              className={classNames('AssetSwitcherWrapper__asset', {
                AssetSwitcherWrapper__assetActive: item === activeAsset,
              })}
              type="button"
              key={id}
            >
              <TokenIcon
                tokenSymbol={item}
                height={24}
                width={24}
                withShortName={true}
                className=""
              />
            </button>
          );
        })}
      </div>
      <style jsx={true}>{staticStyles}</style>
    </DropdownWrapper>
  );
}
