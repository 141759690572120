import { TokenIcon } from '@sturdyfi/sturdy-ui-kit';
import Tooltip from 'src/components/Tooltip';

import NoData from '../../basic/NoData';
import SpinLoader from '../../basic/SpinLoader';
import Value from '../../basic/Value';
import ValuePercent from '../../basic/ValuePercent';
import InfoPanelWrapper from '../../Wrappers/InfoPanelWrapper';
import QuestionLinkWrapper from '../../Wrappers/QuestionLinkWrapper';
import TextWithInfoWrapper from '../../Wrappers/TextWithInfoWrapper';

import { OverviewDataType } from '../types';
import staticStyles from './style';

interface BorrowableDepositOverviewPanelProps
  extends Pick<
    OverviewDataType,
    | 'assetAmount'
    | 'assetSymbol'
    | 'APY'
    | 'incentiveAmount'
    | 'avgAPY'
    | 'gasEstimation'
    | 'loadingGasEstimation'
  > {}

export default function BorrowableDepositOverviewPanel({
  assetAmount,
  assetSymbol,
  APY,
  incentiveAmount,
  avgAPY,
  gasEstimation,
  loadingGasEstimation,
}: BorrowableDepositOverviewPanelProps) {
  return (
    <InfoPanelWrapper
      title={<span>Transaction overview</span>}
      className={'BorrowableDepositOverviewPanel'}
    >
      <div className={'BorrowableDepositOverviewPanel__item'}>
        <div className={'BorrowableDepositOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Your Position'} tooltipName="yourPosition" />
          <div className={'BorrowableDepositOverviewPanel__item__content__value'}>
            {assetAmount ? <Value value={assetAmount} maximumValueDecimals={2} /> : <NoData />}
            <span>&nbsp;{assetSymbol.toUpperCase()}</span>
          </div>
        </div>
      </div>

      <div className={'BorrowableDepositOverviewPanel__item'}>
        <div className={'BorrowableDepositOverviewPanel__item__content'}>
          <span>Collateral Usage</span>
          <div className={'BorrowableDepositOverviewPanel__item__content__value'}>
            <span>No</span>
          </div>
        </div>
      </div>

      <div className={'BorrowableDepositOverviewPanel__item'}>
        <div className={'BorrowableDepositOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Deposit APY'} tooltipName="depositAPY" />
          <div className={'BorrowableDepositOverviewPanel__item__content__value'}>
            <ValuePercent value={APY?.toString() || '-'} maximumDecimals={2} />
          </div>
        </div>
        {/* {incentiveAmount && Number(incentiveAmount) > 0 && (
          <div className={'BorrowableDepositOverviewPanel__item__sub incentive'}>
            <Tooltip
              link={
                <>
                  <span>+</span>
                  <Value value={incentiveAmount} maximumValueDecimals={2} />
                  <TokenIcon tokenSymbol={'STRDY'} height={12} width={12} className="" />
                </>
              }
              tooltipName="strdyIncentiveOnDeposit"
              verticalPosition="bottom"
              horizontalPosition="right"
            />
          </div>
        )} */}
        <div className={'BorrowableDepositOverviewPanel__item__sub'}>
          <Tooltip
            link={<QuestionLinkWrapper text={'Where is the yield coming from?'} />}
            tooltipName={'whereIsYieldFrom'}
            hoverable={false}
          />
        </div>
      </div>

      <div className={'BorrowableDepositOverviewPanel__item'}>
        <div className={'BorrowableDepositOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Average APY'} tooltipName="averageAPY" />
          <div className={'BorrowableDepositOverviewPanel__item__content__value'}>
            <ValuePercent value={avgAPY?.toString() || '-'} maximumDecimals={2} />
          </div>
        </div>
      </div>

      <div className={'BorrowableDepositOverviewPanel__item'}>
        <div className={'BorrowableDepositOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Gas Fee'} tooltipName="gasFee" />
          <div className={'BorrowableDepositOverviewPanel__item__content__value'}>
            {loadingGasEstimation ? (
              <SpinLoader color="black" />
            ) : gasEstimation ? (
              <Value
                value={gasEstimation}
                symbol="USD"
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>
      <style jsx={true}>{staticStyles}</style>
    </InfoPanelWrapper>
  );
}
