import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Tooltip {
    position: relative;
    display: inline-block;
    -webkit-text-fill-color: initial;

    &__title {
      color: var(--selected-color);
      @include body_14_sb;
    }
    &__content {
      @include body_14;
      color: var(--tooltip-text-color);

      display: flex;
      flex-direction: column;
      gap: 20px;

      a {
        color: #5677ed;
      }
    }

    &__contentWithTabs {
      width: 280px;
    }

    &__tabs {
      width: 100%;
      border-bottom: 1px solid var(--tabs-color);
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    &__tab {
      position: relative;
      cursor: pointer;
      @include body_14_sb;
      color: var(--not-selected-color);
      padding-top: 2px;
      padding-bottom: 15px;
      margin-right: 20px;

      i {
        position: absolute;
        left: 0;
        width: 0;
        bottom: -1px;
        height: 3px;
        transition: width 0.4s ease;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: #5677ed;
          transition: width 0.4s ease;
        }
      }
    }

    &__tabActive {
      // border-bottom: 1px solid #5677ed;
      color: var(--selected-color);
      i {
        width: 95% !important;
      }
    }

    &__Bold {
      color: var(--tooltip-bold-color);
    }
    &__Yellow {
      background: var(--linear-apy-color);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &__Green {
      color: var(--health-factor-color);
    }

    &__additional-description {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 16px;
      gap: 10px;

      width: 290px;

      background: var(--tooltip-highlight-color);
      border-radius: 16px;

      span {
        @include body_14;
        display: flex;
        align-items: center;
        text-align: center;

        .light {
          /* new gradient 2 */

          background: radial-gradient(
              391.23% 4173.53% at 414.81% -286.69%,
              #141725 0%,
              #34427b 100%
            )
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }

        .dark {
          color: white;
        }
      }
    }

    &__infoIcon {
      padding-left: 4px;
      width: 14px;
      height: 14px;
      cursor: pointer;
    }

    &__inner {
      visibility: hidden;
      position: absolute;
      z-index: 90;
      min-width: 259px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      gap: 16px;
      background: var(--dropdown-background-color);
      box-shadow: var(--panel-box-shadow);
      backdrop-filter: blur(4px);
      border-radius: 16px;
      color: black;
    }
    &__innerLight {
      filter: drop-shadow(0px 4px 22px rgba(203, 207, 211, 0.31));
    }
    &__innerVisible {
      visibility: visible;
    }

    &__top {
      .Tooltip__inner {
        transform-origin: 0 100%;
        bottom: calc(100% + 10px);
        &::before {
          top: 100%; /* At the bottom of the tooltip */
          left: 50%;
          margin-left: -5px;
          border-color: var(--dropdown-background-color) transparent transparent transparent;
        }
      }
    }

    &__bottom {
      .Tooltip__inner {
        transform-origin: 100% 0;
        top: calc(100% + 10px);
        &::before {
          bottom: 100%; /* At the top of the tooltip */
          left: 50%;
          margin-left: -5px;
          border-color: transparent transparent var(--dropdown-background-color) transparent;
        }
      }
    }

    &__left {
      .Tooltip__inner {
        left: 0;
      }
    }

    &__right {
      .Tooltip__inner {
        right: 0;
      }
    }

    &__center {
      .Tooltip__inner {
        left: 50%;
        transform: translateX(-50%) scaleY(1);
      }
    }

    &__inner::before {
      content: '';
      position: absolute;

      border-width: 5px;
      border-style: solid;
    }

    &__outer {
      display: flex;
    }

    &__data {
      .Tooltip__inner {
        min-width: 0px;

        .Tooltip__content {
          width: max-content;

          .Tooltip__Bold {
            font-weight: 600;
          }
        }
      }
    }
  }

  .Tooltip__hoverable:hover .Tooltip__inner {
    visibility: visible;
  }
`;

export default staticStyles;
